import { Text } from "./Text";
import { InstrumentKind } from "../generated/schema";
import { useCallback, useMemo, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon, brands } from "@fortawesome/fontawesome-svg-core/import.macro";
import { twMerge } from "tailwind-merge";

type PaymentMethod = InstrumentKind.PAYMENT_CARD | InstrumentKind.APPLE_PAY;
const buttonContainerClass =
  "cursor-pointer m-[1px] relative flex flex-col gap-3 rounded-2xl border-neutral-200 border bg-white p-4 dark:bg-neutral-800";
const selectedContainerClass = "m-0 border-primary border-2";

export const PaymentMethodSelector = ({
  supportedPaymentMethods,
  onPaymentMethodSelected,
}: {
  supportedPaymentMethods: InstrumentKind[];
  onPaymentMethodSelected: (paymentMethod: PaymentMethod) => void;
}) => {
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>();

  const onSelect = useCallback(
    (pm: PaymentMethod) => {
      setPaymentMethod(pm);
      onPaymentMethodSelected(pm);
    },
    [onPaymentMethodSelected],
  );

  const selctedIcon = useMemo(() => {
    return (
      <FontAwesomeIcon
        icon={icon({ name: "check-circle" })}
        className="text-primary absolute right-0 top-0 z-10 h-5 w-5 translate-x-[33%] translate-y-[-33%] transform bg-neutral-100 dark:bg-neutral-800"
      />
    );
  }, []);

  return (
    <div className={"flex flex-col gap-2"}>
      {supportedPaymentMethods.includes(InstrumentKind.APPLE_PAY) && (
        <div
          key="PaymentMethodSelector:APPLE_PAY"
          data-testid="PaymentMethodSelector:APPLE_PAY"
          className={twMerge(
            buttonContainerClass,
            paymentMethod === InstrumentKind.APPLE_PAY
              ? selectedContainerClass
              : "",
          )}
          onClick={() => onSelect(InstrumentKind.APPLE_PAY)}
        >
          <div>
            <div className="flex flex-row items-center">
              <FontAwesomeIcon
                icon={brands("apple")}
                className="mr-1 h-4 w-4"
              />
              <Text className="text-base font-bold">Pay</Text>
            </div>
            <Text className="text-sm">
              Use Apple Pay to complete transfers instead of adding a debit
              card.
            </Text>
            <div className="mt-1 flex items-center text-xs font-medium tracking-tight dark:text-white">
              <FontAwesomeIcon
                icon={icon({ name: "megaphone", style: "solid" })}
                className="mr-1 text-[#e1af4f]"
              />
              <div className="opacity-60">
                Must have a <b>debit card</b> in your Apple Wallet
              </div>
            </div>
            {paymentMethod === InstrumentKind.APPLE_PAY && selctedIcon}
          </div>
        </div>
      )}
      {supportedPaymentMethods.includes(InstrumentKind.PAYMENT_CARD) && (
        <div
          key="PaymentMethodSelector:PAYMENT_CARD"
          data-testid="PaymentMethodSelector:PAYMENT_CARD"
          className={twMerge(
            buttonContainerClass,
            paymentMethod === InstrumentKind.PAYMENT_CARD
              ? selectedContainerClass
              : "",
          )}
          onClick={() => onSelect(InstrumentKind.PAYMENT_CARD)}
        >
          <div>
            <div className="flex flex-row items-center">
              <FontAwesomeIcon
                icon={icon({ name: "credit-card" })}
                className="mr-1 h-4 w-4"
              />
              <Text className="text-base font-bold">Debit Card</Text>
            </div>
            <Text className="text-sm">
              Connect a debit card from your bank to make transfers.
            </Text>
          </div>
          {paymentMethod === InstrumentKind.PAYMENT_CARD && selctedIcon}
        </div>
      )}
    </div>
  );
};
