import { AppContext } from "@src/contexts/AppContextProvider";
import { ErrorMessages } from "@tigris/mesokit";
import { Result, err, ok } from "neverthrow";
import { Posthog, Sentry, TelemetryEvents } from "@tigris/common";
import { useCallback, useContext } from "react";
import {
  startAuthentication,
  startRegistration,
} from "@simplewebauthn/browser";

export const usePasskey = () => {
  const {
    api: {
      resolveBeginPasskeyRegistration,
      resolveCompletePasskeyRegistration,
      resolveBeginPasskeyLogin,
      resolveCompletePasskeyLogin,
    },
    session,
    setHasPasskey,
  } = useContext(AppContext);

  const registerPasskey = useCallback(async (): Promise<
    Result<true, string>
  > => {
    Posthog.capture(TelemetryEvents.passkeyRegistrationAttempt);
    const beginResult = await resolveBeginPasskeyRegistration();
    if (beginResult.isErr()) return err(beginResult.error);

    const ceremony = beginResult.value;
    let startRegistrationResponse;
    try {
      startRegistrationResponse = await startRegistration(
        JSON.parse(ceremony.request).publicKey as unknown as Parameters<
          typeof startRegistration
        >[0],
      );
    } catch (error: unknown) {
      Posthog.capture(TelemetryEvents.passkeyRegistrationFail);
      if (
        error instanceof Error &&
        !["AbortError", "NotAllowedError"].includes(error.name)
      ) {
        Sentry.captureException(error, {
          extra: { result: JSON.stringify(ceremony) },
        });
      }
      return err(ErrorMessages.passkey.PASSKEY_REGISTRATION_FAILED);
    }

    const input = {
      ceremonyId: ceremony.id,
      response: JSON.stringify(startRegistrationResponse),
    };
    const completeRegistrationResult = await resolveCompletePasskeyRegistration(
      { input },
    );
    if (completeRegistrationResult.isErr()) {
      Posthog.capture(TelemetryEvents.passkeyRegistrationFail);
      return err(completeRegistrationResult.error);
    }

    Posthog.capture(TelemetryEvents.passkeyRegistrationSuccess);
    setHasPasskey(true);
    return ok(true);
  }, [
    resolveBeginPasskeyRegistration,
    resolveCompletePasskeyRegistration,
    setHasPasskey,
  ]);

  const loginWithPasskey = useCallback(async () => {
    Posthog.capture(TelemetryEvents.passkeyLoginAttempt);
    const beginResult = await resolveBeginPasskeyLogin();
    if (beginResult.isErr()) return err(beginResult.error);
    const ceremony = beginResult.value;

    let startAuthenticationResponse;
    try {
      startAuthenticationResponse = await startAuthentication(
        JSON.parse(ceremony.request).publicKey as unknown as Parameters<
          typeof startAuthentication
        >[0],
      );
    } catch (error: unknown) {
      Posthog.capture(TelemetryEvents.passkeyLoginFail);
      if (
        error instanceof Error &&
        !["AbortError", "NotAllowedError"].includes(error.name)
      ) {
        Sentry.captureException(error.message, {
          extra: { result: JSON.stringify(ceremony) },
        });
      }
      return err(ErrorMessages.passkey.PASSKEY_LOGIN_FAILED);
    }

    const input = {
      ceremonyId: ceremony.id,
      response: JSON.stringify(startAuthenticationResponse),
      riskSessionKey: session?.riskSession?.sessionKey ?? "",
    };
    const completeLoginResult = await resolveCompletePasskeyLogin({ input });
    if (completeLoginResult.isErr()) {
      Posthog.capture(TelemetryEvents.passkeyLoginFail);
      return err(completeLoginResult.error);
    }

    Posthog.capture(TelemetryEvents.passkeyLoginSuccess);
    setHasPasskey(true);
    return ok(completeLoginResult.value);
  }, [
    resolveBeginPasskeyLogin,
    resolveCompletePasskeyLogin,
    session?.riskSession?.sessionKey,
    setHasPasskey,
  ]);

  return { registerPasskey, loginWithPasskey };
};
