import { useCallback, useState } from "react";

import { InternationalAddressForm } from "./InternationalAddressForm";
import { NonUSCountryCodeAlpha3 } from "../../../types";
import { InternationalLookupAddressDetail } from "../../../utils/smarty";
import { twMerge } from "tailwind-merge";
import { toast } from "sonner";
import { AddressCombobox, AddressComboboxProps } from "../../AddressCombobox";

export type InternationalAddressCaptureProps = {
  expandedOnly?: boolean;
  initialCountryCodeAlpha3: NonUSCountryCodeAlpha3;
  initialValue?: InternationalLookupAddressDetail;
  /**
   * Whether to allow the user to change the country set via `initialCountryCodeAlpha3`.
   *
   * Defaults to `true`.
   * */
  allowCountrySelection?: boolean;
  onChange: (props: {
    address?: InternationalLookupAddressDetail;
    isValid: boolean;
  }) => void;
};

export const InternationalAddressCapture = ({
  initialCountryCodeAlpha3,
  onChange,
  expandedOnly = false,
  allowCountrySelection = true,
  initialValue,
}: InternationalAddressCaptureProps) => {
  const [formExpanded, setFormExpanded] = useState(
    expandedOnly || initialValue,
  );
  const [address, setAddress] = useState<
    InternationalLookupAddressDetail | undefined
  >(initialValue);

  const handleAddressSelected = useCallback<
    AddressComboboxProps<InternationalLookupAddressDetail>["onAddressSelected"]
  >(
    (address) => {
      setFormExpanded(true);
      setAddress(address);
      onChange({ address, isValid: true });
    },
    [onChange],
  );

  const handleComboboxError = useCallback<
    AddressComboboxProps<InternationalLookupAddressDetail>["onError"]
  >((message) => {
    setFormExpanded(true);
    toast.error(message, { duration: 3e3 });
  }, []);

  return (
    <div>
      {formExpanded ? (
        <InternationalAddressForm
          allowCountrySelection={allowCountrySelection}
          initialCountryCodeAlpha3={
            address
              ? (address.countryIso3 as NonUSCountryCodeAlpha3)
              : initialCountryCodeAlpha3
          }
          initialAddress={address}
          onChange={onChange}
        />
      ) : (
        <>
          <AddressCombobox<InternationalLookupAddressDetail>
            international
            initialCountryCodeAlpha3={initialCountryCodeAlpha3}
            onAddressSelected={handleAddressSelected}
            onError={handleComboboxError}
            isValid
            allowCountrySelection={allowCountrySelection}
          />
          <div
            tabIndex={0}
            className={twMerge(
              "mt-2 inline-block cursor-pointer text-xs font-medium text-neutral-400 underline",
              "opacity-50 transition-opacity duration-200",
              "hover:opacity-100",
              "outline-none focus:opacity-100",
            )}
            onClick={() => setFormExpanded(true)}
            data-testid="international-address-capture:expand-button"
          >
            Enter address manually
          </div>
        </>
      )}
    </div>
  );
};
