import {
  OnboardingAppEvents,
  OnboardingConfiguration,
  ReturnToTransferReason,
} from "@tigris/onboarding";
import { err, ok, Result } from "neverthrow";
import { ErrorMessages } from "@tigris/mesokit";
import { User } from "@src/types";

export type LaunchOnboardingWindow = {
  window: Window;
  // A handler to forcibly close the opened window
  close: () => void;
};

export type LaunchOnboardingWindowOptions = {
  url: string;
  name: string;
  onReturnToTransfer: (reason: ReturnToTransferReason) => void;
  onRequestOnboardingConfiguration: () => OnboardingConfiguration & {
    user?: Partial<User>;
  };
};

export const launchOnboardingWindow = ({
  url,
  name,
  onReturnToTransfer,
  onRequestOnboardingConfiguration,
}: LaunchOnboardingWindowOptions): Result<LaunchOnboardingWindow, string> => {
  /** Extract the [origin](https://developer.mozilla.org/en-US/docs/Web/API/Location/origin) from the provided URL to use as a postmessage target. */
  const onboardingAppOrigin = new URL(url).origin;
  // Whether onboarding has fully completed (successfully or terminated)
  let onboardingResolved = false;

  const onboardingWindow = window.open(url, name);
  const onboardingWindowOrigin = new URL(url).origin;

  if (!onboardingWindow) {
    return err(ErrorMessages.openWindow.GENERIC_ERROR);
  }
  onboardingWindow.opener = window;

  const poller = setInterval(() => {
    if (onboardingWindow.closed) {
      clearInterval(poller);

      if (!onboardingResolved) {
        onReturnToTransfer("returnToLogin");
      }
    }
  }, 500);

  const cleanup = () => {
    onboardingWindow.close();
    clearInterval(poller);
    window.removeEventListener("message", messageHandler);
  };

  const messageHandler = (event: MessageEvent) => {
    // Drop messages from origins we don't care about
    if (event.origin !== onboardingWindowOrigin) return;

    if (event.data.kind === OnboardingAppEvents.GET_ONBOARDING_CONFIGURATION) {
      onboardingWindow.postMessage(
        {
          kind: OnboardingAppEvents.RETURN_ONBOARDING_CONFIGURATION,
          payload: onRequestOnboardingConfiguration(),
        },
        onboardingAppOrigin,
      );
    }

    if (event.data.kind === OnboardingAppEvents.ONBOARDING_COMPLETE) {
      onboardingResolved = true;
      cleanup();
      onReturnToTransfer("onboardingComplete");
    }

    if (event.data.kind === OnboardingAppEvents.ONBOARDING_TERMINATED) {
      onboardingResolved = true;
      cleanup();
      onReturnToTransfer("onboardingTerminated");
    }

    if (event.data.kind === OnboardingAppEvents.ONBOARDING_RETURN_TO_LOGIN) {
      onboardingResolved = true;
      cleanup();
      onReturnToTransfer("returnToLogin");
    }
  };

  window.addEventListener("message", messageHandler);

  return ok({
    window: onboardingWindow,
    close: () => cleanup(),
  });
};
