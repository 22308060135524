import { Result, err, ok } from "neverthrow";
import { getAge } from "@tigris/common";
import { ErrorMessages } from "@tigris/mesokit";
import { InstrumentKind } from "../../generated/sdk";
import { ProfileStatus, Routes, UserStatus } from "../../types";
import { NextOnboardingArgs } from "./types";

const hasAcceptedUSTerms = (profileStatus: ProfileStatus): boolean => {
  return profileStatus.acceptedMesoTerms && profileStatus.acceptedZeroHashTerms;
};

export const getNextUSOnboardingStep = ({
  profileStatus,
  user,
  supportedPaymentMethods,
}: NextOnboardingArgs): Result<Routes, string> => {
  // In all cases, this is the very first step, so if it's not set but other values are, something is very wrong
  if (!profileStatus.residenceCountry) {
    return ok(Routes.Root);
  }

  if (!profileStatus.account && !hasAcceptedUSTerms(profileStatus)) {
    return ok(Routes.CreateAccount);
  } else if (!profileStatus.account || !hasAcceptedUSTerms(profileStatus)) {
    return err(ErrorMessages.onboardingSteps.UNABLE_TO_DETERMINE_NEXT_STEP);
  }

  if (!profileStatus.phone || !profileStatus.phoneVerified) {
    return ok(Routes.PhoneEntry);
  }

  // Personal information (first, last, dob, citizenship, residential address)
  if (!profileStatus.basicInfo || !profileStatus.residentialAddress) {
    return ok(Routes.BasicInfoOverview);
  }

  if (!profileStatus.taxpayerId) {
    return ok(Routes.TaxpayerIdEntry);
  }

  // At this point, if no user object is provided, we can't determine next steps
  if (!user) {
    return err(ErrorMessages.onboardingSteps.USER_OBJECT_REQUIRED);
  }

  // Scam warning
  const userAge = getAge(user.dateOfBirth);
  const ageRequiresScamWarning = userAge.isOk() && userAge.value >= 50;
  if (ageRequiresScamWarning && !profileStatus.acknowledgedScamWarning) {
    return ok(Routes.ScamWarning);
  }

  // Now should go to select payment method
  if (!profileStatus.financialInstrument) {
    if (
      supportedPaymentMethods.length === 1 &&
      supportedPaymentMethods[0] === InstrumentKind.PAYMENT_CARD
    ) {
      return ok(Routes.AddPaymentCard);
    }

    return ok(Routes.SelectPaymentMethod);
  }

  if (user.status === UserStatus.NEW) {
    return ok(Routes.Summary);
  }

  return err(ErrorMessages.onboardingSteps.UNABLE_TO_DETERMINE_NEXT_STEP);
};
