import { useCallback, useState } from "react";
import { DocumentVerificationResult } from "../hooks/useAwaitInlineDocs";
import { twMerge } from "tailwind-merge";

type InlineDocsVerificationDevControlsProps = {
  /** A callback that will be dispatched when the dev tools want to force a client timeout. */
  onForceClientTimeout: () => void;
  /**
   * A callback that will be dispatched when the dev tools want to force a completion event.
   *
   * This is for AUTOMATED TESTS ONLY
   *
   */
  __testOnly_onForceComplete: () => void;
  /** A callback that will be dispatched when the dev tools want to force an error event. */
  onForceError: () => void;
  result: DocumentVerificationResult | null;
};

const mappedResult = (result: DocumentVerificationResult | null) => {
  switch (result) {
    case DocumentVerificationResult.COMPLETE:
      return "COMPLETE";
    case DocumentVerificationResult.ERROR:
      return "ERROR";
    case DocumentVerificationResult.CLIENT_TIMEOUT:
      return "CLIENT_TIMEOUT";
    default:
      return "PENDING";
  }
};

export const InlineDocsVerificationDevControls = ({
  onForceClientTimeout,
  onForceError,
  result,
  __testOnly_onForceComplete,
}: InlineDocsVerificationDevControlsProps) => {
  const [collapsed, setCollapsed] = useState(false);

  // This statement prevents this entire module from being bundled in our production builds
  if (!(import.meta.env.VITE_TIGRIS_ENV === "preview" || import.meta.env.DEV)) {
    return;
  }

  // this is okay because this will only be rendered in dev/preview
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const toggleCollapsed = useCallback(
    () => setCollapsed(!collapsed),
    [collapsed],
  );

  return (
    <div
      className={twMerge(
        "fixed bottom-2 left-4 w-[280px] overflow-hidden rounded-sm",
        "bg-neutral-200 dark:bg-black/80",
        "font-mono text-neutral-800/40 dark:text-neutral-200/80",
        collapsed &&
          "h-[32px] w-[32px] rounded-full bg-black/10 dark:bg-neutral-900/10",
      )}
    >
      {collapsed ? (
        <div
          className="flex h-full w-full cursor-pointer items-center justify-center"
          onClick={toggleCollapsed}
        >
          <div className="w-[18px]">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
              <path d="M392.8 1.2c-17-4.9-34.7 5-39.6 22l-128 448c-4.9 17 5 34.7 22 39.6s34.7-5 39.6-22l128-448c4.9-17-5-34.7-22-39.6zm80.6 120.1c-12.5 12.5-12.5 32.8 0 45.3L562.7 256l-89.4 89.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l112-112c12.5-12.5 12.5-32.8 0-45.3l-112-112c-12.5-12.5-32.8-12.5-45.3 0zm-306.7 0c-12.5-12.5-32.8-12.5-45.3 0l-112 112c-12.5 12.5-12.5 32.8 0 45.3l112 112c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256l89.4-89.4c12.5-12.5 12.5-32.8 0-45.3z" />
            </svg>
          </div>
        </div>
      ) : (
        <>
          <header
            className={twMerge(
              "px-2 py-4",
              "text-xs font-bold text-neutral-500 dark:text-white",
              "bg-neutral-300 dark:bg-black",
              "border-b border-b-neutral-400 dark:border-b-neutral-800",
            )}
          >
            <div className="flex w-full items-center justify-between">
              <div className="flex items-center gap-1 text-[10px]">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 16 16"
                  fill="currentColor"
                  className="size-3"
                >
                  <path
                    fillRule="evenodd"
                    d="M11.5 8a3.5 3.5 0 0 0 3.362-4.476c-.094-.325-.497-.39-.736-.15L12.099 5.4a.48.48 0 0 1-.653.033 8.554 8.554 0 0 1-.879-.879.48.48 0 0 1 .033-.653l2.027-2.028c.24-.239.175-.642-.15-.736a3.502 3.502 0 0 0-4.476 3.427c.018.99-.133 2.093-.914 2.7l-5.31 4.13a2.015 2.015 0 1 0 2.828 2.827l4.13-5.309c.607-.78 1.71-.932 2.7-.914L11.5 8ZM3 13.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z"
                    clipRule="evenodd"
                  />
                </svg>
                Meso dev tools (document capture)
              </div>

              <div onClick={toggleCollapsed} className="cursor-pointer">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  className="size-5"
                >
                  <path d="M6.28 5.22a.75.75 0 0 0-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 1 0 1.06 1.06L10 11.06l3.72 3.72a.75.75 0 1 0 1.06-1.06L11.06 10l3.72-3.72a.75.75 0 0 0-1.06-1.06L10 8.94 6.28 5.22Z" />
                </svg>
              </div>
            </div>
          </header>

          <div className="flex flex-col gap-4 p-4 text-xs">
            <div className="flex items-center gap-1">
              <div>Status:</div>
              <div className="inline rounded-full bg-purple-700 px-2 font-mono text-[10px] tracking-wide text-white">
                {mappedResult(result)}
              </div>
            </div>

            <div className="flex flex-col gap-2">
              <div>Simulate events:</div>
              <button
                disabled={result === DocumentVerificationResult.CLIENT_TIMEOUT}
                onClick={(event) => {
                  event.stopPropagation();
                  onForceClientTimeout();
                }}
                className="rounded-md bg-purple-900 p-2 font-bold text-white hover:bg-purple-700 disabled:pointer-events-none disabled:opacity-70"
              >
                Client timeout
              </button>
              <button
                disabled={result === DocumentVerificationResult.ERROR}
                onClick={onForceError}
                className="rounded-md bg-purple-900 p-2 font-bold text-white hover:bg-purple-700 disabled:pointer-events-none disabled:opacity-70"
                data-testid="devToolsTestErrorButton"
              >
                Error
              </button>

              {/* Only render this for tests */}
              {(import.meta.env.VITE_IS_TEST ||
                import.meta.env.VITEST_VSCODE) && (
                <button
                  disabled={result === DocumentVerificationResult.COMPLETE}
                  onClick={__testOnly_onForceComplete}
                  className="rounded-md bg-purple-900 p-2 font-bold text-white hover:bg-purple-700 disabled:pointer-events-none disabled:opacity-70"
                  data-testid="devToolsTestCompleteButton"
                >
                  Complete
                </button>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};
