import { motion, Variants } from "framer-motion";
import { PropsWithChildren } from "react";
import { twMerge } from "tailwind-merge";
import { spring } from "../../utils/animation";
import { AnimatedButtonProps } from ".";
import { StaticButton } from "./StaticButton";

const buttonAnimation: Variants = {
  initial: { opacity: 0, y: 24 },
  exit: { opacity: 0, y: 24, transition: spring },
  animate: { opacity: 1, y: 0, transition: { ...spring, delay: 0.25 } },
};

/** An animated Meso UI button. */
export const AnimatedButton = (
  props: PropsWithChildren<AnimatedButtonProps>,
) => {
  return (
    <motion.div
      key="AnimatedButton"
      className={twMerge(["flex flex-col", props.containerClassName ?? ""])}
      variants={buttonAnimation}
      initial="initial"
      animate="animate"
      exit="exit"
    >
      <StaticButton {...props} static={true} />
    </motion.div>
  );
};
