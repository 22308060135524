import { UserActivationState } from "../generated/sdk";
import { useState, useMemo, useRef, useCallback } from "react";
import { useApi } from "./useApi";
import { UserStatus } from "../types";
import { err, ok, Result } from "neverthrow";

type ActivateUserResult = Result<UserStatus, undefined>;

type UseActivateUserHook = {
  activateUser: () => Promise<ActivateUserResult>;
  isActivatingUser: boolean;
};

/** The polling interval in `ms`. */
const USE_ACTIVATE_USER_POLLING_INTERVAL = 1e3;

export const useActivateUser = (): UseActivateUserHook => {
  const userActivationInitialized = useRef(false);
  const { api, session } = useApi();
  const [error, setError] = useState<string>();
  const [isActivatingUser, setIsActivatingUser] = useState(false);

  const checkActivation = useCallback(
    (userActivationId?: string) =>
      new Promise<ActivateUserResult>(async (resolve) => {
        if (!userActivationId || error) return;

        const userActivationStatusResult =
          await api.resolveUserActivationStatus({
            id: userActivationId,
          });

        if (userActivationStatusResult.isErr()) {
          setError(userActivationStatusResult.error);
          resolve(err(undefined));
          return;
        }

        const userActivation = userActivationStatusResult.value;

        const activationComplete =
          userActivation.state === UserActivationState.FAILED ||
          userActivation.state === UserActivationState.SUCCEEDED;

        if (!activationComplete) {
          setTimeout(() => {
            resolve(checkActivation(userActivationId));
          }, USE_ACTIVATE_USER_POLLING_INTERVAL);
          return;
        } else {
          resolve(ok(userActivation.userStatus as UserStatus));
        }
      }),
    [api, error],
  );

  const activateUser = useCallback<
    UseActivateUserHook["activateUser"]
  >(async () => {
    // Ensure we only initialize once
    if (userActivationInitialized.current) return err(undefined);

    userActivationInitialized.current = true;
    setIsActivatingUser(true);

    const activateUserResult = await api.resolveActivateUser({
      input: { riskSessionKey: session!.riskSession!.sessionKey },
    });

    if (activateUserResult.isErr()) {
      setError(activateUserResult.error);
      return err(undefined);
    }

    return await checkActivation(activateUserResult.value.id);
  }, [api, checkActivation, session]);

  const hookValue = useMemo<UseActivateUserHook>(
    () => ({ activateUser, isActivatingUser }),
    [activateUser, isActivatingUser],
  );

  return hookValue;
};
