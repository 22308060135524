import {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  CountryCodeAlpha2,
  FeatureFlag,
  OnboardingAppRenderContext,
  OnboardingContextValue,
  ReturnToTransferReason,
  User,
} from "../types";
import { OnboardingContext } from "./OnboardingContext";
import { useApi } from "../hooks/useApi";
import { DEFAULT_THEME, isValidTheme } from "@tigris/mesokit";
import { Posthog } from "@tigris/common";
import { devToolsGetFeatureFlags } from "../dev/utils";

export type OnboardingContextProviderProps =
  OnboardingContextValue["configuration"] & {
    /**
     * A callback to take the user back to the Transfer window (if available). This is dispatched when onboarding resolves successfully, user activation fails, the user needs to go back to login, or if the user wishes to cancel the session.
     */
    onReturnToTransfer: (reason: ReturnToTransferReason) => void;
    /**
     * The mode the Onboarding application is running in.
     *
     * @default BREAKOUT_FROM_TRANSFER
     */
    renderContext?: OnboardingContextValue["renderContext"];
  };

// Configure client-only feature flags
const getFeatureFlags = (
  partner: OnboardingContextValue["configuration"]["partner"],
) => {
  const featureFlags = new Set<FeatureFlag>();
  if (
    // This is where we can add a client-side partner-specific feature flag
    (import.meta.env.VITE_ALLOW_INTL_ONBOARDING === "true" &&
      partner?.id === "meso-dev") ||
    // This statement has to be explicit and cannot be within a helper function or else tree-shaking won't work
    ((import.meta.env.VITE_TIGRIS_ENV === "preview" || import.meta.env.DEV) &&
      devToolsGetFeatureFlags(FeatureFlag.ALLOW_INTL_ONBOARDING))
  ) {
    featureFlags.add(FeatureFlag.ALLOW_INTL_ONBOARDING);
  }

  return featureFlags;
};

export const OnboardingContextProvider = ({
  children,
  network,
  walletAddress,
  partner,
  onReturnToTransfer,
  supportedPaymentMethods,
  profileStatus,
  renderContext = OnboardingAppRenderContext.BREAKOUT_FROM_TRANSFER,
}: PropsWithChildren<OnboardingContextProviderProps>) => {
  const featureFlags = useMemo(() => getFeatureFlags(partner), [partner]);
  const { session } = useApi();
  const [user, setUser] = useState<User>(() => {
    let residenceCountry = undefined;
    if (
      profileStatus &&
      profileStatus.residenceCountry &&
      Object.values(CountryCodeAlpha2).includes(
        profileStatus.residenceCountry as CountryCodeAlpha2,
      )
    ) {
      residenceCountry = profileStatus.residenceCountry as CountryCodeAlpha2;
    }

    // If residence country cannot be resolved and we have the int'l feature flag off, default the user to US
    if (
      !residenceCountry &&
      !featureFlags.has(FeatureFlag.ALLOW_INTL_ONBOARDING)
    ) {
      if (!residenceCountry) {
        residenceCountry = CountryCodeAlpha2.US;
      }
    }

    return {
      theme: "default",
      residenceCountry,
    };
  });

  const updateUser = useCallback<OnboardingContextValue["updateUser"]>(
    (updatedUser: Partial<User>) => {
      if (updatedUser.theme) {
        const newTheme = isValidTheme(updatedUser.theme)
          ? updatedUser.theme
          : DEFAULT_THEME;

        const currentThemeClasses = Array.from(
          document.documentElement.classList,
        ).filter((className) => className.startsWith("theme-"));

        // Side effects!
        document.documentElement.classList.remove(...currentThemeClasses);

        document.documentElement.classList.add(
          `theme-${newTheme ?? "default"}`,
        );

        updatedUser.theme = newTheme;
      }

      let mergedUser: User;
      setUser((prevUser) => {
        mergedUser = {
          ...prevUser,
          ...updatedUser,
        };
        return mergedUser;
      });

      return mergedUser!;
    },
    [],
  );

  // Once the user has a Meso ID, identify them in Posthog
  useEffect(() => {
    if (user.id) Posthog.identify(user.id);
  }, [user.id]);

  const appReady = useMemo(() => session !== undefined, [session]);

  const contextValue = useMemo<OnboardingContextValue>(
    () => ({
      user,
      appReady,
      updateUser,
      configuration: {
        network,
        walletAddress,
        partner,
        supportedPaymentMethods,
        profileStatus,
      },
      returnToTransfer: onReturnToTransfer,
      renderContext,
      featureFlags,
    }),
    [
      appReady,
      featureFlags,
      network,
      onReturnToTransfer,
      partner,
      profileStatus,
      renderContext,
      supportedPaymentMethods,
      updateUser,
      user,
      walletAddress,
    ],
  );

  return (
    <OnboardingContext.Provider value={contextValue}>
      {children}
    </OnboardingContext.Provider>
  );
};
