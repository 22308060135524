import { Address } from "@src/generated/sdk";
import { CountryCodeAlpha2 } from "@src/types";
import * as Factory from "factory.ts";

export const residentialAddressFactory = Factory.Sync.makeFactory<Address>({
  address1: "123 Street",
  address2: "unit 6",
  city: "Joliet",
  region: "IL",
  postalCode: "60123",
  countryCode: CountryCodeAlpha2.US,
});

export const internationalResidentialAddressFactory =
  Factory.Sync.makeFactory<Address>({
    address1: "some addr",
    address2: undefined,
    city: "some city",
    region: "a region",
    postalCode: "PC123",
    countryCode: CountryCodeAlpha2.IT,
  });
