import {
  useEffect,
  useState,
  useContext,
  ChangeEvent,
  FormEventHandler,
  useCallback,
  useMemo,
} from "react";
import { Title } from "../../Title";
import { Text } from "../../Text";
import { Button } from "../../Button";
import { Input } from "../../Input/Input";
import { MesoKitContext } from "../../../MesoKitContext";
import {
  Frames,
  FramesBillingAddress,
  PaymentMethod,
  /* cspell:disable-next-line: This is a type from CKO's library. */
  FrameElementIdentifer,
  FramesInitProps,
} from "frames-react";
import {
  USAddressCapture,
  USAddressCaptureProps,
} from "../USAddress/USAddressCapture";
import { AnimatePresence, motion } from "framer-motion";
import { z } from "zod";
import {
  billingAddressSchema,
  cardholderNameSchema,
  defaultBillingAddress,
  defaultFormField,
  isPOBoxError,
  isProhibitedRegionError,
  rawAddressContainsPOBox,
  TelemetryEvents,
} from "@tigris/common";
import { PaymentCardInputFrame } from "./PaymentCardInputFrame";
import { ErrorMessages } from "../../../utils/errorMessages";
import { AddPaymentCardFormProps, FormState } from "./types";
import { usePrevious } from "@uidotdev/usehooks";
import { useLazyScript } from "../../../hooks/useLazyScript";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { CountryCodeAlpha2, NonUSCountryCodeAlpha3 } from "../../../types";
import {
  InternationalAddressCapture,
  InternationalAddressCaptureProps,
} from "../InternationalAddress/InternationalAddressCapture";
import {
  InternationalLookupAddressDetail,
  USAddressSuggestion,
} from "../../../utils/smarty";
import { countries, nonUSCountries } from "../../../utils/countries";

enum ToastIds {
  TOKENIZED_CARD_ERROR = "cko_tokenized_card_error",
  CHECKOUT_TOKENIZATION_FAILED = "cko_tokenization",
  ADD_PAYMENT_CARD_API_ERROR = "add_payment_card_api_error",
  PROHIBITED_REGION = "prohibited_region",
  PO_BOX_NOT_ALLOWED = "po_box_not_allowed",
}

const CHECKOUT_SCRIPT_SRC = "https://cdn.checkout.com/js/framesv2.min.js";

const defaultFormState: FormState = {
  isValid: false,
  fields: {
    cardDetails: defaultFormField(""),
    cardholderName: defaultFormField(""),
    billingAddress: defaultFormField(defaultBillingAddress),
  },
};

/**
 * Sanitize card holder name to remove disallowed characters. For now, this only includes digits.
 */
const formatCardholderName = ({
  newValue,
}: {
  previousValue: string;
  newValue: string;
}): string => newValue.replace(/\d+/g, "");

/** The web property the payment card form is render in. */
const property = location.host.includes("account.")
  ? "account app"
  : "transfer app";

/**
 * A component for tokenizing card details with CKO.
 *
 * This has `toast` built-in and will inherit the `sonner` instance from the embedding application.
 */
export const AddPaymentCardForm = ({
  formEnabled,
  formId,
  onTokenizationSuccess,
  disabled = false,
  initialValues,
  sessionId,
  international = false,
  initialCountryCodeAlpha3,
}: AddPaymentCardFormProps) => {
  const { sentry, toast, posthog } = useContext(MesoKitContext);
  const [checkoutScriptLoadStartTime] = useState(() => performance.now());
  const [requestIsInFlight, setRequestIsInFlight] = useState(false);
  /** Determine if the iframes are loaded and ready for use. */
  const [formIsReady, setFormIsReady] = useState(false);
  const { status: checkoutScriptLoadStatus, loadScript } = useLazyScript();
  const [formState, setFormState] = useState<FormState>(() => {
    const initialFormState = defaultFormState;

    if (initialValues?.billingAddress) {
      initialFormState.fields.billingAddress.value =
        initialValues.billingAddress;
      initialFormState.fields.billingAddress.isDirty = true;
      initialFormState.fields.billingAddress.isValid = true;
      initialFormState.fields.billingAddress.isTouched = true;
    }

    if (initialValues?.cardholderName) {
      initialFormState.fields.cardholderName.value =
        initialValues.cardholderName;

      initialFormState.fields.cardholderName.isDirty = true;
      initialFormState.fields.cardholderName.isValid = true;
      initialFormState.fields.cardholderName.isTouched = true;
    }

    return initialFormState;
  });
  const [initialAddressValue] = useState<
    | USAddressCaptureProps["initialValue"]
    | InternationalAddressCaptureProps["initialValue"]
  >(() => {
    if (initialValues?.billingAddress) {
      // Transpose a `FramesBillingAddress` to either a USAddress or InternationalAddress
      if (international) {
        return {
          street: initialValues.billingAddress.addressLine1,
          locality: initialValues.billingAddress.city,
          administrativeArea: initialValues.billingAddress.state,
          postalCode: initialValues.billingAddress.zip,
          countryIso3: nonUSCountries.find(
            ({ countryCodeAlpha2 }) =>
              initialValues.billingAddress?.country === countryCodeAlpha2,
          )?.countryCodeAlpha3 as NonUSCountryCodeAlpha3,
        };
      }

      return {
        streetLine: initialValues.billingAddress.addressLine1,
        secondary: initialValues.billingAddress.addressLine2,
        city: initialValues.billingAddress.city,
        state: initialValues.billingAddress.state,
        zipcode: initialValues.billingAddress.zip,
        entries: 1,
      };
    }

    return undefined;
  });
  const [cardBrand, setCardBrand] = useState<PaymentMethod>();
  /* cspell:disable-next-line: This is a type from CKO's library. */
  const [frameFocused, setFrameFocused] = useState<FrameElementIdentifer>();
  const isDarkMode = useMemo(
    () => document.documentElement.classList.contains("dark-mode"),
    [],
  );
  const previousFormEnabled = usePrevious(formEnabled);
  const [tokenizationAttempts, setTokenizationAttempts] = useState(0);

  const handleFormSubmit = useCallback<FormEventHandler>(
    async (event) => {
      event.preventDefault();

      setRequestIsInFlight(true);
      const tokenizationStartTime = performance.now();

      try {
        posthog?.capture(TelemetryEvents.ckoTokenizationStart, {
          sessionId,
          attempts: tokenizationAttempts + 1,
        });
        setTokenizationAttempts((count) => count + 1);

        const result = await Frames.submitCard();

        const onTokenizationError = (
          message: string,
          reason:
            | "invalid_card_type"
            | "invalid_card_scheme"
            | "invalid_card_category",
        ) => {
          const duration = performance.now() - tokenizationStartTime;
          posthog?.capture(TelemetryEvents.ckoTokenizationComplete, {
            status: "error",
            duration,
            reason,
            sessionId,
            property,
            attempts: tokenizationAttempts,
          });

          toast?.error(message, { id: ToastIds.TOKENIZED_CARD_ERROR });
          Frames.enableSubmitForm();
          setRequestIsInFlight(false);
        };

        // There is a discrepancy in the CKO docs and types. Cases are mixed across the two. Calling `.toLowerCase()` allows us to normalize the response.
        if (import.meta.env.VITE_TIGRIS_ENV === "dev") {
          // We cannot test debit cards in dev. This early return bypasses that constraint on the client-side.
          posthog?.capture(TelemetryEvents.ckoTokenizationComplete, {
            status: "success",
            duration: performance.now() - tokenizationStartTime,
            sessionId,
            property,
            attempts: tokenizationAttempts,
          });
          onTokenizationSuccess(result.token);
        } else if (result.card_type?.toLowerCase() !== "debit") {
          onTokenizationError(
            ErrorMessages.addPaymentCard.DEBIT_CARDS_ONLY_ERROR,
            "invalid_card_type",
          );
        } else if (
          !result.scheme ||
          !["visa", "mastercard"].includes(result.scheme.toLowerCase())
        ) {
          onTokenizationError(
            ErrorMessages.addPaymentCard.UNSUPPORTED_CARD_SCHEME_ERROR,
            "invalid_card_scheme",
          );
        } else if (result.card_category?.toLowerCase() !== "consumer") {
          onTokenizationError(
            ErrorMessages.addPaymentCard.CONSUMER_CARDS_ONLY_ERROR,
            "invalid_card_category",
          );
        } else {
          const duration = performance.now() - tokenizationStartTime;

          posthog?.capture(TelemetryEvents.ckoTokenizationComplete, {
            status: "success",
            duration,
            sessionId,
            property,
            attempts: tokenizationAttempts,
          });

          onTokenizationSuccess(result.token);
        }
      } catch (err: unknown) {
        setRequestIsInFlight(false);
        toast?.error(
          ErrorMessages.addPaymentCard.CHECKOUT_TOKENIZATION_FAILED,
          { id: ToastIds.CHECKOUT_TOKENIZATION_FAILED },
        );
        sentry?.captureException(err, {
          tags: { integration: "checkout frames" },
        });
      }
    },
    [
      onTokenizationSuccess,
      posthog,
      sentry,
      sessionId,
      toast,
      tokenizationAttempts,
    ],
  );

  useEffect(() => {
    if (checkoutScriptLoadStatus === "error") {
      posthog?.capture(TelemetryEvents.ckoIframesLoadError);
      toast?.error(ErrorMessages.addPaymentCard.GENERIC_ERROR);
    }
  }, [checkoutScriptLoadStatus, posthog, toast]);

  // Load CKO script when component mounts
  useEffect(() => {
    if (formEnabled) {
      loadScript(CHECKOUT_SCRIPT_SRC);
    }
  }, [formEnabled, loadScript]);

  useEffect(() => {
    const endTime = performance.now();

    if (!previousFormEnabled && formEnabled && formIsReady) {
      posthog?.capture(TelemetryEvents.ckoIframesReady, {
        duration: endTime - checkoutScriptLoadStartTime,
      });
      Frames.enableSubmitForm();
      setRequestIsInFlight(false);
    }
  }, [
    checkoutScriptLoadStartTime,
    formEnabled,
    formIsReady,
    posthog,
    previousFormEnabled,
  ]);

  const onUSAddressResolved = useCallback<
    USAddressCaptureProps["onAddressResolved"]
  >(
    ({ address, rawValue }) => {
      if (!address) {
        // If we can't create a full `Suggestion`, we still have the raw input which we can validate for the existence of a PO Box
        if (rawAddressContainsPOBox(rawValue)) {
          toast?.error(ErrorMessages.address.PO_BOX_NOT_ALLOWED_ERROR, {
            id: ToastIds.PO_BOX_NOT_ALLOWED,
          });
        } else {
          toast?.dismiss(ToastIds.PO_BOX_NOT_ALLOWED);
        }

        setFormState((previousState) => {
          return {
            ...previousState,
            isValid: false,
            fields: {
              ...previousState.fields,
              billingAddress: {
                value: { ...defaultBillingAddress },
                isValid: false,
                isDirty:
                  previousState.fields.billingAddress.isDirty ||
                  address !== null ||
                  rawValue.length > 0,
                isTouched: true,
              },
            },
          };
        });
        return;
      }

      const mappedSuggestion: FramesBillingAddress = {
        addressLine1: address.streetLine,
        addressLine2: address.secondary,
        city: address.city,
        state: address.state,
        zip: address.zipcode,
        country: CountryCodeAlpha2.US,
      };

      const addressValidationResult =
        billingAddressSchema.safeParse(mappedSuggestion);
      const isValid = addressValidationResult.success;

      if (!isValid) {
        if (isPOBoxError(addressValidationResult.error)) {
          toast?.error(ErrorMessages.address.PO_BOX_NOT_ALLOWED_ERROR, {
            id: ToastIds.PO_BOX_NOT_ALLOWED,
          });
        }

        if (isProhibitedRegionError(addressValidationResult.error)) {
          posthog?.capture(TelemetryEvents.onboardingProhibitedRegion, {
            formId,
            inputId: "billingAddress",
            region: mappedSuggestion.state,
          });
          toast?.error(
            ErrorMessages.addPaymentCard.PROHIBITED_US_AND_TERRITORY_CODE,
            { id: ToastIds.PROHIBITED_REGION },
          );
        }
      } else {
        toast?.dismiss(ToastIds.PROHIBITED_REGION);
        toast?.dismiss(ToastIds.PO_BOX_NOT_ALLOWED);
      }

      setFormState((previousState) => ({
        ...previousState,
        isValid:
          isValid &&
          Object.entries(previousState.fields)
            .filter(([key, _]) => key !== "billingAddress")
            .every(([_, { isValid }]) => isValid),
        fields: {
          ...previousState.fields,
          billingAddress: {
            ...previousState.fields.billingAddress,
            isValid,
            isDirty: true,
            isTouched: true,
            value: mappedSuggestion,
          },
        },
      }));
    },
    [formId, posthog, toast],
  );

  const handleInternationalAddressChange = useCallback<
    InternationalAddressCaptureProps["onChange"]
  >(({ address, isValid }) => {
    if (!address) {
      return;
    }

    const mappedSuggestion: FramesBillingAddress = {
      addressLine1: address.street,
      // Some countries such as Iceland and Bulgaria do not return locality, but instead use `administrativeArea`
      city: address.locality ?? address.administrativeArea,
      // Some countries such as Cyprus, Latvia, Malta, and Slovenia do not return `administrativeAreaShort`
      state: address.administrativeAreaShort ?? address.administrativeArea,
      zip: address.postalCode,
      country: countries.find(
        (country) => country.countryCodeAlpha3 === address.countryIso3,
      )?.countryCodeAlpha2,
    };

    setFormState((previousState) => ({
      ...previousState,
      isValid:
        isValid &&
        Object.entries(previousState.fields)
          .filter(([key, _]) => key !== "billingAddress")
          .every(([_, { isValid }]) => isValid),
      fields: {
        ...previousState.fields,
        billingAddress: {
          ...previousState.fields.billingAddress,
          isValid,
          isDirty: true,
          isTouched: true,
          value: mappedSuggestion,
        },
      },
    }));
  }, []);

  const renderFieldAsValid = useCallback(
    (fieldKey: keyof FormState["fields"]): boolean => {
      const field = formState.fields[fieldKey];

      if (!field.isTouched || field.isValid) {
        return true;
      }

      if (field.isTouched && field.isDirty) {
        return field.isValid;
      }

      return true;
    },
    [formState.fields],
  );

  const onBlur = useCallback(
    (fieldName: keyof FormState["fields"]) => () => {
      setFormState((previousState) => ({
        ...previousState,
        isTouched: true,
        isValid: Object.values(previousState.fields).every(
          (field) => field.isValid,
        ),
        isDirty: Object.values(previousState.fields).every(
          (field) => field.isDirty,
        ),
        fields: {
          ...previousState.fields,
          [fieldName]: {
            ...previousState.fields[fieldName],
            isTouched: true,
          },
        },
      }));
    },
    [],
  );

  const onChange = useCallback(function onChange<
    T = string | FramesBillingAddress,
  >({
    fieldName,
    format,
    validationSchema,
  }: {
    fieldName: keyof FormState["fields"];

    format?: (props: { previousValue: T; newValue: T }) => T;
    /**
     * A [Zod](https://zod.dev) schema to perform validation via `safeParse`.
     */
    validationSchema: z.ZodSchema;
  }) {
    return (event: ChangeEvent<HTMLInputElement>) => {
      setFormState((previousState) => {
        const newValue =
          typeof format === "function"
            ? format({
                previousValue: previousState.fields[fieldName].value as T,
                newValue: event.target.value as T,
              })
            : event.target.value;

        const isDirty =
          previousState.fields[fieldName].isTouched ||
          newValue !== previousState.fields[fieldName].value;

        let isValid = false;

        const result = validationSchema.safeParse(newValue);

        isValid = result.success;

        return {
          ...previousState,
          isValid:
            isValid &&
            Object.entries(previousState.fields)
              .filter(([key, _]) => key !== fieldName)
              .every(([_, { isValid }]) => isValid),
          fields: {
            ...previousState.fields,
            [fieldName]: {
              ...previousState.fields[fieldName],
              value: newValue,
              isValid,
              isDirty,
            },
          },
        };
      });
    };
  }, []);

  const framesConfig = useMemo<FramesInitProps>(
    () => ({
      debug: import.meta.env.VITE_TIGRIS_ENV === "dev",
      publicKey: import.meta.env.VITE_CHECKOUT_PUBLIC_KEY,
      localization: {
        cardNumberPlaceholder: "Card Number",
        expiryMonthPlaceholder: "MM",
        expiryYearPlaceholder: "YY",
        cvvPlaceholder: "CVV",
      },
      style: {
        base: {
          color: isDarkMode ? "white" : "rgb(50, 59, 60)",
          // autofill from pw manager may cause unreadable text in dark mode
          backgroundColor: isDarkMode ? "rgb(64 64 64)" : undefined,
          fontFamily: "Inter, system-ui, Avenir, Helvetica, Arial, sans-serif",
          letterSpacing: "normal",
          fontWeight: "normal",
        },
        invalid: {
          color: "red",
          fontWeight: "normal",
        },
        placeholder: {
          base: {
            opacity: "40%",
            fontFamily:
              "Inter, system-ui, Avenir, Helvetica, Arial, sans-serif",
            fontWeight: "normal",
            letterSpacing: "normal",
          },
        },
      },
      cardholder: {
        billingAddress: formState.fields.billingAddress.value,
        name: formState.fields.cardholderName.value,
      },
    }),
    [
      formState.fields.billingAddress.value,
      formState.fields.cardholderName.value,
      isDarkMode,
    ],
  );

  const disableInputs = requestIsInFlight || !formIsReady || disabled;
  const disableFormSubmit =
    requestIsInFlight || !formIsReady || !formState.isValid || disabled;

  return (
    <form
      id={formId}
      name={formId}
      onSubmit={handleFormSubmit}
      className="relative flex h-full flex-grow flex-col justify-between gap-2"
      data-testid={formId}
    >
      <div className="flex flex-col gap-1.5">
        <Title.Medium bold>Add a Debit Card</Title.Medium>
        <Text>Enter your debit card information to continue.</Text>

        <AnimatePresence>
          {checkoutScriptLoadStatus === "ready" && (
            <motion.section
              className="z-10 flex flex-col gap-4"
              key="AddPaymentCardInputs"
              data-testid="AddPaymentCardInputs"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
            >
              <div>
                <Input
                  name="cardholderName"
                  placeholder="Name on Card"
                  value={formState.fields.cardholderName.value}
                  isValid={renderFieldAsValid("cardholderName")}
                  disabled={disableInputs}
                  onChange={onChange<string>({
                    fieldName: "cardholderName",
                    format: formatCardholderName,
                    validationSchema: cardholderNameSchema,
                  })}
                  onBlur={onBlur("cardholderName")}
                  maxLength={255}
                  autoComplete="cc-name"
                  className="!rounded-none !rounded-t-2xl !border-b-0 ring-inset"
                />
                <Frames
                  config={framesConfig}
                  ready={() => {
                    setFormIsReady(true);
                  }}
                  frameFocus={(e) => {
                    setFrameFocused(e.element);
                    toast?.dismiss(ToastIds.TOKENIZED_CARD_ERROR);
                  }}
                  frameBlur={(e) => {
                    setFrameFocused((currentFrame) => {
                      if (currentFrame !== e.element) return currentFrame;
                    });
                    setFormState((previousState) => ({
                      ...previousState,
                      fields: {
                        ...previousState.fields,
                        cardDetails: {
                          ...previousState.fields.cardDetails,
                          isTouched: true,
                        },
                      },
                    }));
                  }}
                  paymentMethodChanged={({ paymentMethod }) => {
                    setCardBrand(paymentMethod);
                  }}
                  cardValidationChanged={({ isValid }) => {
                    setFormState((previousState) => ({
                      isValid:
                        isValid &&
                        Object.entries(previousState.fields)
                          .filter(([key, _]) => key !== "cardDetails")
                          .every(([_, { isValid }]) => isValid),
                      fields: {
                        ...previousState.fields,
                        cardDetails: {
                          ...previousState.fields.cardDetails,
                          isValid,
                        },
                      },
                    }));
                  }}
                  cardTokenizationFailed={(error) => {
                    toast?.error(
                      ErrorMessages.addPaymentCard.CHECKOUT_TOKENIZATION_FAILED,
                      { id: ToastIds.CHECKOUT_TOKENIZATION_FAILED },
                    );

                    sentry?.captureException(
                      ErrorMessages.addPaymentCard.CHECKOUT_TOKENIZATION_FAILED,
                      {
                        tags: { integration: "checkout frames" },
                        extra: { error },
                      },
                    );
                  }}
                >
                  <PaymentCardInputFrame
                    isFocused={!!frameFocused}
                    disabled={disableInputs}
                    cardBrand={cardBrand}
                    formIsReady={formIsReady}
                  />
                </Frames>
                <div className="mt-1 flex items-center text-xs font-medium tracking-tight opacity-60 dark:text-white">
                  <FontAwesomeIcon
                    icon={icon({ name: "info-circle", style: "solid" })}
                    className="mr-1"
                  />
                  <div>Make sure your name matches what&apos;s on the card</div>
                </div>
              </div>
              <div>
                {international ? (
                  <InternationalAddressCapture
                    initialCountryCodeAlpha3={
                      initialCountryCodeAlpha3 as NonUSCountryCodeAlpha3
                    }
                    onChange={handleInternationalAddressChange}
                    initialValue={
                      initialAddressValue as InternationalLookupAddressDetail
                    }
                  />
                ) : (
                  <USAddressCapture
                    labelText="Billing Address"
                    placeholder="Your Billing Address"
                    onAddressResolved={onUSAddressResolved}
                    disabled={disableInputs}
                    isValid={renderFieldAsValid("billingAddress")}
                    inputName="billingAddress"
                    initialValue={initialAddressValue as USAddressSuggestion}
                  />
                )}
              </div>
            </motion.section>
          )}
        </AnimatePresence>
      </div>

      <Button
        key="AddPaymentCard:button"
        disabled={disableFormSubmit}
        type="submit"
        isLoading={requestIsInFlight}
      >
        Continue
      </Button>
    </form>
  );
};
