import { z } from "zod";
import { StubDocumentReceivedWebhookInput } from "../generated/sdk";
import { Api, UrlString } from "../types";
import { err, ok, Result } from "neverthrow";

const stubDocumentReceivedWebhookInputSchema = z.object({
  dateOfBirth: z.string().regex(/\d{4}-\d{2}-\d{2}/),
  firstName: z.string().min(1, "A valid first name is required."),
  lastName: z.string().min(1, "A valid last name is required."),
  number: z.string().min(1, "A valid number is required."),
  kind: z.enum(["driversLicense", "passport"]),
  issuingCountry: z.string().regex(/\w{2}/),
  verificationId: z.string().min(1, "A valid verification ID is required."),
}) satisfies z.ZodType<StubDocumentReceivedWebhookInput>;

export const idvStubHandler = (
  identityDocumentUrl: UrlString,
  resolveStubIDVReceivedWebhook: Api["resolveStubIDVReceivedWebhook"],
): Promise<Result<true, undefined>> =>
  new Promise((resolve) => {
    const handler = async (
      event: MessageEvent<StubDocumentReceivedWebhookInput>,
    ) => {
      if (event.origin === new URL(identityDocumentUrl).origin) {
        window.removeEventListener("message", handler);

        const parsedMessage = stubDocumentReceivedWebhookInputSchema.safeParse(
          event.data,
        );

        if (parsedMessage.success) {
          const stubIDVReceivedWebhookResult =
            await resolveStubIDVReceivedWebhook({
              input: parsedMessage.data,
            });

          if (stubIDVReceivedWebhookResult.isErr()) {
            resolve(err(undefined));
          }

          resolve(ok(true));
        } else {
          resolve(err(undefined));
        }
      }
    };

    window.addEventListener("message", handler);
  });
