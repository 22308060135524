import { AppContext } from "@src/contexts/AppContextProvider";
import { useRef, useEffect, useContext } from "react";
import { spring } from "../utils/animation";
import { motion } from "framer-motion";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import { Routes } from "@src/utils/constants";
import { OutletContext } from "@src/types";
import { useApplePayContext } from "@src/hooks/useApplePayContext";
import { Posthog, TelemetryEvents } from "@tigris/common";

const APPLE_PAY_JS_API_VERSION = 3;

const buttonAnimation = {
  initial: { opacity: 0, y: 24 },
  exit: { opacity: 0, y: 24, transition: spring },
  animate: { opacity: 1, y: 0, transition: { ...spring, delay: 0.25 } },
};

export const ApplePayButton = ({
  executeTransfer,
  buttonstyle = "black",
  type = "plain",
  locale = "us-EN",
}: {
  executeTransfer: (payment: ApplePayJS.ApplePayPayment) => Promise<number>;
  buttonstyle?: string;
  type?: string;
  locale?: string;
}) => {
  const {
    useQuote: { quote },
  } = useOutletContext<OutletContext>();
  const { applePayInitiativeContext, setApplePayCanceled } =
    useApplePayContext();
  const navigate = useNavigate();
  const { search } = useLocation();
  const {
    configuration: { destinationAsset },
    api: { resolveRequestApplePayPaymentSession },
  } = useContext(AppContext);
  const buttonRef = useRef<HTMLElement>(null);

  useEffect(() => {
    if (!quote || !buttonRef.current) {
      return;
    }

    let activeSession: ApplePaySession | null = null;
    const currButton = buttonRef.current;

    const handleButtonClick = (e: MouseEvent) => {
      if (activeSession) {
        return;
      }

      e.stopPropagation();
      Posthog.capture(TelemetryEvents.applePayButtonClick);

      const request: ApplePayJS.ApplePayPaymentRequest = {
        countryCode: "US",
        currencyCode: "USD",
        merchantCapabilities: ["supports3DS", "supportsDebit"],
        supportedNetworks: ["visa", "masterCard"],
        requiredBillingContactFields: ["name", "postalAddress"],
        requiredShippingContactFields: ["phone", "email"],
        total: {
          label: `$${quote.sourceTotal.amount} for ${quote.destination.amount} ${destinationAsset}`,
          type: "final",
          amount: quote.sourceTotal.amount,
        },
      };

      activeSession = new ApplePaySession(APPLE_PAY_JS_API_VERSION, request);

      activeSession.onvalidatemerchant = async () => {
        if (!applePayInitiativeContext) {
          activeSession?.abort();
          activeSession = null;
          return;
        }

        const applePayPaymentSessionResult =
          await resolveRequestApplePayPaymentSession({
            input: {
              initiativeContext: applePayInitiativeContext,
            },
          });

        if (applePayPaymentSessionResult.isErr()) {
          activeSession?.abort();
          activeSession = null;
        } else {
          activeSession?.completeMerchantValidation(
            applePayPaymentSessionResult.value,
          );
        }
      };

      activeSession.onpaymentauthorized = async ({ payment }) => {
        Posthog.capture(TelemetryEvents.applePayPaymentAuthorized);

        const status = await executeTransfer(payment);
        Posthog.capture(TelemetryEvents.applePayPaymentComplete, { status });
        activeSession?.completePayment({ status });

        activeSession = null;

        if (status === ApplePaySession.STATUS_SUCCESS) {
          navigate({ pathname: Routes.TransferSheetStatus, search });
        }
      };

      activeSession.oncancel = () => {
        Posthog.capture(TelemetryEvents.applePayCancel);
        setApplePayCanceled();
        activeSession = null;
      };

      activeSession.begin();
    };

    currButton.addEventListener("click", handleButtonClick);

    return () => {
      if (activeSession) {
        activeSession.abort();
        activeSession = null;
      }
      currButton.removeEventListener("click", handleButtonClick);
    };
  }, [
    applePayInitiativeContext,
    destinationAsset,
    executeTransfer,
    navigate,
    quote,
    resolveRequestApplePayPaymentSession,
    search,
    setApplePayCanceled,
  ]);

  return (
    <motion.div
      key="AnimatedApplePayButton"
      variants={buttonAnimation}
      initial="initial"
      animate="animate"
      exit="exit"
      className="max-h-12"
      data-testid="ApplePayButton"
    >
      <apple-pay-button
        buttonstyle={buttonstyle}
        type={type}
        locale={locale}
        ref={buttonRef}
      />
    </motion.div>
  );
};
