import {
  ChangeEventHandler,
  FormEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  Text,
  Button,
  LabelledInput,
  PasswordValidity,
  PasswordStrength,
  spring,
  ErrorMessages,
  CustomToastError,
} from "@tigris/mesokit";
import { useThrottle } from "@uidotdev/usehooks";
import { toast } from "sonner";
import measurePasswordStrength from "zxcvbn";
import {
  MIN_PASSWORD_LENGTH,
  defaultFormField,
  emailSchema,
  passwordSchema,
} from "@tigris/common";
import { FormField, Routes } from "../types";
import { AnimatePresence, motion } from "framer-motion";
import AnimationContainer from "./AnimationContainer";
import Heading from "./Heading";
import { useRouter } from "../hooks/useRouter";
import { useOnboarding } from "../hooks/useOnboarding";
import { useApi } from "../hooks/useApi";
import { AutoFocusRef } from "../utils/autoFocusRef";
import { mailcheck } from "../utils/mailcheck";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useAgreements } from "../hooks/useAgreements";

type FormState = {
  isValid: boolean;
  isDirty: boolean;
  isTouched: boolean;
  fields: Record<"email" | "password", FormField>;
  toastErrorMessage: string | null;
};

const FORM_ID = "CreateAccount";
const TOAST_ID = FORM_ID;
const PASSWORD_TOO_BIG_TOAST_ID = `${FORM_ID}:password`;

const defaultFormState: FormState = {
  isValid: false,
  isDirty: false,
  isTouched: false,
  fields: { email: defaultFormField(""), password: defaultFormField("") },
  toastErrorMessage: null,
};

const EmailAccessory = ({ show }: { show: boolean }) => {
  return (
    <AnimatePresence mode="wait">
      {show && (
        <motion.div
          data-testid="CreateAccount:emailWarning"
          className="cursor-default text-xs font-normal text-neutral-400"
          variants={{
            initial: { opacity: 0, y: 6 },
            exit: { opacity: 0, y: 6 },
            animate: {
              opacity: 1,
              y: 0,
              transition: { ...spring },
            },
          }}
          initial="initial"
          animate="animate"
          exit="exit"
        >
          <FontAwesomeIcon icon={icon({ name: "warning" })} color="#ebb305" />{" "}
          Email may be invalid.
        </motion.div>
      )}
    </AnimatePresence>
  );
};

const PasswordAccessory = ({
  isValid,
  strength,
}: {
  isValid: boolean;
  strength: number;
}) => {
  return (
    <div className="flex cursor-default items-center gap-2 font-normal">
      <PasswordStrength strength={strength} validPasswordLength={isValid} />
      <PasswordValidity
        isValid={isValid}
        minPasswordLength={MIN_PASSWORD_LENGTH}
      />
    </div>
  );
};

export const CreateAccount = () => {
  const {
    api: { resolveCreateAccount },
  } = useApi();
  const { returnToTransfer } = useOnboarding();
  const { navigate } = useRouter();
  const [isLoading, setIsLoading] = useState(false);
  const [formState, setFormState] = useState<FormState>(defaultFormState);
  const [passwordStrength, setPasswordStrength] = useState(0);
  const throttled = useThrottle(formState.fields, 100);
  const emailInputRef = useRef<HTMLInputElement>(null);
  const [emailMayBeInvalid, setEmailMayBeInvalid] = useState<boolean>(false);
  const {
    Agreements,
    checked: agreementsChecked,
    submitAgreements,
  } = useAgreements();

  const handleSubmit = useCallback<FormEventHandler<HTMLFormElement>>(
    async (event) => {
      event.preventDefault();

      toast.dismiss();

      setIsLoading(true);

      const createAccountResult = await resolveCreateAccount({
        input: {
          email: formState.fields.email.value,
          password: formState.fields.password.value,
        },
      });

      if (createAccountResult.isErr()) {
        toast.error(
          <CustomToastError
            title={createAccountResult.error}
            body={
              <div>
                If you&apos;ve used Meso before or already started signing up,{" "}
                <a
                  href="#"
                  data-testid={`${TOAST_ID}:loginLink`}
                  onClick={(e: React.MouseEvent) => {
                    e.preventDefault();
                    returnToTransfer("returnToLogin");
                  }}
                  className="underline opacity-80 transition-opacity hover:opacity-100"
                >
                  log in
                </a>
                .
              </div>
            }
          />,
        );
        setIsLoading(false);
        return;
      }

      const submitAgreementsResult = await submitAgreements();

      if (submitAgreementsResult.isErr()) {
        toast.error(submitAgreementsResult.error, { id: TOAST_ID });
        setIsLoading(false);
        return;
      }

      navigate(Routes.PhoneEntry);
    },
    [
      formState.fields.email.value,
      formState.fields.password.value,
      navigate,
      resolveCreateAccount,
      returnToTransfer,
      submitAgreements,
    ],
  );

  const renderFieldAsValid = useCallback(
    (fieldKey: keyof FormState["fields"]): boolean => {
      const field = formState.fields[fieldKey];
      if (!field.isTouched || field.isValid) {
        return true;
      }

      if (field.isTouched && field.isDirty) {
        return field.isValid;
      }

      return true;
    },
    [formState.fields],
  );

  const onBlur = useCallback(
    (fieldName: keyof FormState["fields"]) => () => {
      setFormState((previousState) => ({
        ...previousState,
        isTouched: true,
        isValid: Object.values(previousState.fields).every(
          (field) => field.isValid,
        ),
        isDirty: Object.values(previousState.fields).every(
          (field) => field.isDirty,
        ),
        fields: {
          ...previousState.fields,
          [fieldName]: {
            ...previousState.fields[fieldName],
            isTouched: true,
          },
        },
      }));

      if (fieldName === "email") {
        // In the future, we may want to present suggested fixes for the entered address.
        // This library gives us that, but for now we are just rendering a generic message
        // and not using the returned suggested email.
        const suggestedEmailResult = mailcheck(formState.fields.email.value);

        if (
          suggestedEmailResult.isOk() &&
          suggestedEmailResult.value !== null
        ) {
          setEmailMayBeInvalid(true);
        } else {
          setEmailMayBeInvalid(false);
        }
      }
    },
    [formState.fields.email.value],
  );

  const handleEmailChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (event) => {
      setFormState((previousState) => {
        const newValue = event.target.value;

        const isDirty =
          previousState.fields.email.isTouched ||
          newValue !== previousState.fields.email.value;

        const result = emailSchema.safeParse(newValue);
        const isValid = result.success;

        return {
          ...previousState,
          isDirty: previousState.isDirty || isDirty,
          isValid:
            isValid &&
            Object.entries(previousState.fields)
              .filter(([key, _]) => key !== "email")
              .every(([_, { isValid }]) => isValid),
          fields: {
            ...previousState.fields,
            email: {
              ...previousState.fields.email,
              value: newValue,
              isValid,
              isDirty,
            },
          },
        };
      });
      setEmailMayBeInvalid(false);
    },
    [],
  );

  const handlePasswordChange = useCallback<
    ChangeEventHandler<HTMLInputElement>
  >((event) => {
    const newValue = event.target.value;
    let toastErrorMessage: FormState["toastErrorMessage"] = null;

    setFormState((previousState) => {
      const isDirty =
        previousState.fields.password.isTouched ||
        newValue !== previousState.fields.password.value;

      const result = passwordSchema.safeParse(newValue);
      const isValid = result.success;

      if (!result.success) {
        const errorCodes = result.error.issues.map((issue) => issue.code);

        if (errorCodes.includes("too_big")) {
          toastErrorMessage = ErrorMessages.password.TOO_BIG;
        }
      }

      return {
        ...previousState,
        toastErrorMessage,
        isDirty: previousState.isDirty || isDirty,
        isValid:
          isValid &&
          Object.entries(previousState.fields)
            .filter(([key, _]) => key !== "password")
            .every(([_, { isValid }]) => isValid),
        fields: {
          ...previousState.fields,
          password: {
            ...previousState.fields.password,
            value: newValue,
            isValid,
            isDirty,
          },
        },
      };
    });
  }, []);

  useEffect(() => {
    if (formState.toastErrorMessage) {
      toast.error(ErrorMessages.password.TOO_BIG, {
        id: PASSWORD_TOO_BIG_TOAST_ID,
      });
    } else {
      toast.dismiss(PASSWORD_TOO_BIG_TOAST_ID);
    }
  }, [formState.toastErrorMessage]);

  useEffect(() => {
    const passwordStrengthResult = measurePasswordStrength(
      throttled.password.value,
      [throttled.email.value],
    );
    setPasswordStrength(passwordStrengthResult.score);
  }, [throttled.email.value, throttled.password.value]);

  return (
    <AnimationContainer onAnimationComplete={AutoFocusRef(emailInputRef)}>
      <form
        id={FORM_ID}
        name={FORM_ID}
        data-testid={FORM_ID}
        onSubmit={handleSubmit}
        className="onboarding-inner-content"
      >
        <Heading title="Create Account" />

        <div className="flex flex-col gap-4">
          <div className="relative flex flex-col">
            <LabelledInput
              labelProps={{
                text: "Email",
                accessory: <EmailAccessory show={emailMayBeInvalid} />,
              }}
              name="email"
              id="email"
              value={formState.fields.email.value}
              data-testid={`${FORM_ID}:email`}
              isValid={renderFieldAsValid("email")}
              ref={emailInputRef}
              onChange={handleEmailChange}
              onBlur={onBlur("email")}
              placeholder="Your Email Address"
              type="email"
            />
          </div>

          <div className="flex flex-col gap-1">
            <LabelledInput
              labelProps={{
                text: "Password",
                accessory: (
                  <PasswordAccessory
                    isValid={formState.fields.password.isValid}
                    strength={passwordStrength}
                  />
                ),
              }}
              name="password"
              id="password"
              type="password"
              value={formState.fields.password.value}
              data-testid={`${FORM_ID}:password`}
              isValid={renderFieldAsValid("password")}
              onChange={handlePasswordChange}
              onBlur={onBlur("password")}
              placeholder="Enter a Password"
            />
          </div>
        </div>

        <motion.div
          variants={{
            initial: { opacity: 0, y: 48 },
            exit: { opacity: 0, y: 48, transition: spring },
            animate: {
              opacity: 1,
              y: 0,
              transition: { ...spring, delay: 0.05 },
            },
          }}
          initial="initial"
          animate="animate"
          exit="exit"
          className="group w-full cursor-pointer font-medium"
          data-testid="loginButton"
          onClick={() => {
            // For inline/embedded: Close onboarding window and navigate to email/password login
            // For standalone: Navigate to email/password login
            returnToTransfer("returnToLogin");
          }}
        >
          <Text className="text-xs">
            Have an account? Log in{" "}
            <span className="inline-flex transition-transform group-hover:translate-x-0.5">
              {"  "}→
            </span>
          </Text>
        </motion.div>
        <div className="onboarding-footer flex flex-col gap-3">
          <Agreements />

          <Button
            disabled={isLoading || !formState.isValid || !agreementsChecked}
            type="submit"
            isLoading={isLoading}
          >
            Create Account
          </Button>
        </div>
      </form>
    </AnimationContainer>
  );
};
