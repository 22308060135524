import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { InternationalLookupAddressDetail } from "../../../utils/smarty";
import { FormField, NonUSCountryCodeAlpha3 } from "../../../types";
import { defaultFormField, presenceSchema } from "@tigris/common";
import { z } from "zod";
import { Input } from "../../Input";
import {
  Country,
  defaultNonUSCountry,
  nonUSCountries,
} from "../../../utils/countries";
import { twMerge } from "tailwind-merge";
import { CountryCombobox } from "../../CountryCombobox";

export type InternationalAddressFormProps = {
  disabled?: boolean;
  initialAddress?: InternationalLookupAddressDetail;
  onChange: (props: {
    address?: InternationalLookupAddressDetail;
    isValid: boolean;
  }) => void;
  initialCountryCodeAlpha3: NonUSCountryCodeAlpha3;
  /**
   * Whether to allow the user to change the country set via `initialCountryCodeAlpha3`.
   *
   * Defaults to `true`.
   * */
  allowCountrySelection?: boolean;
};

type FormState = {
  isValid: boolean;
  isTouched: boolean;
  fields: {
    street: FormField;
    // city, town, village, etc
    locality: FormField;
    // state, county, province, etc.
    administrativeArea: FormField;
    postalCode: FormField;
  };
};

const defaultFormState = (
  address?: InternationalLookupAddressDetail,
): FormState => {
  return address
    ? {
        isValid: true,
        isTouched: true,
        fields: {
          street: {
            ...defaultFormField(address.street),
            isValid: true,
            isDirty: false,
            isTouched: true,
          },
          locality: {
            // Some countries such as Iceland and Bulgaria do not return locality, but instead use `administrativeArea`
            ...defaultFormField(address.locality ?? address.administrativeArea),
            isValid: true,
            isTouched: true,
          },
          administrativeArea: {
            ...defaultFormField(
              // Some countries such as Cyprus, Latvia, Malta, and Slovenia do not return `administrativeAreaShort`
              address.administrativeAreaShort ?? address.administrativeArea,
            ),
            isValid: true,
            isTouched: true,
          },
          postalCode: {
            ...defaultFormField(address.postalCode),
            isValid: true,
            isTouched: true,
          },
        },
      }
    : {
        isValid: false,
        isTouched: false,
        fields: {
          street: defaultFormField(""),
          locality: defaultFormField(""),
          administrativeArea: defaultFormField(""),
          postalCode: defaultFormField(""),
        },
      };
};

export const InternationalAddressForm = ({
  initialAddress,
  disabled = false,
  onChange,
  initialCountryCodeAlpha3,
  allowCountrySelection = true,
}: InternationalAddressFormProps) => {
  const [formState, setFormState] = useState<FormState>(() =>
    defaultFormState(initialAddress),
  );
  const [selectedCountry, setSelectedCountry] =
    useState<NonUSCountryCodeAlpha3>(initialCountryCodeAlpha3);

  const renderFieldAsValid = useCallback(
    (fieldKey: keyof FormState["fields"]): boolean => {
      const field = formState.fields[fieldKey];
      if (!field.isTouched || field.isValid) {
        return true;
      } else if (field.isTouched && field.isDirty) {
        return field.isValid;
      }
      return true;
    },
    [formState.fields],
  );

  const onBlur = useCallback(
    (fieldName: keyof FormState["fields"]) => () => {
      setFormState((previousState) => {
        return {
          ...previousState,
          fields: {
            ...previousState.fields,
            [fieldName]: {
              ...previousState.fields[fieldName],
              isTouched: true,
            },
          },
        };
      });
    },
    [],
  );

  const handleChange = useCallback(
    ({
      fieldName,
      validationSchema,
    }: {
      fieldName: keyof FormState["fields"];
      validationSchema: z.ZodSchema;
    }) => {
      return (event: ChangeEvent<HTMLInputElement>) => {
        setFormState((previousState) => {
          const newValue = event.target.value;

          const isDirty =
            previousState.fields[fieldName].isTouched ||
            newValue !== previousState.fields[fieldName].value;

          const isValid = validationSchema.safeParse(newValue).success;

          const newFields = {
            ...previousState.fields,
            [fieldName]: {
              ...previousState.fields[fieldName],
              value: newValue,
              isValid,
              isDirty,
            },
          };

          const formIsValid = Object.entries(newFields).every(
            ([_, { isValid }]) => isValid,
          );

          return {
            ...previousState,
            isTouched: true,
            isValid: formIsValid,
            fields: newFields,
          };
        });
      };
    },
    [],
  );

  // Dispatch the `onChange` callback after the state update is applied in `handleChange`. This prevents the bad setState() call warning/error.
  useEffect(() => {
    onChange({
      address: {
        street: formState.fields.street.value,
        locality: formState.fields.locality.value,
        administrativeArea: formState.fields.administrativeArea.value,
        postalCode: formState.fields.postalCode.value,
        countryIso3: selectedCountry,
      },
      isValid: formState.isValid,
    });
  }, [formState, onChange, selectedCountry]);

  const country = useMemo<Country>(() => {
    return nonUSCountries.find((c) => c.countryCodeAlpha3 === selectedCountry)!;
  }, [selectedCountry]);

  return (
    <div className="flex flex-col divide-y rounded-[16px] border dark:divide-neutral-600 dark:border-neutral-600">
      <div
        className={twMerge(
          "flex flex-col rounded-[16px] dark:bg-neutral-700",
          "divide-y dark:divide-neutral-600",
        )}
      >
        <CountryCombobox
          countries={nonUSCountries}
          onSelectCountry={(countryCode) => {
            setSelectedCountry(
              (nonUSCountries.find((c) => c.countryCodeAlpha2 === countryCode)
                ?.countryCodeAlpha3 ??
                defaultNonUSCountry.countryCodeAlpha3) as NonUSCountryCodeAlpha3,
            );
            setFormState(defaultFormState());
          }}
          disabled={disabled || !allowCountrySelection}
          initialCountry={country.countryCodeAlpha2}
        />
        <Input
          className="!rounded-b-none !rounded-t-none !border-none ring-inset ring-offset-0"
          name="addressLine1"
          placeholder="Address Line 1"
          value={formState.fields.street.value}
          isValid={renderFieldAsValid("street")}
          disabled={disabled}
          onChange={handleChange({
            fieldName: "street",
            validationSchema: presenceSchema,
          })}
          onBlur={onBlur("street")}
          autoComplete="disabled"
          autoCorrect="off"
          data-testid="international-address-form:address-line-1"
        />
      </div>
      <Input
        className="!rounded-none border-none ring-inset"
        placeholder="City"
        name="locality"
        value={formState.fields.locality.value}
        isValid={renderFieldAsValid("locality")}
        disabled={disabled}
        onChange={handleChange({
          fieldName: "locality",
          validationSchema: presenceSchema,
        })}
        onBlur={onBlur("locality")}
        autoComplete="address-level2"
        autoCorrect="off"
        data-testid="international-address-form:locality"
      />

      <div className="flex divide-x dark:divide-neutral-600">
        <Input
          className="!rounded-none !rounded-bl-2xl border-none ring-inset"
          placeholder="Province/State/County"
          name="administrativeArea"
          value={formState.fields.administrativeArea.value}
          isValid={renderFieldAsValid("administrativeArea")}
          disabled={disabled}
          onChange={handleChange({
            fieldName: "administrativeArea",
            validationSchema: presenceSchema,
          })}
          onBlur={onBlur("administrativeArea")}
          autoComplete="administrativeArea"
          autoCorrect="off"
          data-testid="international-address-form:administrative-area"
        />

        <Input
          className="!rounded-none !rounded-br-2xl border-none ring-inset"
          placeholder="Postal Code"
          name="postalCode"
          value={formState.fields.postalCode.value}
          isValid={renderFieldAsValid("postalCode")}
          disabled={disabled}
          onChange={handleChange({
            fieldName: "postalCode",
            validationSchema: presenceSchema,
          })}
          onBlur={onBlur("postalCode")}
          autoComplete="postal-code"
          autoCorrect="off"
          data-testid="international-address-form:postal-code"
        />
      </div>
    </div>
  );
};
