import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Transition,
} from "@headlessui/react";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { CobrandingLogo, BuyLimit } from "@tigris/mesokit";
import { MessageKind, IntegrationMode } from "@src/types";
import { useContext } from "react";
import { AppContext } from "@src/contexts/AppContext";
import { Posthog, TelemetryEvents } from "@tigris/common";

const MENU_ITEM_LINK_CLASS =
  "group flex cursor-pointer flex-row items-center rounded-full p-2 opacity-75 font-medium transition-all duration-150 hover:bg-neutral-100 hover:opacity-100 hover:dark:bg-neutral-800";

export default function SupportMenu() {
  const { bus, partner, mode, userLimits } = useContext(AppContext);

  return (
    <Menu as="div" className="relative mr-auto w-full text-left">
      {({ open }) => (
        <>
          <div className="relative">
            <Transition
              enter="transition duration-200 ease-out"
              enterFrom="transform scale-95 opacity-0"
              enterTo="transform scale-100 opacity-100"
              leave="transition duration-200 ease-out"
              leaveFrom="transform scale-100 opacity-100"
              leaveTo="transform scale-95 opacity-0"
            >
              <MenuItems className="absolute -left-3 -right-3 -top-3 rounded-[1.75rem] bg-white px-3 pb-4 pt-8 shadow-xl ring-1 ring-black ring-opacity-5 focus:outline-none dark:bg-neutral-700">
                <div className="mt-4">
                  {userLimits && <BuyLimit limits={userLimits} />}
                  <MenuItem>
                    <a
                      href="https://support.meso.network"
                      target="_blank"
                      rel="noreferrer"
                      className={MENU_ITEM_LINK_CLASS}
                      onClick={() =>
                        Posthog.capture(TelemetryEvents.supportMenuGetHelpClick)
                      }
                    >
                      <FontAwesomeIcon
                        icon={icon({
                          name: "life-ring",
                          style: "regular",
                        })}
                        className="mr-2 scale-90 opacity-50 transition-transform group-hover:scale-100"
                      />
                      <span className="transition-all group-hover:translate-x-1">
                        Get Help
                      </span>
                    </a>
                  </MenuItem>
                  <MenuItem>
                    <div
                      className={MENU_ITEM_LINK_CLASS}
                      onClick={(event) => {
                        event.preventDefault();

                        Posthog.capture(TelemetryEvents.supportMenuClose);

                        if (
                          mode === IntegrationMode.EMBEDDED ||
                          mode === IntegrationMode.WEBVIEW
                        ) {
                          bus!.emit({ kind: MessageKind.CLOSE });
                        }
                      }}
                    >
                      <FontAwesomeIcon
                        icon={icon({
                          name: "arrow-left-from-line",
                          style: "regular",
                        })}
                        className="mr-2 scale-90 opacity-50 transition-transform group-hover:scale-100"
                      />
                      <span className="transition-all group-hover:translate-x-1">
                        Exit
                      </span>
                    </div>
                  </MenuItem>
                </div>
              </MenuItems>
            </Transition>
          </div>
          <div className="relative flex items-center justify-between">
            <MenuButton
              className="support-menu-button flex h-6 w-6 items-center justify-center transition-all active:scale-95"
              data-testid="support-menu-button"
              onClick={() =>
                Posthog.capture(TelemetryEvents.supportMenuToggle, {
                  isOpen: !open,
                })
              }
            >
              <FontAwesomeIcon icon={icon({ name: "bars", style: "light" })} />
            </MenuButton>
            {/* The `ml-[24px]` is required to account for the offset of the
            CobrandingLogo which is out of the flow and pushes its contents over
            24px. */}
            {/* <div className="ml-[24px]">
              <EnvironmentBanner />
            </div> */}
            <CobrandingLogo
              displayName={partner?.displayName}
              logoUri={partner?.logoUri}
            />
          </div>
        </>
      )}
    </Menu>
  );
}
