import { Routes } from "@src/utils/constants";
import { Result, err, ok } from "neverthrow";
import { ErrorMessages } from "@tigris/mesokit";
import {
  LoginFragment,
  ProfileFieldStatus,
  TransferKind,
} from "@src/generated/sdk";
import { CountryCodeAlpha2, InternalLinkPath, UserStatus } from "@src/types";
import { NavigateOptions } from "react-router-dom";

type ProfileStatus = LoginFragment["profileStatus"];

type NextRoute = {
  to: InternalLinkPath;
  options?: NavigateOptions;
};

export const requiredInternationalOnboardingSteps: Array<keyof ProfileStatus> =
  [
    "acceptedMesoTerms",
    "account",
    "financialInstrument",
    "basicInfo",
    "phone",
    "phoneVerified",
    "residentialAddress",
    "taxpayerId",
    "citizenship",
    "acknowledgedScamWarning",
    "residenceCountry",
    "pesel",
    "identityDocuments",
  ];

export const requiredUSOnboardingSteps: Array<keyof ProfileStatus> = [
  "acceptedMesoTerms",
  "acceptedZeroHashTerms",
  "account",
  "financialInstrument",
  "basicInfo",
  "phone",
  "phoneVerified",
  "residentialAddress",
  "taxpayerId",
  "citizenship",
  "acknowledgedScamWarning",
  "residenceCountry",
];

const hasCompletedOnboardingSteps = (profileStatus: ProfileStatus): boolean => {
  if (profileStatus.residenceCountry !== CountryCodeAlpha2.US) {
    // Check int'l steps

    return requiredInternationalOnboardingSteps.every((key) => {
      if (key === "pesel") {
        return (
          profileStatus.pesel !== ProfileFieldStatus.REQUIRED &&
          profileStatus.pesel !== ProfileFieldStatus.UNKNOWN
        );
      }

      return Boolean(profileStatus[key]) === true;
    });
  }

  // Check US steps

  return requiredUSOnboardingSteps.every((key) => {
    return Boolean(profileStatus[key]) === true;
  });
};

export const nextAuthStep = ({
  profileStatus,
  needsTwoFactor,
  transferKind,
  userStatus,
  search,
}: {
  profileStatus: ProfileStatus;
  needsTwoFactor: boolean;
  transferKind: TransferKind;
  userStatus?: UserStatus;
  search: string;
}): Result<NextRoute, string> => {
  switch (userStatus) {
    // This should be sufficient to skip looking at the `account` property and allow previously onboarded users to proceed with their transfer
    case UserStatus.ACTIVE: {
      if (needsTwoFactor) {
        return ok({ to: { pathname: Routes.TransferSheet2Fa, search } });
      } else if (transferKind === TransferKind.CASH_IN) {
        return ok({ to: { pathname: Routes.TransferSheet, search } });
      } else {
        return ok({
          to: { pathname: Routes.TransferSheetDepositAddress, search },
        });
      }
    }

    case UserStatus.NEW: {
      if (hasCompletedOnboardingSteps(profileStatus) && !needsTwoFactor) {
        return ok({ to: { pathname: Routes.ActivateUser, search } });
      } else if (profileStatus.phoneVerified && needsTwoFactor) {
        return ok({
          to: { pathname: Routes.TransferSheet2Fa, search },
          options: { state: { profileStatus } },
        });
      } else if (!hasCompletedOnboardingSteps(profileStatus)) {
        return ok({ to: { pathname: Routes.Onboarding, search } });
      } else {
        return err(ErrorMessages.onboardingSteps.UNABLE_TO_DETERMINE_NEXT_STEP);
      }
    }

    case UserStatus.PROVISIONAL: {
      if (hasCompletedOnboardingSteps(profileStatus)) {
        return ok({ to: { pathname: Routes.ActivateUser, search } });
      }
    }

    default:
      return ok({ to: { pathname: Routes.TransferUnavailable, search } });
  }
};
