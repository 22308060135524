import { AppContext } from "@src/contexts/AppContextProvider";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  AnonQuoteInput,
  QuoteInput,
  SingleUseInstrument,
  TransferKind,
} from "@src/generated/sdk";
import { ErrorMessages } from "@tigris/mesokit";
import { Sentry } from "@tigris/common";
import { toast } from "sonner";
import { useTimeout } from "./useTimeout";
import { AssetAmount, UseQuoteHook } from "@src/types";
import { QuoteLimitErrorCode } from "@src/api";
import { useOnboarding } from "./useOnboarding";
import { Routes } from "@src/utils/constants";
import { useLocation } from "react-router-dom";
import { singleUseInstrumentSchema } from "@src/utils/validation";

export type UseQuoteConfiguration = {
  /**
   * An optional callback to handle meso and user-specific limit errors for authenticated quotes.
   *
   * If this callback is provided a toast _will not_ be rendered by the hook. Instead, the calling component must handle the error and it's presentation.
   */
  onLimitError?: (code?: QuoteLimitErrorCode) => void;
};

// time before the quote expires before we request a new quote
const GRACE_MS = 18_000;
const TOAST_ID = "quote_error";

/**
 *  A hook to poll for quotes. If a `Quote` input is provided, the hook will fetch authorized quote. If not, unauthorized quotes will be requested.
 */
export const useQuote = ({ onLimitError }: UseQuoteConfiguration = {}) => {
  const {
    api: {
      resolveQuote,
      resolveAnonQuote,
      resolveCreateCashOutQuote,
      resolveCreateAnonCashOutQuote,
    },
    configuration: {
      sourceAmount,
      sourceCAIPAsset,
      destinationAmount,
      destinationCAIPAsset,
      walletAddress,
      transferKind,
      externalId,
    },
    user: { walletInstruments },
    fiatInstrument,
    session,
  } = useContext(AppContext);
  const { onboardingInProgress } = useOnboarding();
  const [isPolling, setIsPolling] = useState(true);
  const [state, setState] = useState<UseQuoteHook>({
    restart() {
      setExpiration({ value: GRACE_MS });
      setIsPolling(true);
    },
    stop: () => setIsPolling(false),
  });
  const { pathname } = useLocation();
  const authenticatedCashInQuoteRequestInFlight = useRef(false);

  const [expiration, setExpiration] = useState<{ value: number }>({
    value: GRACE_MS,
  });

  const shouldSuspendPolling = useMemo(() => {
    return (
      onboardingInProgress ||
      pathname === Routes.TransferUnavailable ||
      pathname === Routes.ActivateUser
    );
  }, [onboardingInProgress, pathname]);

  // calculate primitive values to use as inputs dependencies
  const fiatInstrumentId = useMemo(() => {
    return fiatInstrument && typeof fiatInstrument === "object"
      ? fiatInstrument.id
      : undefined;
  }, [fiatInstrument]);
  const deferredSourceInstrument = useMemo(() => {
    return singleUseInstrumentSchema.safeParse(fiatInstrument).success
      ? (fiatInstrument as SingleUseInstrument)
      : undefined;
  }, [fiatInstrument]);
  const walletInstrumentId = useMemo(() => {
    if (
      walletInstruments &&
      walletInstruments.collection &&
      walletInstruments.collection.length
    ) {
      const walletInstrumentId = walletInstruments.collection.find(
        ({ address: walletInstrumentAddress }) =>
          walletInstrumentAddress.toLowerCase() === walletAddress.toLowerCase(),
      )?.id;

      return walletInstrumentId;
    }

    return undefined;
  }, [walletAddress, walletInstruments]);

  const inputs = useMemo(() => {
    // reset expiration to trigger fetch when inputs change
    setExpiration({ value: GRACE_MS });

    // reset authenticated flag when there is no instrument
    if (!fiatInstrumentId && !deferredSourceInstrument) {
      authenticatedCashInQuoteRequestInFlight.current = false;
    }

    return {
      fiatInstrumentId,
      deferredSourceInstrument,
      walletInstrumentId,
      sourceAsset: sourceCAIPAsset,
      destinationAsset: destinationCAIPAsset,
      sourceAmount,
      destinationAmount,
      riskSessionKey: session?.riskSession?.sessionKey,
    };
  }, [
    deferredSourceInstrument,
    destinationAmount,
    destinationCAIPAsset,
    fiatInstrumentId,
    session?.riskSession?.sessionKey,
    sourceAmount,
    sourceCAIPAsset,
    walletInstrumentId,
  ]);

  const fetchQuote = useCallback(async () => {
    if (!isPolling || !session) {
      return;
    }

    if (transferKind === TransferKind.CASH_IN) {
      if (
        inputs &&
        (inputs.fiatInstrumentId || inputs.deferredSourceInstrument) &&
        inputs.walletInstrumentId
      ) {
        authenticatedCashInQuoteRequestInFlight.current = true;
        const input: QuoteInput = {
          sourceInstrumentId: inputs.fiatInstrumentId,
          deferredSourceInstrument: inputs.deferredSourceInstrument,
          destAsset: inputs?.destinationAsset,
          sourceAsset: inputs?.sourceAsset,
          destInstrumentId: inputs.walletInstrumentId,
        };

        if (inputs?.destinationAmount) {
          input.destAmount = inputs?.destinationAmount;
        } else if (inputs?.sourceAmount) {
          input.sourceAmount = inputs?.sourceAmount;
        }

        if (externalId) {
          input.externalId = externalId;
        }

        const quoteResult = await resolveQuote({ input });
        if (!isPolling) {
          return;
        }

        if (quoteResult.isErr()) {
          if (
            quoteResult.error.isLimitError &&
            onLimitError &&
            typeof onLimitError === "function"
          ) {
            onLimitError(quoteResult.error.code as QuoteLimitErrorCode);
          }

          setIsPolling(false);
          return;
        }

        setExpiration({
          value: new Date(quoteResult.value.expiration).getTime() - Date.now(),
        });
        setIsPolling(true);
        setState((state) => ({ ...state, quote: quoteResult.value }));
      } else {
        const input: AnonQuoteInput = {
          destAsset: inputs?.destinationAsset,
          sourceAsset: inputs?.sourceAsset,
        };

        if (inputs?.destinationAmount) {
          input.destAmount = inputs?.destinationAmount;
        } else if (inputs?.sourceAmount) {
          input.sourceAmount = inputs?.sourceAmount;
        }

        const anonQuoteResult = await resolveAnonQuote({ input });

        // If we are already getting authenticated quotes, drop this response on the floor.
        if (
          authenticatedCashInQuoteRequestInFlight.current === true ||
          !isPolling
        ) {
          return;
        }

        if (anonQuoteResult.isErr()) {
          if (
            anonQuoteResult.error.isLimitError &&
            onLimitError &&
            typeof onLimitError === "function"
          ) {
            onLimitError(anonQuoteResult.error.code as QuoteLimitErrorCode);
          } else {
            toast.error(anonQuoteResult.error.message, {
              onDismiss: state.restart,
              onAutoClose: state.restart,
              id: TOAST_ID,
            });
          }

          setIsPolling(false);
          return;
        }

        setExpiration({
          value:
            new Date(anonQuoteResult.value.expiration).getTime() - Date.now(),
        });
        setIsPolling(true);
        setState((state) => ({
          ...state,
          quote: anonQuoteResult.value,
        }));
      }
    } else {
      if (inputs && inputs.fiatInstrumentId && inputs.walletInstrumentId) {
        if (!inputs.riskSessionKey) {
          Sentry.captureException("Unable to find riskSessionKey");
          toast.error(ErrorMessages.quote.GENERIC_API_ERROR, { id: TOAST_ID });
          return;
        }

        const quoteResult = await resolveCreateCashOutQuote({
          input: {
            destinationAsset: inputs.destinationAsset,
            destinationInstrumentId: inputs.fiatInstrumentId,
            senderInstrumentId: inputs.walletInstrumentId,
            sourceAsset: inputs.sourceAsset,
            sourceAmount: inputs.sourceAmount as AssetAmount,
            riskSessionKey: inputs.riskSessionKey,
          },
        });

        if (!isPolling) return;
        if (!quoteResult.isOk()) {
          if (
            quoteResult.error.isLimitError &&
            onLimitError &&
            typeof onLimitError === "function"
          ) {
            onLimitError();
          } else {
            toast.error(quoteResult.error.message, {
              onDismiss: state.restart,
              onAutoClose: state.restart,
              id: TOAST_ID,
            });
          }
          setIsPolling(false);
          return;
        }

        setExpiration({
          value: new Date(quoteResult.value.expiration).getTime() - Date.now(),
        });
        setIsPolling(true);
        setState((state) => ({
          ...state,
          quote: quoteResult.value,
        }));
      } else {
        const anonQuoteResult = await resolveCreateAnonCashOutQuote({
          input: {
            destinationAsset: inputs?.destinationAsset,
            sourceAsset: inputs?.sourceAsset,
            sourceAmount: inputs?.sourceAmount as AssetAmount,
          },
        });

        if (!isPolling) return;
        if (!anonQuoteResult.isOk()) {
          if (
            anonQuoteResult.error.isLimitError &&
            onLimitError &&
            typeof onLimitError === "function"
          ) {
            onLimitError();
          } else {
            toast.error(anonQuoteResult.error.message, {
              onDismiss: state.restart,
              onAutoClose: state.restart,
              id: TOAST_ID,
            });
          }
          setIsPolling(false);
          return;
        }

        setExpiration({
          value:
            new Date(anonQuoteResult.value.expiration).getTime() - Date.now(),
        });
        setIsPolling(true);
        setState((state) => ({
          ...state,
          quote: anonQuoteResult.value,
        }));
      }
    }
  }, [
    isPolling,
    session,
    transferKind,
    inputs,
    externalId,
    resolveQuote,
    onLimitError,
    state.restart,
    resolveAnonQuote,
    resolveCreateCashOutQuote,
    resolveCreateAnonCashOutQuote,
  ]);

  useTimeout(expiration.value - GRACE_MS, fetchQuote);

  useEffect(() => {
    if (shouldSuspendPolling) {
      state.stop();
    } else {
      state.restart();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldSuspendPolling, state.restart, state.stop]);

  return state;
};
