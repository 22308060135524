import { ChangeEventHandler, useCallback, useEffect, useState } from "react";
import { firstAndLastNameSchema } from "@tigris/common";
import { twMerge } from "tailwind-merge";

type FieldName = "firstName" | "lastName";
export type FullNameValue = Record<FieldName, string>;

export type FullNameProps = {
  disabled: boolean;
  initialValue?: FullNameValue;
  onChange: (value: Record<FieldName, string>, isValid: boolean) => void;
};

/**
 * A Meso UI component that combines first and last name into a single visual element.
 *
 * This component is not explicitly exported from the `mesokit` package. It should be integrated via the `useFullName` hook.
 */
export const FullName = ({
  disabled,
  initialValue,
  onChange,
}: FullNameProps) => {
  const [fullName, setFullName] = useState<Record<FieldName, string>>({
    firstName: initialValue?.firstName ?? "",
    lastName: initialValue?.lastName ?? "",
  });
  const [isValid, setIsValid] = useState(true);
  const [firstNameIsTouched, setFirstNameIsTouched] = useState(
    initialValue?.firstName ? true : false,
  );
  const [lastNameIsTouched, setLastNameIsTouched] = useState(
    initialValue?.lastName ? true : false,
  );

  const handleChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (event) => {
      const fieldName = event.target.name;

      const newFullName = { ...fullName, [fieldName]: event.target.value };
      const valueIsValid =
        firstAndLastNameSchema.safeParse(newFullName).success;

      const bothTouched = firstNameIsTouched && lastNameIsTouched;

      if (bothTouched && !valueIsValid) {
        setIsValid(false);
      } else if (valueIsValid && !bothTouched) {
        setIsValid(true);
      } else if (!bothTouched) {
        setIsValid(true);
      } else {
        setIsValid(valueIsValid);
      }
      if (fieldName === "firstName") {
        setFirstNameIsTouched(true);
      } else if (fieldName === "lastName") {
        setLastNameIsTouched(true);
      }

      setFullName(newFullName);
    },
    [firstNameIsTouched, fullName, lastNameIsTouched],
  );

  useEffect(() => {
    if (firstNameIsTouched && lastNameIsTouched) {
      onChange(fullName, isValid);
    }
  }, [firstNameIsTouched, fullName, isValid, lastNameIsTouched, onChange]);

  return (
    <>
      <label
        data-testid="FullName:label"
        htmlFor="firstName"
        className={twMerge(
          "label",
          !isValid && "label-invalid",
          disabled && "label-disabled",
        )}
      >
        Full name
      </label>
      <div
        className={twMerge(
          // Layout & geometry
          "flex",
          // Display: Taken from `.input{}` class and modified for this use case
          "h-12 w-full rounded-[16px] text-sm text-neutral-800 transition duration-150 dark:border-transparent dark:bg-neutral-700 dark:text-white dark:ring-offset-neutral-800",
          "divide-x divide-neutral-200 dark:divide-neutral-600",
          disabled && "input-disabled",
          !isValid && "input-invalid",
          "focus-within:dark:ring-primary-light focus-within:ring-primary-light border outline-none transition duration-150 focus-within:ring-2 dark:ring-offset-neutral-800",
        )}
      >
        <input
          type="text"
          name="firstName"
          id="firstName"
          // `outline-none` prevents the browser and tailwind from automatically adding focus styles
          className="w-1/2 bg-transparent px-2 outline-none disabled:bg-transparent"
          disabled={disabled}
          onChange={handleChange}
          placeholder="First Name"
          value={fullName.firstName}
          // https://developer.1password.com/docs/web/compatible-website-design/
          data-1p-ignore
          data-testid="FullName:firstName"
        />
        <input
          type="text"
          name="lastName"
          id="lastName"
          // `outline-none` prevents the browser and tailwind from automatically adding focus styles
          className="w-1/2 bg-transparent px-2 outline-none disabled:bg-transparent"
          disabled={disabled}
          onChange={handleChange}
          placeholder="Last Name"
          value={fullName.lastName}
          // https://developer.1password.com/docs/web/compatible-website-design/
          data-1p-ignore
          data-testid="FullName:lastName"
        />
      </div>
    </>
  );
};
