import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AnimatePresence, motion } from "framer-motion";
import { twMerge } from "tailwind-merge";

const variants = {
  initial: { opacity: 0.2 },
  animate: { opacity: 1 },
  exit: { opacity: 0.2 },
};

export const PasswordValidity = ({
  isValid,
  minPasswordLength,
}: {
  isValid: boolean;
  minPasswordLength: number;
}) => {
  return (
    <div
      className={twMerge(
        "flex items-center gap-1 text-xs transition-colors",
        isValid ? "text-neutral-800 dark:text-neutral-100" : "text-neutral-400",
      )}
    >
      <AnimatePresence mode="popLayout">
        {isValid ? (
          <motion.div key="valid-icon" variants={variants}>
            <FontAwesomeIcon
              icon={icon({ name: "circle-check", style: "solid" })}
              className="text-blue-500 dark:text-blue-400"
            />
          </motion.div>
        ) : (
          <motion.div key="invalid-icon" variants={variants}>
            <FontAwesomeIcon
              icon={icon({ name: "circle", style: "regular" })}
              className="opacity-40 dark:text-white"
            />
          </motion.div>
        )}
      </AnimatePresence>
      {minPasswordLength} characters
    </div>
  );
};
