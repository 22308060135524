import { err, ok, Result } from "neverthrow";
import SmartySDK from "smartystreets-javascript-sdk";

const credentials = new SmartySDK.core.SharedCredentials(
  (import.meta.env.VITE_SMARTY_AUTH_ID as string) || "",
);
const client = SmartySDK.core.buildClient.usAutocompletePro(credentials);

/** Fetch suggestions via the Smarty SDK fro US-based addresses. */
export async function usLookup(
  search: string,
  selected = "",
): Promise<Result<SmartySDK.usAutocompletePro.Suggestion[], string>> {
  // skip lookup if search string is empty
  if (search == "") return ok([]);

  // set Smarty lookup params
  const lookup = new SmartySDK.usAutocompletePro.Lookup(
    search.substring(0, 127),
  );

  if (selected != "") lookup.selected = selected.substring(0, 127);
  lookup.maxResults = 6;

  // send lookup req
  try {
    const lookupRes = await client.send(lookup);
    return ok(lookupRes.result);
  } catch {
    return err("Invalid Response");
  }
}
