import { MAX_PASSWORD_LENGTH } from "@tigris/common";

const genericErrorMessage =
  "Something went wrong. Please try again or contact support.";

export const ErrorMessages = {
  addPaymentCard: {
    CHECKOUT_TOKENIZATION_FAILED:
      "Unable to tokenize debit card. Please contact support.",
    ADD_PAYMENT_CARD_API_ERROR:
      "Unable to add card. Please check the details are correct and try again.",
    DEBIT_CARDS_ONLY_ERROR:
      "Meso only supports debit cards. Please use a different card.",
    CONSUMER_CARDS_ONLY_ERROR:
      "Business cards are not supported. Please try again with a personal debit card.",
    UNSUPPORTED_CARD_SCHEME_ERROR:
      "Meso only supports Visa and Mastercard debit cards. Please use a different card.",
    UNSUPPORTED_ISSUING_COUNTRY_API_ERROR:
      "Please use a different card. Meso only supports debit cards issued in the U.S.",
    PROHIBITED_US_AND_TERRITORY_CODE:
      "Residents of NY or LA are not eligible to use Meso at this time.",
    PAYOUT_INELIGIBLE_PAYMENT_CARD_ERROR:
      "Added card does not support payouts. Please use a different card.",
    GENERIC_ERROR: genericErrorMessage,
    USER_BLOCKED_ERROR:
      "Unable to add your debit card. Please contact support.",
  },
  paymentMethod: {
    SKIP_FIAT_INSTRUMENT_ERROR:
      "Unable to select payment method. Please try again.",
    REMOVE_FIAT_INSTRUMENT_ERROR: "Unable to remove card. Please try again.",
  },
  address: {
    UNABLE_TO_PARSE_ADDRESS_ERROR: "Unable to parse address.",
    PO_BOX_NOT_ALLOWED_ERROR:
      "PO boxes are not allowed. Please use a different address.",
  },
  GENERIC_ERROR: genericErrorMessage,
  landingSheet: {
    LOGIN_FAILED: "Login failed. Please try again.",
    EMAIL_PASSWORD_LOGIN_FAILED:
      "Login failed. Please check your email and password.",
    GENERATE_SIGNING_MESSAGE_ERROR: "Unable to generate signing message.",
    WALLET_SIGNING_GENERIC_ERROR: "Unable to verify address.",
  },
  transferSheet: {
    EXECUTE_TRANSFER_API_ERROR: "Unable to execute transfer. Please try again.",
    QUOTE_ALREADY_EXECUTED_API_ERROR: "Quote already used to execute transfer.",
    QUOTE_EXPIRED_API_ERROR: "Quote expired. Please try again.",
  },
  transferStatus: {
    TRANSFER_STATUS_API_ERROR: "Failed to retrieve transfer status.",
    TRANSFER_STATUS_CLIENT_ERROR: "Unable to retrieve transfer status.",
    TRANSFER_DECLINED_ERROR: "Your transfer was declined.",
    TRANSFER_DECLINED_BANK_DECLINE_ERROR:
      "Payment declined by your bank. Please contact them to resolve.",
  },
  threeDomainSecure: {
    NOT_INITIALIZED: "3D Secure Authentication is not initialized.",
    GENERIC_CLIENT_ERROR: "Unable to authenticate 3D Secure payment.",
    CREATE_3DS_SECURE_SESSION_CLIENT_ERROR:
      "Unable to create 3D Secure session.",
    SET_3DS_SESSION_METHOD_API_ERROR:
      "Unable to set 3D Secure session method completion.",
    SET_3DS_SESSION_METHOD_CLIENT_ERROR:
      "Unable to complete 3D Secure session.",
  },
  quote: {
    GENERIC_API_ERROR: "Unable to resolve quote at this time.",
    minLimitError: (min: number | string) =>
      `The amount you entered is below our minimum of $${min}. Please enter $${min} or more.`,
    maxLimitError: (max: number | string) => {
      if (max === 0 || max === "0") {
        return `The amount you entered exceeds your limit of $${max}.`;
      }

      return `The amount you entered exceeds your limit of $${max}. Please enter a lower amount.`;
    },
    REQUESTED_AMOUNT_EXCEEDS_LIMIT:
      "The amount you have requested exceeds your current 30-day buy-limit.",
    NETWORK_FEE_EXCEEDS_TOTAL:
      "Current Network Fees for this token exceed the total value requested.",
  },
  cashOutQuote: {
    GENERIC_API_ERROR: "Unable to resolve quote at this time.",
    PAYOUT_INELIGIBLE_PAYMENT_CARD_ERROR:
      "Your card does not support payouts. Please add a different card.",
  },
  twoFactorAuth: {
    GENERIC_VERIFICATION_ERROR: "Unable to verify phone. Please try again.",
    AUTH_FLOW_ERROR: "Unable to proceed. Please restart and try again.",
    DUPLICATE_PHONE_NUMBER_ERROR: "Phone number already in use.",
  },
  password: {
    TOO_BIG: `The password you've entered is too long. Your password must be less than ${MAX_PASSWORD_LENGTH} characters.`,
  },
  userActivation: {
    GENERIC_API_ERROR:
      "Unable to fetch activation status. Please contact support.",
  },
  auth: {
    EXPIRED_REAUTHENTICATE: "Authentication expired.",
  },
  headlessNavigator: {
    GENERIC_ERROR: "Headless wallet verification failed. Please try again.",
  },
  createAccount: {
    GENERIC_ERROR: "Unable to create account.",
  },
  onboardingSteps: {
    UNABLE_TO_DETERMINE_NEXT_STEP:
      "Unable to complete onboarding. Please contact support.",
    USER_OBJECT_REQUIRED: "A valid User object is required at this step.",
  },
  startOnboarding: {
    UNABLE_TO_START_ONBOARDING:
      "Something went wrong. Please try again or contact support.",
  },
  addWallet: {
    UNABLE_TO_ADD_WALLET:
      "Unable to add wallet. Please try again or contact support.",
  },
  initialization: {
    UNABLE_TO_GET_WEBVIEW_HANDLE_ERROR_IOS:
      "Unable to find webView handle with `window.webkit.messageHandlers.meso`.",
    UNABLE_TO_GET_WEBVIEW_HANDLE_ERROR_RN:
      "Unable to find webView handle with `window.ReactNativeWebView`.",
    UNABLE_TO_GET_WEBVIEW_HANDLE_ERROR: "Unable to find webView handle.",
    MESSAGE_BUS_INITIALIZATION_ERROR: "Unable to initialize Meso.",
  },
  partner: {
    UNABLE_TO_RESOLVE_PARTNER_ERROR: "Unable to resolve partner details.",
    TERMINAL_ERROR: "Failed to resolve partner from API.",
  },
  newSession: {
    GENERIC_API_ERROR: "Unable to create new session.",
  },
  passkey: {
    UNABLE_TO_BEGIN_PASSKEY_REGISTRATION:
      "Unable to begin passkey registration. Please try again.",
    UNABLE_TO_BEGIN_PASSKEY_LOGIN:
      "Unable to begin passkey login. Please try again.",
    PASSKEY_REGISTRATION_FAILED:
      "Passkey registration failed. Please try again.",
    PASSKEY_LOGIN_FAILED:
      "Passkey login failed. Please try again or login with email and password.",
  },
  identityDocument: {
    UNABLE_TO_RETRIEVE_DOCUMENT_UPLOAD_URL:
      "Unable to retrieve document upload URL.",
  },
  common: {
    LOOKUP_USER_API_ERROR: "Unable to fetch user data. Please contact support.",
  },
  agreements: {
    ACCEPT_TERMS_API_ERROR:
      "Unable to accept terms of service. Please try again.",
    DUPLICATE_WALLET_ERROR:
      "Wallet already associated with a Meso account. Please log in instead.",
  },
  basicInfoEntry: {
    GENERIC_API_ERROR: genericErrorMessage,
    DUPLICATE_EMAIL_ERROR:
      "Email address already in use. Please contact support.",
    PROHIBITED_US_AND_TERRITORY_CODE:
      "Residents of NY or LA are not eligible to use Meso at this time.",
  },
  summary: {
    GENERIC_API_ERROR: "Unable to set theme. Please try again.",
  },
  taxpayerId: {
    GENERIC_API_ERROR: genericErrorMessage,
    INVALID_ERROR:
      "Invalid Social Security Number or ITIN. Please check the number and try again.",
  },
  activateUser: {
    GENERIC_API_ERROR: "Unable to activate user. Please try again.",
  },
  phoneEntry: {
    GENERIC_API_ERROR: "Unable to add phone number. Please try again.",
  },
  checkoutContext: {
    NOT_INITIALIZED: "Risk.js is not initialized.",
    FAILED_TO_RETRIEVE_DEVICE_SESSION:
      "Failed to retrieve Risk.js deviceSessionId.",
  },
  openWindow: { GENERIC_ERROR: genericErrorMessage },
  onboardingWindow: {
    GENERIC_ERROR: "Something went wrong. You may now close this window.",
  },
  applePay: {
    GENERIC_API_ERROR:
      "Unable to authorize Apple Pay payment. Please try again.",
  },
  addResidenceCountry: { GENERIC_ERROR: genericErrorMessage },
  addResidentialAddress: { GENERIC_ERROR: genericErrorMessage },
  addPesel: { GENERIC_ERROR: genericErrorMessage },
  addCitizenship: {
    GENERIC_ERROR:
      "Unable to add citizenship. Invalid citizenship country provided.",
  },
  smartyLookup: {
    GENERIC_ERROR:
      "Unable to find a match. Please enter your address manually.",
    GENERIC_API_ERROR: "Unable to fetch address results.",
  },
  user: {
    UNABLE_TO_DETERMINE_RESIDENCE_COUNTRY:
      "Unable to determine residence country for user.",
  },
};
