import { FiatInstrument, SingleUseInstrument } from "../generated/schema";

export const getFiatDisplayValue = (
  fiatInstrument?: FiatInstrument | SingleUseInstrument,
): string | undefined => {
  if (
    fiatInstrument !== null &&
    typeof fiatInstrument === "object" &&
    fiatInstrument.__typename === "FiatInstrument"
  ) {
    if (fiatInstrument.kind === "PAYMENT_CARD") {
      return fiatInstrument.maskedNumber;
    } else if (fiatInstrument.kind === "APPLE_PAY") {
      return "APPLE_PAY";
    }
  } else if (typeof fiatInstrument === "string") {
    return fiatInstrument;
  }
  return;
};

export const getFiatType = (
  fiatInstrument?: FiatInstrument | SingleUseInstrument,
): string | undefined => {
  if (
    fiatInstrument !== null &&
    typeof fiatInstrument === "object" &&
    fiatInstrument.__typename === "FiatInstrument"
  ) {
    if (fiatInstrument.kind === "PAYMENT_CARD") {
      return fiatInstrument.cardScheme;
    } else if (fiatInstrument.kind === "APPLE_PAY") {
      return "APPLE_PAY";
    }
  } else if (typeof fiatInstrument === "string") {
    return fiatInstrument;
  }
  return;
};
