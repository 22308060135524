import { ErrorMessages } from "@tigris/mesokit";
import { useCallback, useContext } from "react";
import { err, ok, Result } from "neverthrow";
import { AppContext } from "../contexts/AppContext";
import {
  FinancialPartner,
  ThreeDomainSecureInput,
  ThreeDomainSecureSession,
} from "@src/generated/sdk";
import { api } from "@src/api";
import {
  ThreeDSEventKind,
  ThreeDSPayload,
  ThreeDSResponse,
} from "@tigris/threeds";
import { AppContextValue, AssetAmount, CountryCodeAlpha2 } from "@src/types";
import { Sentry } from "@tigris/common";

const CHECKOUT_THREE_DOMAIN_SECURE_METHOD_NOTIFICATION_PRODUCTION_URL =
  "https://api.checkout.com/sessions-interceptor/3ds-method-notification";
const CHECKOUT_THREE_DOMAIN_SECURE_METHOD_NOTIFICATION_SANDBOX_URL =
  "https://api.sandbox.checkout.com/sessions-interceptor/3ds-method-notification";

const CHECKOUT_VALID_CHALLENGE_TRANS_STATUSES = ["Y", "N", "U", "A", "R"];
enum ThreeDomainSecureSessionNextAction {
  ISSUER_FINGERPRINT = "issuer_fingerprint",
  CHALLENGE_CARDHOLDER = "challenge_cardholder",
}

const completeIssuerFingerprint = async ({
  partnerId,
  session,
  resolveSetThreeDomainSecureSessionMethodCompletion,
}: {
  partnerId?: string;
  session: ThreeDomainSecureSession;
  resolveSetThreeDomainSecureSessionMethodCompletion: ReturnType<
    typeof api
  >["resolveSetThreeDomainSecureSessionMethodCompletion"];
}) => {
  return await new Promise<Result<ThreeDomainSecureSession, string>>(
    (resolve) => {
      const setMethodCompletion = async (
        session: ThreeDomainSecureSession,
        timeoutId: NodeJS.Timeout,
      ) => {
        clearTimeout(timeoutId);
        window.removeEventListener("message", methodCompletionCallback, false);
        iframe.remove();
        resolve(
          await resolveSetThreeDomainSecureSessionMethodCompletion({
            input: { id: session.id, methodCompletion: "Y" },
          }),
        );
      };

      const timeoutId = setTimeout(async () => {
        window.removeEventListener("message", methodCompletionCallback, false);
        window.removeEventListener("message", iframeReadyCallback, false);
        iframe.remove();
        resolve(
          await resolveSetThreeDomainSecureSessionMethodCompletion({
            input: { id: session.id, methodCompletion: "N" },
          }),
        );
      }, 10 * 1000); // 10 seconds

      const methodCompletionCallback = (
        event: MessageEvent<{
          kind: ThreeDSEventKind;
          payload: ThreeDSResponse;
        }>,
      ) => {
        if (
          event.origin !== import.meta.env.VITE_3DS_APP_ORIGIN ||
          event.data.kind !== ThreeDSEventKind.THREE_DS_RESPONSE ||
          event.data.payload.threeDSServerTransID !== session.transactionId
        ) {
          return;
        }
        setMethodCompletion(session, timeoutId);
      };
      window.addEventListener("message", methodCompletionCallback, false);

      const iframeReadyCallback = (
        event: MessageEvent<{
          kind: ThreeDSEventKind;
          payload: ThreeDSResponse;
        }>,
      ) => {
        if (
          event.origin != import.meta.env.VITE_3DS_APP_ORIGIN ||
          event.data.kind != ThreeDSEventKind.THREE_DS_READY
        ) {
          return;
        }

        window.removeEventListener("message", iframeReadyCallback, false);
        const message = {
          kind: ThreeDSEventKind.THREE_DS_PAYLOAD,
          payload: {
            url: session.threeDSMethodUrl!,
            fieldName: "threeDSMethodData",
            fieldValue: btoa(
              JSON.stringify({
                threeDSServerTransID: session.transactionId,
                threeDSMethodNotificationURL:
                  import.meta.env.VITE_TIGRIS_ENV === "prod"
                    ? CHECKOUT_THREE_DOMAIN_SECURE_METHOD_NOTIFICATION_PRODUCTION_URL
                    : CHECKOUT_THREE_DOMAIN_SECURE_METHOD_NOTIFICATION_SANDBOX_URL,
              }),
            ),
          } as ThreeDSPayload,
        };

        iframe.contentWindow?.postMessage(
          message,
          import.meta.env.VITE_3DS_APP_ORIGIN,
        );
      };
      window.addEventListener("message", iframeReadyCallback);

      const iframe = document.createElement("iframe");
      iframe.setAttribute("referrerPolicy", "origin");
      iframe.name = "threeDSMethodCompletionIframe";
      iframe.style.display = "none";

      const url = new URL("/3ds", import.meta.env.VITE_3DS_APP_ORIGIN);
      url.searchParams.append("sessionId", session.id);
      partnerId && url.searchParams.append("partnerId", partnerId);
      iframe.src = url.toString();

      document.body.appendChild(iframe);
    },
  );
};

const issueCardholderChallenge = async ({
  partnerId,
  session,
}: {
  partnerId?: string;
  session: ThreeDomainSecureSession;
}) => {
  return await new Promise<Result<string, string>>((resolve) => {
    const handleChallengeCompletion = (
      transStatus: string,
      timeoutId: NodeJS.Timeout,
    ) => {
      clearTimeout(timeoutId);
      window.removeEventListener("message", challengeCallback, false);
      iframe.remove();

      resolve(
        CHECKOUT_VALID_CHALLENGE_TRANS_STATUSES.includes(transStatus)
          ? ok(transStatus)
          : err(ErrorMessages.threeDomainSecure.GENERIC_CLIENT_ERROR),
      );
    };
    const timeoutId = setTimeout(
      () => {
        window.removeEventListener("message", challengeCallback, false);
        window.removeEventListener("message", iframeReadyCallback, false);
        iframe.remove();
        resolve(err(ErrorMessages.threeDomainSecure.GENERIC_CLIENT_ERROR));
      },
      60 * 1000 * 15, // 15 minutes
    );

    const challengeCallback = (
      event: MessageEvent<{
        kind: ThreeDSEventKind;
        payload: ThreeDSResponse;
      }>,
    ) => {
      if (
        event.origin !== import.meta.env.VITE_3DS_APP_ORIGIN ||
        event.data.kind !== ThreeDSEventKind.THREE_DS_RESPONSE ||
        event.data.payload.threeDSServerTransID !== session.transactionId
      ) {
        return;
      }
      handleChallengeCompletion(
        event.data.payload.transStatus || "U",
        timeoutId,
      );
    };
    window.addEventListener("message", challengeCallback, false);

    const iframeReadyCallback = (
      event: MessageEvent<{ kind: ThreeDSEventKind }>,
    ) => {
      if (
        event.origin != import.meta.env.VITE_3DS_APP_ORIGIN ||
        event.data.kind != ThreeDSEventKind.THREE_DS_READY
      ) {
        return;
      }

      window.removeEventListener("message", iframeReadyCallback, false);
      const challengeRequest = {
        threeDSServerTransID: session.transactionId,
        acsTransID: session.acsTransactionId,
        messageType: "CReq",
        messageVersion: session.protocolVersion,
        challengeWindowSize: "05",
      };
      const message = {
        kind: ThreeDSEventKind.THREE_DS_PAYLOAD,
        payload: {
          url: session.acsUrl!,
          fieldName: "creq",
          fieldValue: btoa(JSON.stringify(challengeRequest)).replace(/=/g, ""),
        } as ThreeDSPayload,
      };

      iframe.contentWindow?.postMessage(
        message,
        import.meta.env.VITE_3DS_APP_ORIGIN,
      );
    };
    window.addEventListener("message", iframeReadyCallback);

    const iframe = document.createElement("iframe");
    iframe.name = "threeDSChallengeIframe";
    iframe.setAttribute("referrerPolicy", "origin");
    iframe.style.margin = "0";
    iframe.style.padding = "0";
    iframe.style.position = "fixed";
    iframe.style.height = "100%";
    iframe.style.width = "100%";
    iframe.style.top = "0";
    iframe.style.left = "0";

    const url = new URL("/3ds", import.meta.env.VITE_3DS_APP_ORIGIN);
    url.searchParams.append("sessionId", session.id);
    partnerId && url.searchParams.append("partnerId", partnerId);
    iframe.src = url.toString();

    document.body.appendChild(iframe);
  });
};

const getFinancialPartner = (
  user: AppContextValue["user"],
): Result<FinancialPartner, string> => {
  if (!user.residenceCountry) {
    return err(ErrorMessages.user.UNABLE_TO_DETERMINE_RESIDENCE_COUNTRY);
  }

  return user.residenceCountry === CountryCodeAlpha2.US
    ? ok(FinancialPartner.ZERO_HASH)
    : ok(FinancialPartner.MESO_SPZOO);
};

export const useThreeDS = () => {
  const {
    api: {
      resolveCreateThreeDomainSecureSession,
      resolveSetThreeDomainSecureSessionMethodCompletion,
    },
    partner,
    configuration: { sourceCAIPAsset },
    fiatInstrument,
    user,
  } = useContext(AppContext);

  const authenticateThreeDomainSecurePayment = useCallback(
    async ({
      quoteSourceAmount,
    }: {
      /** The fiat amount for the current quote to run 3DS against. */
      quoteSourceAmount: AssetAmount;
    }): Promise<
      Result<
        ThreeDomainSecureInput | { dataShareNotSupported: true } | undefined,
        string
      >
    > => {
      if (
        !fiatInstrument ||
        typeof fiatInstrument !== "object" ||
        fiatInstrument.__typename !== "FiatInstrument" ||
        !fiatInstrument.requires3ds
      ) {
        return ok(undefined);
      }

      const financialPartnerResult = getFinancialPartner(user);

      if (financialPartnerResult.isErr()) {
        Sentry.captureMessage(financialPartnerResult.error, {
          level: "error",
          extra: {
            userResidenceCountryCode: user.residenceCountry ?? "UNKNOWN",
          },
        });
        return err("....");
      }

      const createSessionResult = await resolveCreateThreeDomainSecureSession({
        input: {
          amount: quoteSourceAmount,
          asset: sourceCAIPAsset,
          colorDepth: screen.colorDepth.toString(),
          financialInstrumentId: fiatInstrument.id,
          javaEnabled: navigator.javaEnabled(),
          language: navigator.language,
          screenHeight: screen.height.toString(),
          screenWidth: screen.width.toString(),
          timezoneOffset: new Date().getTimezoneOffset().toString(),
          financialPartner: financialPartnerResult.value,
        },
      });

      if (createSessionResult.isErr()) {
        return err(createSessionResult.error);
      }

      var session = createSessionResult.value;

      if ("dataShareNotSupported" in session) {
        return ok({ dataShareNotSupported: true });
      }
      if (
        session.threeDSMethodUrl &&
        session.nextActions.includes(
          ThreeDomainSecureSessionNextAction.ISSUER_FINGERPRINT,
        )
      ) {
        const fingerPrintResult = await completeIssuerFingerprint({
          ...(partner?.id ? { partnerId: partner.id } : {}),
          session,
          resolveSetThreeDomainSecureSessionMethodCompletion,
        });
        if (fingerPrintResult.isErr()) {
          return err(fingerPrintResult.error);
        }
        session = fingerPrintResult.value;
      }

      if (
        session.acsUrl &&
        session.nextActions.includes(
          ThreeDomainSecureSessionNextAction.CHALLENGE_CARDHOLDER,
        )
      ) {
        const challengeResult = await issueCardholderChallenge({
          ...(partner?.id ? { partnerId: partner.id } : {}),
          session,
        });
        if (challengeResult.isErr()) {
          return err(challengeResult.error);
        }
        return ok({
          sessionID: session.id,
          transStatus: challengeResult.value,
        });
      }

      return ok({ sessionID: session.id });
    },
    [
      fiatInstrument,
      partner?.id,
      resolveCreateThreeDomainSecureSession,
      resolveSetThreeDomainSecureSessionMethodCompletion,
      sourceCAIPAsset,
      user,
    ],
  );

  return { authenticateThreeDomainSecurePayment };
};
