import { Api } from "@src/types";
import * as Factory from "factory.ts";

const noop = () => {};

export const apiFactory = Factory.Sync.makeFactory<
  Record<keyof Api, typeof noop>
>({
  resolveActivateUser: noop,
  resolveAddPaymentCard: noop,
  resolveAddWallet: noop,
  resolveAnonQuote: noop,
  resolveBeginPasskeyLogin: noop,
  resolveBeginPasskeyRegistration: noop,
  resolveCheckTransfer: noop,
  resolveCompletePasskeyLogin: noop,
  resolveCompletePasskeyRegistration: noop,
  resolveCreateAnonCashOutQuote: noop,
  resolveCreateCashOutQuote: noop,
  resolveCreateThreeDomainSecureSession: noop,
  resolveExecuteTransfer: noop,
  resolveLoginMessage: noop,
  resolveLoginWithEmailAndPassword: noop,
  resolveLoginWithWallet: noop,
  resolveNewSession: noop,
  resolvePartnerDetails: noop,
  resolveQuote: noop,
  resolveRemoveFiatInstrument: noop,
  resolveRequestApplePayPaymentSession: noop,
  resolveRiskSession: noop,
  resolveSend2FACode: noop,
  resolveSetThreeDomainSecureSessionMethodCompletion: noop,
  resolveSubmitUserFeedback: noop,
  resolveUser: noop,
  resolveUserActivationStatus: noop,
  resolveVerify2FA: noop,
});
