import { AnimatePresence, Variants, motion } from "framer-motion";

const animationVariants: Variants = {
  initial: { opacity: 0, y: 4 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: 4 },
};

const strengths = [
  { emoji: "💩💩", text: "Very weak" },
  { emoji: "💩", text: "Weak" },
  { emoji: "😬", text: "Ok" },
  { emoji: "💪", text: "Strong" },
  { emoji: "😎", text: "Very strong" },
];

export const PasswordStrength = ({
  strength,
  validPasswordLength,
}: {
  strength: number;
  validPasswordLength: boolean;
}) => {
  return (
    <AnimatePresence>
      {validPasswordLength ? (
        <motion.div
          {...animationVariants}
          className="flex items-center text-xs text-neutral-800 dark:text-neutral-300"
        >
          <div className="flex gap-1">
            <div>{strengths[strength].emoji}</div>
            <div>{strengths[strength].text}</div>
          </div>
        </motion.div>
      ) : null}
    </AnimatePresence>
  );
};
