import { InstrumentKind, SingleUseInstrument } from "@src/generated/sdk";

/**
 * Maps InstrumentKind to SingleUseInstrument type; used to translate
 * supportedPaymentMethods to the fiatInstrument of user
 *
 * @param instrumentKind InstrumentKind or undefined
 * @returns SingleUseInstrument corresponding to InstrumentKind or undefined
 */
export const instrumentKindToSingleUseInstrument = (
  instrumentKind?: InstrumentKind,
) => {
  if (!instrumentKind) return;

  switch (instrumentKind) {
    case InstrumentKind.APPLE_PAY:
      return SingleUseInstrument.APPLE_PAY;
  }
};
