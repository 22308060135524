import { PropsWithChildren } from "react";
import { twMerge } from "tailwind-merge";

type TextProps = {
  className?: string;
  "data-testid"?: string;
};

export const Text = ({
  children,
  "data-testid": dataTestId,
  className = "",
}: PropsWithChildren<TextProps>) => {
  const classes = twMerge(
    "text-sm m-0 text-neutral-800 dark:text-white leading-tight",
    className,
  );

  return (
    <div data-testid={dataTestId} className={classes}>
      {children}
    </div>
  );
};
