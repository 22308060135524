import { AnchorHTMLAttributes, ButtonHTMLAttributes } from "react";
import { AnimatedButton } from "./AnimatedButton";
import { StaticButton } from "./StaticButton";

type CommonButtonProps = {
  /**
   * Whether the button renders with the `primary` styling.
   *
   * Defaults to `true`.
   */
  primary?: boolean;
  /**
   * Whether to render the loading state or not.
   *
   * Defaults to `false`.
   */
  isLoading?: boolean;
  /**
   * Theme override className
   */
  themeOverride?: string;
};

type AsButtonProps = CommonButtonProps &
  ButtonHTMLAttributes<HTMLButtonElement> & {
    as?: "button";
  };

type AsAnchorTagProps = CommonButtonProps &
  Omit<AnchorHTMLAttributes<HTMLAnchorElement>, "onClick" | "type"> & {
    as: "a";
    disabled?: boolean;
    href: Required<AnchorHTMLAttributes<HTMLAnchorElement>>["href"];
  };

export type AnimatedButtonProps = (AsButtonProps | AsAnchorTagProps) & {
  /** Styles to apply to the button's Framer Motion container */
  containerClassName?: string;
  static: false;
};
export type StaticButtonProps = (AsButtonProps | AsAnchorTagProps) & {
  static?: true;
};

/**
 * A Meso UI button.
 *
 * This button can be animated or rendered as `static`.
 */
export const Button = (props: AnimatedButtonProps | StaticButtonProps) => {
  const { static: _static, ...rest } = props;

  if (_static) {
    return <StaticButton {...rest} />;
  } else {
    return <AnimatedButton {...rest} static={false} />;
  }
};
