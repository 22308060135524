import { useEffect, useState } from "react";

export type USAgreementsProps = {
  onValidationChange: (isValid: boolean) => void;
  termsUrl: string;
  privacyPolicyUrl: string;
};

const ZH_PRIVACY_POLICY_URL = "https://docs.zerohash.com/page/privacy-policy";
const ZH_REGULATORY_DISCLOSURES_URL =
  "https://docs.zerohash.com/page/us-licenses-and-disclosures";

const checkboxClasses =
  "focus:ring-primary dark:focus:ring-primary-light checked:bg-primary dark:checked:bg-primary-light peer h-6 w-6 md:w-4 md:h-4 appearance-none rounded-md border-gray-300 bg-neutral-200 focus:ring-2 ring-offset-1 dark:border-gray-600 dark:bg-neutral-600 dark:ring-offset-neutral-800";
const checkmarkClasses =
  "pointer-events-none absolute left-1.5 top-1.5 md:left-1 h-3 w-3 md:top-1 md:h-2 md:w-2 scale-75 fill-white opacity-0 transition peer-checked:scale-100 peer-checked:opacity-100";

export const USAgreements = ({
  onValidationChange,
  termsUrl,
  privacyPolicyUrl,
}: USAgreementsProps) => {
  const [mesoAgreementsAccepted, setMesoAgreementsAccepted] = useState(false);
  const [zeroHashAgreementsAccepted, setZeroHashAgreementsAccepted] =
    useState(false);

  useEffect(() => {
    onValidationChange(mesoAgreementsAccepted && zeroHashAgreementsAccepted);
  }, [mesoAgreementsAccepted, onValidationChange, zeroHashAgreementsAccepted]);

  return (
    <div
      className="dark:border-ts-subtle mb-2 mt-4 overflow-hidden rounded-xl border border-neutral-700/10 p-3"
      data-testid="US:Agreements"
    >
      <div className="mb-2 flex items-start gap-2 text-[0.625rem] leading-tight">
        <div className="relative">
          <input
            type="checkbox"
            name="mesoAgreements"
            data-testid="mesoAgreements"
            id="mesoAgreements"
            className={checkboxClasses}
            checked={mesoAgreementsAccepted}
            onChange={() => {
              setMesoAgreementsAccepted(!mesoAgreementsAccepted);
            }}
          />
          <svg
            width="11"
            height="9"
            viewBox="0 0 11 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={checkmarkClasses}
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M10.0812 0.186222C10.5307 0.507231 10.6347 1.13178 10.3137 1.58119L5.31373 8.58119C5.14288 8.82039 4.87543 8.9723 4.58248 8.99655C4.28953 9.02079 4.00075 8.91492 3.79289 8.70706L0.292893 5.20706C-0.0976311 4.81654 -0.0976311 4.18337 0.292893 3.79285C0.683417 3.40232 1.31658 3.40232 1.70711 3.79285L4.3724 6.45814L8.68627 0.418717C9.00728 -0.0306951 9.63183 -0.134787 10.0812 0.186222Z"
            />
          </svg>
        </div>
        <label htmlFor="mesoAgreements" className="opacity-50">
          I agree to the{" "}
          <a
            href={termsUrl}
            target="_blank"
            rel="noreferrer"
            className="font-bold no-underline hover:opacity-50"
          >
            Meso User Agreement
          </a>{" "}
          and have read and understand the{" "}
          <a
            href={privacyPolicyUrl}
            target="_blank"
            rel="noreferrer"
            className="font-bold no-underline hover:opacity-50"
          >
            Meso Privacy Policy
          </a>{" "}
          and <span className="font-bold">I am a US citizen</span>.
        </label>
      </div>
      <div className="flex items-start gap-2 text-[0.625rem] leading-tight">
        <div className="relative">
          <input
            type="checkbox"
            name="zeroHashAgreements"
            data-testid="zeroHashAgreements"
            id="zeroHashAgreements"
            className={checkboxClasses}
            checked={zeroHashAgreementsAccepted}
            onChange={() => {
              setZeroHashAgreementsAccepted(!zeroHashAgreementsAccepted);
            }}
          />

          <svg
            width="11"
            height="9"
            viewBox="0 0 11 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={checkmarkClasses}
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M10.0812 0.186222C10.5307 0.507231 10.6347 1.13178 10.3137 1.58119L5.31373 8.58119C5.14288 8.82039 4.87543 8.9723 4.58248 8.99655C4.28953 9.02079 4.00075 8.91492 3.79289 8.70706L0.292893 5.20706C-0.0976311 4.81654 -0.0976311 4.18337 0.292893 3.79285C0.683417 3.40232 1.31658 3.40232 1.70711 3.79285L4.3724 6.45814L8.68627 0.418717C9.00728 -0.0306951 9.63183 -0.134787 10.0812 0.186222Z"
            />
          </svg>
        </div>
        <label htmlFor="zeroHashAgreements" className="opacity-50">
          <p className="mb-1 leading-3">
            I agree to the Zero Hash and Zero Hash Liquidity Services User
            Agreement, and I have read and understand the Zero Hash{" "}
            <a
              href={ZH_PRIVACY_POLICY_URL}
              target="_blank"
              rel="noreferrer"
              className="font-bold no-underline hover:opacity-50"
            >
              Privacy Policy
            </a>{" "}
            and{" "}
            <a
              href={ZH_REGULATORY_DISCLOSURES_URL}
              target="_blank"
              rel="noreferrer"
              className="font-bold no-underline hover:opacity-50"
            >
              Regulatory Disclosures
            </a>
            .
          </p>
          <p className="leading-3">
            I understand that the value of any cryptocurrency, including digital
            assets pegged to fiat currency, commodities, or any other asset, may
            go to zero.
          </p>
        </label>
      </div>
    </div>
  );
};
