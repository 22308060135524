import * as Factory from "factory.ts";
import {
  CardType,
  FiatInstrumentFragment,
  InstrumentKind,
} from "@src/generated/sdk";

export const fiatInstrumentFactory =
  Factory.Sync.makeFactory<FiatInstrumentFragment>({
    __typename: "FiatInstrument",
    id: "some_id",
    kind: InstrumentKind.PAYMENT_CARD,
    display: "Debit",
    maskedNumber: "2222",
    cardType: CardType.DEBIT,
    cardScheme: "Visa",
    payoutEligible: true,
    requires3ds: false,
    createdAt: new Date(Date.UTC(2023, 10, 11, 0, 0, 0)).toISOString(),
  });
