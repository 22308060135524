import {
  FullProfileStatusFragment,
  ProfileFieldStatus,
} from "@src/generated/mocks";
import { CountryCodeAlpha2 } from "@src/types";
import * as Factory from "factory.ts";

export const profileStatusFactory =
  Factory.Sync.makeFactory<FullProfileStatusFragment>({
    __typename: "ProfileStatus",
    account: false,
    acceptedMesoTerms: false,
    acceptedZeroHashTerms: false,
    phone: false,
    phoneVerified: false,
    financialInstrument: false,
    basicInfo: false,
    residentialAddress: false,
    taxpayerId: false,
    citizenship: false,
    acknowledgedScamWarning: false,
    residenceCountry: null,
    pesel: ProfileFieldStatus.UNKNOWN,
    identityDocuments: false,
  });

export const returningUserProfileStatusFactory = profileStatusFactory.extend({
  account: true,
  acceptedMesoTerms: true,
  acceptedZeroHashTerms: true,
  phone: true,
  phoneVerified: true,
  financialInstrument: true,
  basicInfo: true,
  residentialAddress: true,
  taxpayerId: true,
  citizenship: true,
  acknowledgedScamWarning: true,
  residenceCountry: CountryCodeAlpha2.US,
  pesel: ProfileFieldStatus.PROVIDED,
});
