import { FormEventHandler, useCallback, useMemo, useState } from "react";
import AnimationContainer from "./AnimationContainer";
import Heading from "./Heading";
import {
  Button,
  countries,
  InternationalAddressCapture,
  InternationalAddressCaptureProps,
  nonUSCountries,
  InternationalLookupAddressDetail,
} from "@tigris/mesokit";
import { NonUSCountryCodeAlpha3 } from "../types";
import { useRouter } from "../hooks/useRouter";
import { useApi } from "../hooks/useApi";
import { useOnboarding } from "../hooks/useOnboarding";
import { toast } from "sonner";
import { AddResidentialAddressInput } from "../generated/sdk";
import { getNextOnboardingStep } from "../utils/nextOnboardingStep";

const FORM_ID = "ResidentialAddress";

export const ResidentialAddress = () => {
  const { user, updateUser, configuration } = useOnboarding();
  const { navigate } = useRouter();
  const { api } = useApi();
  const [isLoading, setIsLoading] = useState(false);
  const [address, setAddress] = useState<InternationalLookupAddressDetail>();
  const [isValid, setIsValid] = useState(false);

  const handleSubmit = useCallback<FormEventHandler<HTMLFormElement>>(
    async (event) => {
      event.preventDefault();

      toast.dismiss();

      setIsLoading(true);

      const residentialAddressInput: AddResidentialAddressInput = {
        address1: address!.street,
        // Some countries such as Iceland and Bulgaria do not return locality, but instead use `administrativeArea`
        city: address!.locality ?? address!.administrativeArea,
        countryCode: countries.find((country) => {
          return country.countryCodeAlpha3 === address!.countryIso3;
        })!.countryCodeAlpha2,
        postalCode: address!.postalCode,
        // Some countries such as Cyprus, Latvia, Malta, and Slovenia do not return `administrativeAreaShort`
        region: address!.administrativeAreaShort ?? address!.administrativeArea,
      };

      const addResidentialAddressResult =
        await api.resolveAddResidentialAddress({
          input: residentialAddressInput,
        });

      if (addResidentialAddressResult.isErr()) {
        toast.error(addResidentialAddressResult.error);
        setIsLoading(false);
        return;
      }

      const updatedUser = updateUser({
        residentialAddress: residentialAddressInput,
      });

      const nextStep = getNextOnboardingStep({
        profileStatus: addResidentialAddressResult.value,
        supportedPaymentMethods: configuration.supportedPaymentMethods,
        user: updatedUser,
      });

      if (nextStep.isErr()) {
        setIsLoading(false);
        toast.error(nextStep.error);
        return;
      }

      navigate(nextStep.value);
    },
    [address, api, configuration.supportedPaymentMethods, navigate, updateUser],
  );

  const handleAddressChange = useCallback<
    InternationalAddressCaptureProps["onChange"]
  >(({ address, isValid }) => {
    setAddress(address);
    setIsValid(isValid);
  }, []);

  const initialCountryCodeAlpha3 = useMemo(() => {
    return nonUSCountries.find(
      (nonUSCountry) =>
        nonUSCountry.countryCodeAlpha2 === user.residenceCountry,
    )?.countryCodeAlpha3 as NonUSCountryCodeAlpha3;
  }, [user.residenceCountry]);

  return (
    <AnimationContainer>
      <form
        id={FORM_ID}
        name={FORM_ID}
        data-testid={FORM_ID}
        onSubmit={handleSubmit}
        className="onboarding-inner-content"
      >
        <Heading title="Residential Address" subtitle="Where do you live?" />

        <InternationalAddressCapture
          // Residential address is locked to the value the user declared at the beginning of onboarding.
          allowCountrySelection={false}
          initialCountryCodeAlpha3={initialCountryCodeAlpha3}
          onChange={handleAddressChange}
        />

        <div className="onboarding-footer">
          <Button
            key="ResidentialAddress:button"
            disabled={!isValid || isLoading}
            type="submit"
            isLoading={isLoading}
          >
            Continue
          </Button>
        </div>
      </form>
    </AnimationContainer>
  );
};
