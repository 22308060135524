import {
  InternationalAddressSuggestions,
  InternationalLookupAddressDetail,
} from "./types";
import { err, ok, Result } from "neverthrow";
import { CountryCodeAlpha3, NonUSCountryCodeAlpha3 } from "../../types";
import { ErrorMessages } from "../errorMessages";
import { z } from "zod";
import { MesoKitContextValue } from "../../MesoKitContext";

type InternationalSmartyLookupResult = Result<
  InternationalLookupAddressDetail | InternationalAddressSuggestions,
  string
>;

// Top-level results
const internationalAddressLookupResult = z.object({
  candidates: z.array(
    z.object({
      entries: z.number(),
      address_text: z.string(),
      address_id: z.string(),
    }),
  ),
});

const internationalAddressDetailLookupResult = z.object({
  candidates: z.array(
    z.object({
      administrative_area: z.string(),
      // Certain countries may not return this
      administrative_area_long: z.optional(z.string()),
      // Certain countries (Cyprus, Latvia, Malta, Slovenia) may not return this
      administrative_area_short: z.optional(z.string()),
      country_iso3: z.nativeEnum(CountryCodeAlpha3),
      // Certain countries (Bulgaria, Iceland) may not return this
      locality: z.optional(z.string()),
      postal_code: z.string(),
      street: z.string(),
    }),
  ),
});

// The smarty SDK does not surface administrative_area_long or administrative_area_short, so we call the API directly https://github.com/smarty/smartystreets-javascript-sdk/pull/99
const baseUrl = "https://international-autocomplete.api.smarty.com/v2/lookup/";
export const internationalSmartyLookup = async ({
  search,
  addressId,
  country,
  sentry,
}: {
  search: string;
  addressId?: string;
  country: NonUSCountryCodeAlpha3;
  sentry?: MesoKitContextValue["sentry"];
}): Promise<InternationalSmartyLookupResult> => {
  // const isDetailLookup = addressId != undefined;
  const searchParams = new URLSearchParams({
    search,
    country,
    max_results: "10",
    key: import.meta.env.VITE_SMARTY_AUTH_ID,
  });

  const url = new URL(
    `${baseUrl}${addressId ?? ""}?${searchParams.toString()}`,
  );
  const res = await fetch(url.toString(), {
    headers: {
      "content-type": "application/json; charset=utf-8",
    },
  });

  if (res.status !== 200) {
    let responseBody = "";

    if (res.headers.get("content-type")?.includes("application/json")) {
      responseBody = await res.json();
    }

    sentry?.captureMessage(ErrorMessages.smartyLookup.GENERIC_API_ERROR, {
      extra: {
        status: res.status,
        params: searchParams.toString(),
        responseBody,
      },
    });

    return err(ErrorMessages.smartyLookup.GENERIC_API_ERROR);
  }

  const address = await res.json();
  const candidates = address.candidates || [];

  if (candidates.length === 0) {
    return ok([]);
  }

  // Just because we have an `addressId`, doesn't mean we are making a request for a specific address.
  // All addressId does is further narrow down the search results.
  if ("street" in address.candidates[0]) {
    const parsed = internationalAddressDetailLookupResult.safeParse(address);
    if (parsed.success) {
      const [candidate] = parsed.data.candidates;
      const detail: InternationalLookupAddressDetail = {
        street: candidate.street,
        locality: candidate.locality,
        administrativeArea: candidate.administrative_area,
        administrativeAreaShort: candidate.administrative_area_short,
        administrativeAreaLong: candidate.administrative_area_long,
        postalCode: candidate.postal_code,
        countryIso3: candidate.country_iso3,
      };

      return ok(detail);
    }
  } else {
    const parsed = internationalAddressLookupResult.safeParse(address);
    if (parsed.success) {
      return ok(
        parsed.data.candidates.map(({ address_id, address_text, entries }) => ({
          addressText: address_text,
          addressId: address_id,
          entries,
        })),
      );
    }
  }

  sentry?.captureMessage(ErrorMessages.smartyLookup.GENERIC_API_ERROR, {
    extra: {
      status: res.status,
      params: searchParams.toString(),
      responseBody: address,
    },
  });

  return err(ErrorMessages.smartyLookup.GENERIC_API_ERROR);
};
