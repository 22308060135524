import {
  ChangeEventHandler,
  FormEventHandler,
  useCallback,
  useMemo,
  useState,
} from "react";
import AnimationContainer from "./AnimationContainer";
import Heading from "./Heading";
import {
  Button,
  CountryCombobox,
  CountryComboboxProps,
  ErrorMessages,
  LabelledInput,
  nonUSCountries,
  useFullName,
} from "@tigris/mesokit";
import { CountryCodeAlpha2, Routes } from "../types";
import { useRouter } from "../hooks/useRouter";
import { useOnboarding } from "../hooks/useOnboarding";
import { useApi } from "../hooks/useApi";
import { dateOfBirthSchema } from "../utils/validation";
import { toast } from "sonner";
import {
  formatDateForApi,
  parseDateInput,
  formatDateString,
  formatDateFromApi,
} from "../utils/dates";
import { Sentry } from "@tigris/common";

const FORM_ID = "PersonalInfo";

export const PersonalInfo = () => {
  const { user, updateUser } = useOnboarding();
  const { navigate } = useRouter();
  const { api } = useApi();
  const {
    isValid: fullNameIsValid,
    FullName,
    getFullName,
  } = useFullName({
    firstName: user.firstName ?? "",
    lastName: user.lastName ?? "",
  });
  const [dateOfBirth, setDateOfBirth] = useState(() => {
    if (!user.dateOfBirth) {
      return "";
    }

    const formatStringResult = formatDateFromApi(user.dateOfBirth);

    if (formatStringResult.isErr()) {
      return "";
    }

    return formatStringResult.value;
  });
  const [dateOfBirthIsValid, setDateOfBirthIsValid] = useState(true);
  const [citizenship, setCitizenship] = useState<CountryCodeAlpha2>();
  const [isLoading, setIsLoading] = useState(false);

  const handleDateOfBirthChange = useCallback<
    ChangeEventHandler<HTMLInputElement>
  >(
    (event) => {
      const { name, value } = event.target;
      if (name === "dateOfBirth") {
        setDateOfBirth(
          formatDateString({ previousValue: dateOfBirth, newValue: value }),
        );
        const isValid = !dateOfBirthSchema.safeParse(dateOfBirth).success;

        setDateOfBirthIsValid(isValid);
      }
    },
    [dateOfBirth],
  );

  const handleCitizenshipCountryChange = useCallback<
    Required<CountryComboboxProps>["onSelectCountry"]
  >((country) => {
    setCitizenship(country ?? undefined);
  }, []);

  const handleSubmit = useCallback<FormEventHandler<HTMLFormElement>>(
    async (event) => {
      toast.dismiss();
      event.preventDefault();

      setIsLoading(true);

      const fullName = getFullName();

      // `Date.UTC` ensures we ignore the timezone offset of the client
      const newDateOfBirthResult = parseDateInput(dateOfBirth);

      if (newDateOfBirthResult.isErr()) {
        toast.error(ErrorMessages.GENERIC_ERROR);

        Sentry.captureException("Error parsing date of birth", {
          extra: {
            input: dateOfBirth,
            stored: user.dateOfBirth,
          },
        });

        setIsLoading(false);
        return;
      }

      // The value to pass to our API and store on context.
      const newDateOfBirthTimestamp = formatDateForApi(
        newDateOfBirthResult.value,
      );

      if (
        fullName.firstName !== user.firstName ||
        fullName.lastName !== user.lastName ||
        !user.dateOfBirth ||
        newDateOfBirthResult.value.toISOString() !==
          new Date(user.dateOfBirth).toISOString()
      ) {
        const resolveAddBasicInfoResult = await api.resolveAddBasicInfo({
          input: {
            firstName: fullName.firstName,
            lastName: fullName.lastName,
            dateOfBirth: newDateOfBirthTimestamp,
          },
        });

        if (resolveAddBasicInfoResult.isErr()) {
          toast.error(resolveAddBasicInfoResult.error);
          setIsLoading(false);
          return;
        }
      }

      const addCitizenshipResult = await api.resolveAddCitizenship({
        input: { countryCode: citizenship! },
        errorMessage: ErrorMessages.addCitizenship.GENERIC_ERROR,
      });

      if (addCitizenshipResult.isErr()) {
        toast.error(addCitizenshipResult.error);
        setIsLoading(false);
        return;
      }

      updateUser({
        firstName: fullName.firstName,
        lastName: fullName.lastName,
        dateOfBirth: newDateOfBirthTimestamp,
        citizenship,
      });

      if (citizenship === CountryCodeAlpha2.PL) {
        navigate(Routes.Pesel);
      } else {
        navigate(Routes.ResidentialAddress);
      }
    },
    [
      api,
      citizenship,
      dateOfBirth,
      getFullName,
      navigate,
      updateUser,
      user.dateOfBirth,
      user.firstName,
      user.lastName,
    ],
  );

  const formIsValid = useMemo(() => {
    return fullNameIsValid && dateOfBirthIsValid && citizenship !== undefined;
  }, [citizenship, dateOfBirthIsValid, fullNameIsValid]);

  return (
    <AnimationContainer>
      <form
        id={FORM_ID}
        name={FORM_ID}
        data-testid={FORM_ID}
        onSubmit={handleSubmit}
        className="onboarding-inner-content"
      >
        <Heading
          title="Personal Information"
          subtitle="Please verify your personal information is filled in correctly and accurate."
        />

        <div>
          <FullName disabled={isLoading} />
        </div>

        <LabelledInput
          labelProps={{ text: "Date of Birth" }}
          placeholder="MM/DD/YYYY"
          name="dateOfBirth"
          value={dateOfBirth}
          isValid={dateOfBirthIsValid}
          disabled={isLoading}
          onChange={handleDateOfBirthChange}
          data-testid="PersonalInfo:dateOfBirth"
        />

        <div className="w-full">
          <label
            htmlFor="CountryCombobox_search"
            className="label cursor-pointer"
          >
            Citizenship
          </label>
          <CountryCombobox
            onSelectCountry={handleCitizenshipCountryChange}
            disabled={isLoading}
            placeholder="Choose a country"
            countries={nonUSCountries}
          />
        </div>

        <div className="onboarding-footer">
          <Button
            key="PersonalInfo:button"
            disabled={!formIsValid || isLoading}
            type="submit"
            isLoading={isLoading}
          >
            Continue
          </Button>
        </div>
      </form>
    </AnimationContainer>
  );
};
