import { useRouter } from "../hooks/useRouter";
import { ReactElement, useEffect, useState } from "react";
import { useOnboarding } from "../hooks/useOnboarding";
import Heading from "./Heading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { Posthog, TelemetryEvents } from "@tigris/common";

export const HandoffFailed = () => {
  const { currentRoute } = useRouter();
  const {
    configuration: { partner },
  } = useOnboarding();
  const [content, setContent] = useState<{
    title: string;
    subtile: string;
    icon: ReactElement;
  }>();
  const [reason] = useState(() => {
    const { state } = currentRoute;

    if (state && "reason" in state) {
      return state.reason;
    }

    return "unknown";
  });

  useEffect(() => {
    Posthog.capture(TelemetryEvents.onboardingHandoffFail, { reason });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (reason === "onboardingComplete") {
      setContent({
        title: "Account Created Successfully",
        subtile: `Thanks for creating your Meso account. We are unable to redirect you back${partner?.displayName ? " to " + partner?.displayName : ""} right now. Feel free to close
                this window.`,
        icon: (
          <div className="bg-highlight flex h-[96px] w-[96px] items-center justify-center rounded-full">
            <FontAwesomeIcon
              size="3x"
              icon={icon({ name: "party-horn", style: "light" })}
              className="dark:text-neutral-700"
            />
          </div>
        ),
      });
    } else if (reason === "returnToLogin") {
      setContent({
        title: "Something went wrong",
        subtile: `We are unable to redirect you back${partner?.displayName ? " to " + partner?.displayName : ""} right now. Feel free to close this window.`,
        icon: <></>,
      });
    } else {
      setContent({
        title: "Something went wrong",
        subtile:
          "Please try again. If the issue persists, contact Meso support.",
        icon: <></>,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!content) {
    return null;
  }

  return (
    <div className="flex h-full flex-col items-center justify-center gap-4 text-center">
      {content.icon}
      <Heading
        data-testid="HandoffFailed:title"
        title={content.title}
        subtitle={content.subtile}
      />
    </div>
  );
};
