import { err, ok, Result } from "neverthrow";

/** Convert a string from a date input (`mm/dd/yyy`) to a legal `Date` object */
export const parseDateInput = (value: string): Result<Date, string> => {
  const [mm, dd, yyyy] = value.split("/").map(Number);
  const date = new Date(Date.UTC(yyyy, mm - 1, dd));

  if (Number.isNaN(date.getTime())) {
    return err(`Invalid date value: ${value}`);
  }

  return ok(date);
};

/** Convert a valid date to a string in the format expected by the API. */
export const formatDateForApi = (date: Date): string => {
  return date.toISOString().split("T")[0];
};

/**
 * Convert a timestamp represented as an [RFC-3339 Nano](https://www.ietf.org/rfc/rfc3339.txt) (`1990-12-29T00:00:00Z`) string to
 * our expected MM/DD/YYYY format.
 *
 * This is based on the `Time` scalar in our GraphQL API - https://gqlgen.com/reference/scalars/#time
 */
export const formatDateFromApi = (
  timestamp: string,
): Result<string, string> => {
  // Check if the input matches the expected format using a regex
  const regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{3})?Z$/;
  if (!regex.test(timestamp)) {
    return err(`Invalid date string: ${timestamp}`);
  }

  try {
    // Parse the date string
    const date = new Date(timestamp);
    if (isNaN(date.getTime())) {
      return err(`Invalid date value: ${timestamp}`);
    }

    // Format the date as MM/DD/YYYY
    const month = String(date.getUTCMonth() + 1).padStart(2, "0");
    const day = String(date.getUTCDate()).padStart(2, "0");
    const year = date.getUTCFullYear();

    return ok(`${month}/${day}/${year}`);
  } catch (error) {
    return err("Unable to format date.");
  }
};

export const formatDateString = ({
  previousValue,
  newValue,
}: {
  previousValue: string;
  newValue: string;
}): string => {
  // only allow digit or '/' chars with max length of 10 (i.e. MM/DD/YYYY)
  const filteredDate = newValue
    .replace(/\s/g, "")
    .replace(/-/g, "/")
    .replace(/[^0-9/]/g, "")
    .substring(0, 10);

  // return filtered date if deleting, typing invalid char, greater than 10 chars
  const isAdding = previousValue.length < newValue.length;
  if (previousValue == filteredDate || !isAdding) return filteredDate;

  if (
    (/^\d+$/.test(filteredDate) && filteredDate.length === 8) ||
    filteredDate.length === 6
  ) {
    return `${filteredDate.substring(0, 2)}/${filteredDate.substring(
      2,
      4,
    )}/${filteredDate.substring(4)}`;
  }

  const length = filteredDate.length;
  const lastCharIsSlash = filteredDate.endsWith("/");
  if (length == 1 || length == 4) {
    // only allow digit for first char in month and date portions
    return lastCharIsSlash ? filteredDate.slice(0, -1) : filteredDate;
  } else if (length == 2 || length == 5) {
    // pad with 0 if month or date portion is single digit, auto add '/' otherwise
    return lastCharIsSlash
      ? `${filteredDate.slice(0, -2)}0${filteredDate.slice(-2, -1)}/`
      : `${filteredDate}/`;
  } else if (length == 3 || length == 6) {
    // edge case for positions where '/' should be, but last char is not '/' due to deleting '/' and entering digit
    return lastCharIsSlash
      ? filteredDate
      : `${filteredDate.slice(0, -1)}/${filteredDate.slice(-1)}`;
  } else {
    // length is > 6, so only allow digit for year portion
    return lastCharIsSlash ? filteredDate.slice(0, -1) : filteredDate;
  }
};
