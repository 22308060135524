import { toast } from "sonner";
import { ErrorMessages } from "@tigris/mesokit";
import { useNavigate } from "react-router-dom";

import { Routes } from "./constants";
import { ResponseMiddleware } from "graphql-request";
import { GraphQLClientResponse } from "@src/types";
import { isAuthFactorError } from "@tigris/common";

const responseMiddleware = (
  navigate: ReturnType<typeof useNavigate>,
  clearToasts: () => void,
  search: string,
) => {
  const middleWare: ResponseMiddleware = (
    response: GraphQLClientResponse<unknown> | Error,
  ) => {
    const twoFactorErr =
      !(response instanceof Error) && isAuthFactorError(response.errors);

    if (twoFactorErr) {
      setTimeout(() => {
        clearToasts();

        setTimeout(
          () =>
            toast.error(ErrorMessages.auth.EXPIRED_REAUTHENTICATE, {
              id: "EXPIRED_REAUTHENTICATE_CTA",
              action: {
                label: "Reload",
                onClick: (event: React.MouseEvent) => {
                  event.preventDefault();
                  navigate({ pathname: Routes.LandingSheet, search });
                },
              },
              onDismiss: () =>
                navigate({ pathname: Routes.LandingSheet, search }),
            }),
          500,
        );
      }, 2e3);
    }
  };
  return middleWare;
};

export default responseMiddleware;
