import { defaultFormField, passwordSchema } from "@tigris/common";
import { InlineFormInput } from "@tigris/mesokit";
import {
  motion,
  useAnimate,
  usePresence,
  AnimationSequence,
} from "framer-motion";
import {
  ChangeEventHandler,
  FormEventHandler,
  MouseEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { spring } from "@src/utils/animation";
import { truncateEmailAddress } from "@src/utils/truncateEmailAddress";
import { INNER_CARD_HORIZONTAL_ANIMATION_DISTANCE } from "@src/utils/constants";
import { FormField } from "@src/types";

type FormState = {
  isValid: boolean;
  fields: { password: FormField };
};

const FORM_ID = "LandingSheetLoginEntry:password";
const INPUT_ID = "passwordInput";

const DEFAULT_FORM_STATE: FormState = {
  isValid: false,
  fields: { password: defaultFormField("") },
};

export const LandingSheetLoginEntryPassword = ({
  onComplete,
  isLoading,
  email,
  onReturnToEmail,
}: {
  email: string;
  isLoading: boolean;
  onComplete: (password: string) => void;
  onReturnToEmail: () => void;
}) => {
  const [scope, animate] = useAnimate();
  const [isPresent, safeToRemove] = usePresence();
  const [formState, setFormState] = useState<FormState>(DEFAULT_FORM_STATE);
  const passwordInput = useRef<HTMLInputElement>(null);
  const [isReturningToEmail, setIsReturningToEmail] = useState(false);

  const handleSubmit = useCallback<FormEventHandler<HTMLFormElement>>(
    async (event) => {
      event.preventDefault();

      if (!formState.isValid) {
        return;
      }

      onComplete(formState.fields.password.value);
    },
    [formState.fields.password.value, formState.isValid, onComplete],
  );

  const renderFieldAsValid = useCallback((): boolean => {
    const field = formState.fields.password;

    if (!field.isTouched || field.isValid) {
      return true;
    }

    if (field.isTouched && field.isDirty) {
      return field.isValid;
    }

    return true;
  }, [formState.fields.password]);

  const handleInputChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (event) => {
      setFormState((previousState) => {
        const previousValue = previousState.fields.password.value;
        const newValue = event.target.value;

        const isDirty = newValue !== previousValue;

        let isValid = false;

        const result = passwordSchema.safeParse(newValue);
        isValid = result.success;

        return {
          ...previousState,
          isValid,
          fields: {
            password: {
              ...previousState.fields.password,
              value: newValue,
              isValid,
              isDirty,
            },
          },
        };
      });
    },
    [setFormState],
  );

  const handleInputBlur = useCallback(() => {
    setFormState((previousState) => ({
      ...previousState,
      isTouched: true,
      isValid: previousState.fields.password.isValid,
      isDirty: previousState.fields.password.isDirty,
      fields: {
        password: {
          ...previousState.fields.password,
          isTouched: true,
        },
      },
    }));
  }, []);

  const focusInput = useCallback(() => {
    passwordInput.current?.focus();
  }, []);

  const returnToEmail = useCallback<MouseEventHandler<HTMLDivElement>>(
    (event) => {
      event.preventDefault();
      setIsReturningToEmail(true);
      onReturnToEmail();
    },
    [onReturnToEmail],
  );

  const truncatedEmail = useMemo(() => truncateEmailAddress(email), [email]);
  // Animate in
  useEffect(() => {
    const sequence: AnimationSequence = [
      [scope.current, { opacity: 1 }],
      [scope.current, { x: 0 }, { at: 0.05 }],
      [".login-input-label", { y: 0, opacity: 1 }, { at: 0.2 }],
      [".login-input-accessory", { y: 0, opacity: 0.6 }, { at: 0.3 }],
    ];

    animate(sequence).then(focusInput);
  }, [animate, focusInput, scope]);

  // Exit
  useEffect(() => {
    const sequence: AnimationSequence = [
      [scope.current, { opacity: 0 }],
      [".login-input-label", { y: 10, opacity: 0 }],
      [".login-input-accessory", { y: 10, opacity: 0 }],
      [
        scope.current,
        {
          x: isReturningToEmail
            ? INNER_CARD_HORIZONTAL_ANIMATION_DISTANCE
            : -INNER_CARD_HORIZONTAL_ANIMATION_DISTANCE,
        },
        { at: 0.05 },
      ],
    ];

    if (!isPresent) {
      animate(sequence).then(safeToRemove);
    }
  }, [animate, isPresent, isReturningToEmail, safeToRemove, scope]);

  return (
    <motion.div
      className="password-entry-container"
      key={`${FORM_ID}:motion`}
      data-testid={`${FORM_ID}:motion`}
      initial={{ x: INNER_CARD_HORIZONTAL_ANIMATION_DISTANCE, opacity: 0 }}
      transition={{ ...spring }}
      ref={scope}
    >
      <form
        id={FORM_ID}
        name={FORM_ID}
        data-testid={FORM_ID}
        onSubmit={handleSubmit}
        noValidate
      >
        <motion.div
          initial={{ y: 10, opacity: 0 }}
          className="login-input-label mb-2 flex gap-1 text-xs font-bold text-neutral-900 dark:text-white"
        >
          <label htmlFor={INPUT_ID} className="cursor-pointer">
            Password for
          </label>
          <div
            className="cursor-pointer opacity-60"
            onClick={returnToEmail}
            data-testid={`${FORM_ID}:backToEmail`}
          >
            {truncatedEmail}{" "}
            <FontAwesomeIcon icon={icon({ name: "pen", style: "solid" })} />
          </div>
          <motion.a
            initial={{ y: 10, opacity: 0 }}
            className="login-input-accessory group ml-auto cursor-pointer opacity-60"
            href="https://account.meso.network/forgot-password"
            target="_blank"
            data-testid={`${FORM_ID}:forgotPassword`}
          >
            Forgot?
          </motion.a>
        </motion.div>

        {/* Add a hidden input to assist password managers */}
        <input
          type="email"
          name="username"
          id="username"
          value={email}
          autoComplete="username"
          readOnly
          className="hidden"
        />
        <InlineFormInput
          input={{
            name: INPUT_ID,
            type: "password",
            placeholder: "Enter your password",
            value: formState.fields.password.value,
            onChange: handleInputChange,
            onBlur: handleInputBlur,
            isValid: renderFieldAsValid(),
            disabled: isLoading,
            maxLength: 255,
            "data-testid": `${FORM_ID}:${INPUT_ID}`,
            autoComplete: "current-password",
          }}
          ref={passwordInput}
          button={{
            primary: true,
            disabled: isLoading || !formState.isValid,
            "data-testid": `${FORM_ID}:button`,
          }}
        />
      </form>
    </motion.div>
  );
};
