import smarty from "smartystreets-javascript-sdk";

import { CountryCodeAlpha3, NonUSCountryCodeAlpha3 } from "../../types";

export type USAddressSuggestion = smarty.usAutocompletePro.Suggestion;
export type EmptyComboboxAddressItem = { __brand: "" };

/**
 * Details of a non-US address returned from Smarty.
 *
 * See [docs](https://www.smarty.com/docs/cloud/international-address-autocomplete-api#detailed-results).
 */
export type InternationalLookupAddressDetail = {
  street: string;
  /** Roughly, a city name. This value may not be returned, particularly for Iceland and Bulgaria */
  locality?: string;
  administrativeArea: string;
  /** An abbreviation, if available.
   *
   * For the example result of `"New Brunswick" (in Canada), this would be `"NB"`.
   *
   * Certain countries (Cyprus, Latvia, Malta, Slovenia) may not have not value.
   */
  administrativeAreaShort?: string;
  /** The full text of the administrative area.
   *
   * As an example, in Canada we would see `"New Brunswick"` or `"Ontario"`.
   */
  administrativeAreaLong?: string;
  postalCode: string;
  countryIso3: CountryCodeAlpha3;
};

/** A non-US address suggestion from Smarty. */
export type InternationalAddressSuggestion = {
  entries: number;
  addressText: string;
  addressId: string;
};

export type InternationalAddressSuggestions = InternationalAddressSuggestion[];
/** A "detail" lookup will return only a single address. */
export type InternationalAddressLookupDetailResult = [
  InternationalLookupAddressDetail,
];

/**
 * The result of querying the Smarty SDK with a search term and country.
 **/
export type InternationalAddressLookupResult = {
  /**
   * The result will either be a list of summary results:
   *
   * ```json
   * "result": [
   *   {
   *     "entries": 1,
   *     "addressText": "Schießmauer 1 89174 Altheim (Alb)",
   *     "addressId": "OzoDMy8DPgNMSUpHA04gODot"
   *   },
   *   // ...
   * }
   * ```
   *
   * Or a single address detail:
   *
   * ```json
   * "results": [
   *   {
   *     "street": "Schießmauer 1",
   *     "locality": "Altheim (Alb)",
   *     "administrativeArea": "Baden-Württemberg",
   *     "postalCode": "89174",
   *     "countryIso3": "DEU"
   *   }
   * ]
   * ```
   */
  result:
    | InternationalAddressSuggestions
    | InternationalAddressLookupDetailResult;
  search: string;
  country: NonUSCountryCodeAlpha3;
  maxResults: number;
  addressId?: string;
};

/** The input to Smarty's `.Lookup` call for non-US addresses */
export type InternationalLookupParams = {
  search: string;
  country: NonUSCountryCodeAlpha3;
  maxResults: number;
  addressId?: string;
};

/** Test at runtime whether a given value is a detail result or not. */
export const isInternationalDetailResult = (
  result: InternationalAddressLookupResult["result"],
): result is InternationalAddressLookupDetailResult => {
  return (
    (result as InternationalAddressLookupDetailResult).length === 1 &&
    (result as InternationalAddressLookupDetailResult)[0].street !== undefined
  );
};

/** Test at runtime whether a given collection is a list of suggestions or a finalized "detail" result. */
export const isInternationalAddressSuggestions = (
  collection:
    | InternationalLookupAddressDetail
    | InternationalAddressSuggestions,
): collection is InternationalAddressSuggestions => {
  return (collection as InternationalAddressSuggestions).entries !== undefined;
};

/** Test at runtime whether a given international address is a finalized "detail" result or a list of more suggestions. */
export const isInternationalAddressDetail = (
  address: InternationalLookupAddressDetail | InternationalAddressSuggestions,
): address is InternationalLookupAddressDetail => {
  return (address as InternationalLookupAddressDetail).street !== undefined;
};

/** Test at runtime whether a given value is a US address suggestion or not. */
export const isUSAddressSuggestion = (
  address:
    | USAddressSuggestion
    | InternationalAddressSuggestion
    | InternationalLookupAddressDetail
    | EmptyComboboxAddressItem,
): address is USAddressSuggestion =>
  "zipcode" in (address as USAddressSuggestion);
