import { Title, Text } from "@tigris/mesokit";

const Heading = ({
  title,
  subtitle,
}: {
  title: string;
  subtitle?: string | JSX.Element;
}) => (
  <header>
    <Title.Medium bold data-testid="Heading:title">
      {title}
    </Title.Medium>
    <Text data-testid="Heading:subtitle">{subtitle}</Text>
  </header>
);

export default Heading;
