import { USAddressSuggestion } from "./smarty";

/**
 * Parse an international or US Smarty Address Suggestion into a consistently formatted address string.
 */
export const formatUSAddressSuggestion = (
  suggestion?: USAddressSuggestion,
): string => {
  if (!suggestion) return "";

  return [
    `${suggestion?.streetLine} ${suggestion?.secondary}`,
    suggestion?.city,
    `${suggestion?.state} ${suggestion?.zipcode}`,
  ]
    .map((component) => component.trim())
    .filter((component) => component.length > 0)
    .join(", ");
};
