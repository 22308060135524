import { Routes } from "./constants";

/** "Base paths" for routes that exist in the `standalone` integration that need to resolve to the root (`/`) to ensure deep-linking does not break the application. */
export const STANDALONE_CLIENT_BASE_PATHS = [
  "/onboarding",
  "/landing",
  "/transfer",
  "/transfer-unavailable",
  "/activate-user",
];

export const isTransferRoute = (pathname?: string) =>
  pathname?.startsWith("/transfer") && pathname !== Routes.TransferUnavailable;

/** Determine if a given pathname is required to be rendered modally. */
export const isModalExperienceRoute = (pathname?: string) =>
  pathname?.startsWith("/modal") || pathname?.startsWith("/app");

export const isEmbeddedRoute = (pathname?: string) =>
  pathname?.startsWith("/app");

export const isStandaloneRoute = (pathname?: string) =>
  pathname === "/standalone" || pathname === "/";

export const isRewardsRoute = (pathname?: string) => pathname === "/rewards";

export const isInlineRoute = (pathname?: string) => pathname === "/inline";

/** Replace extraneous double-slashes in pathnames.
 *
 * ```
 * "foo/bar" → "foo/bar"
 * "foo//bar" → "foo/bar"
 * "foo//bar//baz" → "foo/bar/baz"
 * ```
 */
export const cleanPath = (pathname: string) => pathname.replace(/\/\//g, "/");

/** Determine whether the given pathname is a route in the Onboarding application. */
export const isOnboardingAppRoute = (pathname: string) =>
  pathname === Routes.Onboarding;
