import { Result, err, ok } from "neverthrow";

/**
 *
 * @param birthDate ISO 8601 format date time in UTC (e.g. "1950-01-01T00:00:00Z")
 * @returns neverthrow result with number age value or string error
 */
export const getAge = (birthDate?: string | null): Result<number, string> => {
  if (!birthDate) return err("No birthday provided");

  const birth = new Date(birthDate.replace("Z", "")); // Convert birth date string to Date object at midnight in local time
  const today = new Date(); // Get today's date in local time

  let age = today.getFullYear() - birth.getFullYear(); // Calculate initial age

  // Adjust age if the birthday hasn't occurred yet this year
  const hasBirthdayOccurredThisYear =
    today.getMonth() > birth.getMonth() ||
    (today.getMonth() === birth.getMonth() &&
      today.getDate() >= birth.getDate());

  if (!hasBirthdayOccurredThisYear) {
    age--; // If birthday hasn't occurred yet this year, subtract 1 from age
  }

  return ok(age);
};
