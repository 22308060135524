import { createContext } from "react";
import { toast } from "sonner";
import { MesoPosthog, MesoSentry } from "@tigris/common";

export type MesoKitContextValue = {
  /** Reference to an instantiated `Sentry` instance in the embedding application. */
  sentry?: MesoSentry;
  /** Reference to an instantiated `Posthog` instance in the embedding application. */
  posthog?: MesoPosthog;
  /** Reference to the `sonner.toast` namespace in the embedding application. */
  toast?: typeof toast;
};

export const MesoKitContext = createContext<MesoKitContextValue>({
  sentry: undefined,
  posthog: undefined,
  toast: undefined,
});
