import { OnboardingContext } from "./OnboardingContext";
import { PropsWithChildren, useCallback, useMemo, useState } from "react";
import { IntegrationMode, OnboardingContextValue } from "@src/types";
import { useLocation, useNavigate } from "react-router-dom";
import { Routes } from "@src/utils/constants";
import { toast } from "sonner";
import { nanoid } from "nanoid";
import { launchOnboardingWindow } from "@src/utils/launchOnboardingWindow";
import { useApp } from "@src/hooks/useApp";
import { InstrumentKind, ProfileFieldStatus } from "@src/generated/sdk";
import { useApplePayContext } from "@src/hooks/useApplePayContext";

const TOAST_ID = "OnboardingContextProvider";
const ONBOARDING_APP_ORIGIN = import.meta.env.VITE_ONBOARDING_APP_ORIGIN;

export const OnboardingContextProvider = ({ children }: PropsWithChildren) => {
  const { search } = useLocation();
  const navigate = useNavigate();
  const {
    mode,
    partner,
    session,
    configuration: { network, walletAddress },
    resetUser,
  } = useApp();
  const { applePayEnabled } = useApplePayContext();
  const [onboardingInProgress, setOnboardingInProgress] = useState(false);
  const [cancelOnboarding, setCancelOnboarding] = useState<
    OnboardingContextValue["cancelOnboarding"]
  >(() => {});

  const supportedPaymentMethods = useMemo(() => {
    const supported = [InstrumentKind.PAYMENT_CARD];
    if (applePayEnabled) supported.push(InstrumentKind.APPLE_PAY);
    return supported;
  }, [applePayEnabled]);

  const initializeOnboarding = useCallback<
    OnboardingContextValue["initializeOnboarding"]
  >(
    (
      profileStatus = {
        __typename: "ProfileStatus",
        account: false,
        acceptedMesoTerms: false,
        acceptedZeroHashTerms: false,
        phone: false,
        phoneVerified: false,
        financialInstrument: false,
        basicInfo: false,
        residentialAddress: false,
        taxpayerId: false,
        citizenship: false,
        acknowledgedScamWarning: false,
        residenceCountry: null,
        pesel: ProfileFieldStatus.UNKNOWN,
        identityDocuments: false,
      },
      user,
      options,
    ) => {
      if (
        mode === IntegrationMode.STANDALONE ||
        mode === IntegrationMode.WEBVIEW
      ) {
        const state = {
          profileStatus,
          supportedPaymentMethods,
        };
        navigate({ pathname: Routes.Onboarding, search }, { state });
        setOnboardingInProgress(true);
      } else {
        if (!session || !partner) {
          throw new Error("Invalid session.");
        }

        const clientSessionId = nanoid(8);
        const onboardingWindowResult = launchOnboardingWindow({
          url: `${ONBOARDING_APP_ORIGIN}`.trim(),
          // Add a unique identifier to the window name to prevent reference collisions and the following browser error/warning
          // Unsafe attempt to initiate navigation for frame with URL '<onboarding_origin>' from frame with URL '<transfer_app_origin>'. The frame attempting navigation is neither same-origin with the target, nor is it the target's parent or opener.
          name: `Meso Onboarding ${clientSessionId}`,
          onReturnToTransfer: (reason) => {
            setOnboardingInProgress(false);

            switch (reason) {
              case "returnToLogin": {
                // A caller can provide an optional callback to override the default behavior for the `onReturnToTransfer` function.
                // If not provided, we will perform best-effort routing
                resetUser();
                if (options?.onReturnToTransferLogin) {
                  options.onReturnToTransferLogin();
                } else {
                  navigate({ pathname: Routes.LandingSheetLoginEntry, search });
                }
                break;
              }

              case "onboardingComplete": {
                // A caller can provide an optional callback to override the default behavior for the `onReturnToTransfer` function.
                // If not provided, we will perform best-effort routing
                if (options?.onReturnToTransferOnboardingComplete) {
                  options.onReturnToTransferOnboardingComplete();
                } else {
                  navigate({ pathname: Routes.TransferSheet, search });
                }
                break;
              }

              case "onboardingTerminated": {
                // A caller can provide an optional callback to override the default behavior for the `onReturnToTransfer` function.
                // If not provided, we will perform best-effort routing
                resetUser();
                if (options?.onReturnToTransferOnboardingTerminated) {
                  options.onReturnToTransferOnboardingTerminated();
                } else {
                  navigate({ pathname: Routes.TransferUnavailable, search });
                }
              }
            }
          },
          onRequestOnboardingConfiguration: () => ({
            partner,
            session: {
              id: session.id,
              token: session.token,
              isReturningUser: session.isReturningUser,
              passkeysEnabled: session.passkeysEnabled,
              applepayEnabled: session.applepayEnabled,
              riskSession: session.riskSession,
              deviceCountry: session.deviceCountry,
            },
            network,
            walletAddress,
            supportedPaymentMethods,
            profileStatus,
            user,
          }),
        });

        if (onboardingWindowResult.isErr()) {
          toast.error(onboardingWindowResult.error, { id: TOAST_ID });
          return;
        }

        setCancelOnboarding(() => () => {
          onboardingWindowResult.value.close();

          setOnboardingInProgress(false);

          resetUser();
          if (options?.onReturnToTransferOnboardingTerminated) {
            options.onReturnToTransferOnboardingTerminated();
          } else {
            navigate({ pathname: Routes.LandingSheetLoginEntry, search });
          }

          setCancelOnboarding(() => {});
        });

        setOnboardingInProgress(true);
      }
    },
    [
      mode,
      navigate,
      network,
      partner,
      resetUser,
      search,
      session,
      supportedPaymentMethods,
      walletAddress,
    ],
  );

  const contextValue = useMemo(
    () => ({
      initializeOnboarding,
      onboardingInProgress,
      cancelOnboarding,
      supportedPaymentMethods,
    }),
    [
      cancelOnboarding,
      initializeOnboarding,
      onboardingInProgress,
      supportedPaymentMethods,
    ],
  );

  return (
    <OnboardingContext.Provider value={contextValue}>
      {children}
    </OnboardingContext.Provider>
  );
};
