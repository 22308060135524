import { Result } from "neverthrow";
import { CountryCodeAlpha2, Routes } from "../../types";
import { getNextInternationalOnboardingStep } from "./getNextInternationalOnboardingStep";
import { getNextUSOnboardingStep } from "./getNextUSOnboardingStep";
import { NextOnboardingArgs } from "./types";

export const getNextOnboardingStep = (
  args: NextOnboardingArgs,
): Result<Routes, string> => {
  if (args.profileStatus.residenceCountry === CountryCodeAlpha2.US) {
    return getNextUSOnboardingStep(args);
  } else {
    return getNextInternationalOnboardingStep(args);
  }
};
