import {
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { AppContext } from "@src/contexts/AppContextProvider";
import { ApplePayContext } from "./ApplePayContext";
import { MESO_JS_VERSION } from "@meso-network/meso-js";
import { Sentry } from "@tigris/common";
import { lt } from "semver";

const APPLE_PAY_SDK_URL =
  "https://applepay.cdn-apple.com/jsapi/v1.1.0/apple-pay-sdk.js";
const APPLE_PAY_SCRIPT_LOAD_TIMEOUT_DELAY_MS = 3_000;
const MIN_MESO_JS_VERSION = "0.1.10";
const KNOWN_PERMISSION_ERRORS = ["SecurityError", "InvalidAccessError"];

export const ApplePayContextProvider = ({ children }: PropsWithChildren) => {
  const sdkLoadedRef = useRef(false);
  const { session } = useContext(AppContext);
  const [applePaySdkLoaded, setApplePaySdkLoaded] = useState(false);
  const [applePaySupported, setApplePaySupported] = useState(false);
  const [applePayCanceled, setApplePayCanceled] = useState(false);

  const applePayInitiativeContext = useMemo(() => {
    let host: string | undefined = undefined;
    try {
      if (window.self === window.top) {
        host = location.host;
      } else if (!!document.referrer) {
        host = new URL(document.referrer).host;
      }
    } catch {
      if (!!document.referrer) {
        host = new URL(document.referrer).host;
      }
    }
    return host;
  }, []);

  useEffect(() => {
    if (
      !session?.applepayEnabled ||
      lt(MESO_JS_VERSION, MIN_MESO_JS_VERSION) ||
      sdkLoadedRef.current
    ) {
      return;
    }
    sdkLoadedRef.current = true;
    const scriptLoadTimeout = setTimeout(
      () => setApplePaySdkLoaded(true),
      APPLE_PAY_SCRIPT_LOAD_TIMEOUT_DELAY_MS,
    );

    const script = document.createElement("script");
    script.src = APPLE_PAY_SDK_URL;
    script.onload = () => {
      clearTimeout(scriptLoadTimeout);
      setApplePaySdkLoaded(true);

      try {
        setApplePaySupported(!!window.ApplePaySession?.canMakePayments());
      } catch (error) {
        if (
          error instanceof DOMException &&
          KNOWN_PERMISSION_ERRORS.includes(error.name)
        ) {
          Sentry.captureMessage(error.message, { level: "log" });
        } else {
          Sentry.captureException(error);
        }
      }
    };
    script.onerror = () => {
      clearTimeout(scriptLoadTimeout);
      setApplePaySdkLoaded(true);
    };

    document.body.appendChild(script);
  }, [session?.applepayEnabled]);

  const contextValue = useMemo(
    () => ({
      applePayCanceled,
      setApplePayCanceled: () => setApplePayCanceled(true),
      applePayEnabled:
        !!session?.applepayEnabled &&
        !!applePayInitiativeContext &&
        applePaySdkLoaded &&
        applePaySupported,
      applePayInitiativeContext,
    }),
    [
      applePayCanceled,
      applePayInitiativeContext,
      applePaySdkLoaded,
      applePaySupported,
      session?.applepayEnabled,
    ],
  );

  return (
    <ApplePayContext.Provider value={contextValue}>
      {children}
    </ApplePayContext.Provider>
  );
};
