import {
  ChangeEvent,
  ChangeEventHandler,
  useCallback,
  useEffect,
  useState,
} from "react";
import { Input } from "../../Input";
import { LabelledInput } from "../../Input/LabelledInput";
import { parseUSAddress } from "../../../utils/parseUSAddress";
import { z } from "zod";
import {
  defaultFormField,
  ALL_US_AND_TERRITORY_CODES_UPPERCASE,
  addressLine1Schema,
  citySchema,
  stateNameSchema,
  zipSchema,
} from "@tigris/common";
import { twMerge } from "tailwind-merge";
import { FormField } from "../../../types";
import { USAddressSuggestion } from "../../../utils/smarty";

export type USAddressFormProps = {
  suggestion?: USAddressSuggestion;
  labelText?: string;
  placeholder?: string;
  disabled: boolean;
  isValid: boolean;

  initialValue?: USAddressSuggestion;
  onAddressResolved: (params: {
    address?: USAddressSuggestion;
    rawValue: string;
  }) => void;
};

type FormState = {
  isValid: boolean;
  isTouched: boolean;
  fields: {
    addressLine1: FormField;
    addressLine2: FormField;
    city: FormField;
    state: FormField;
    zipcode: FormField;
  };
  suggestion?: USAddressSuggestion;
  /** The raw address string. */
  rawValue: string;
};

const ALL_US_AND_TERRITORY_NAMES_TO_ABBREVIATION = {
  Alaska: "AK",
  Alabama: "AL",
  Arkansas: "AR",
  Arizona: "AZ",
  California: "CA",
  Colorado: "CO",
  Connecticut: "CT",
  "District of Columbia": "DC",
  Delaware: "DE",
  Florida: "FL",
  Georgia: "GA",
  Hawaii: "HI",
  Iowa: "IA",
  Idaho: "ID",
  Illinois: "IL",
  Indiana: "IN",
  Kansas: "KS",
  Kentucky: "KY",
  Louisiana: "LA",
  Massachusetts: "MA",
  Maryland: "MD",
  Maine: "ME",
  Michigan: "MI",
  Minnesota: "MN",
  Missouri: "MO",
  Mississippi: "MS",
  Montana: "MT",
  "North Carolina": "NC",
  "North Dakota": "ND",
  Nebraska: "NE",
  "New Hampshire": "NH",
  "New Jersey": "NJ",
  "New Mexico": "NM",
  Nevada: "NV",
  "New York": "NY",
  Ohio: "OH",
  Oklahoma: "OK",
  Oregon: "OR",
  Pennsylvania: "PA",
  "Rhode Island": "RI",
  "South Carolina": "SC",
  "South Dakota": "SD",
  Tennessee: "TN",
  Texas: "TX",
  Utah: "UT",
  Virginia: "VA",
  Vermont: "VT",
  Washington: "WA",
  Wisconsin: "WI",
  "West Virginia": "WV",
  Wyoming: "WY",
};

const StatePicker = ({
  value,
  disabled,
  onChange,
}: {
  value: string;
  disabled: boolean;
  onChange: ChangeEventHandler;
}) => {
  return (
    <>
      <input
        className="h-0 w-0"
        placeholder="State"
        autoComplete="address-level1"
        tabIndex={-1}
        disabled={disabled}
        onChange={(e) => {
          Object.entries(ALL_US_AND_TERRITORY_NAMES_TO_ABBREVIATION).forEach(
            ([name, abbrev]) => {
              if (
                [name.toLowerCase(), abbrev.toLowerCase()].includes(
                  e.target.value.trim().toLowerCase(),
                )
              ) {
                onChange({
                  target: { value: abbrev },
                } as unknown as ChangeEvent<Element>);
              }
            },
          );
        }}
      />
      <select
        disabled={disabled}
        name="state-select"
        id="state-select"
        className={twMerge("select", value.length > 0 && "select-selected")}
        value={value}
        onChange={onChange}
        data-testid="state-select"
      >
        <optgroup label="State">
          {ALL_US_AND_TERRITORY_CODES_UPPERCASE.map((item) => (
            <option key={item} value={item}>
              {item}
            </option>
          ))}
        </optgroup>
      </select>
    </>
  );
};

const defaultFormState = (suggestion?: USAddressSuggestion): FormState => {
  const validAddressLine1 =
    !suggestion || addressLine1Schema.safeParse(suggestion.streetLine).success;
  return suggestion
    ? {
        isValid: validAddressLine1,
        isTouched: true,
        fields: {
          addressLine1: {
            ...defaultFormField(suggestion.streetLine),
            isValid: validAddressLine1,
            isDirty: !validAddressLine1,
            isTouched: true,
          },
          addressLine2: {
            ...defaultFormField(suggestion.secondary),
            isValid: true,
            isTouched: true,
          },
          city: {
            ...defaultFormField(suggestion.city),
            isValid: true,
            isTouched: true,
          },
          state: {
            ...defaultFormField(suggestion.state),
            isValid: true,
            isTouched: true,
          },
          zipcode: {
            ...defaultFormField(suggestion.zipcode),
            isValid: true,
            isTouched: true,
          },
        },
        suggestion,
        rawValue: suggestion
          ? [
              `${suggestion.streetLine} ${suggestion.secondary}`,
              suggestion.city,
              `${suggestion.state} ${suggestion.zipcode}`,
            ]
              .map((component) => component.trim())
              .filter((component) => component.length > 0)
              .join(", ")
          : "",
      }
    : {
        isValid: false,
        isTouched: false,
        fields: {
          addressLine1: defaultFormField(""),
          addressLine2: { ...defaultFormField(""), isValid: true },
          city: defaultFormField(""),
          state: defaultFormField(""),
          zipcode: defaultFormField(""),
        },
        suggestion: undefined,
        rawValue: "",
      };
};

export const USAddressForm = ({
  disabled,
  isValid,
  labelText,
  onAddressResolved,
  suggestion,
}: USAddressFormProps) => {
  const [formState, setFormState] = useState<FormState>(() =>
    defaultFormState(suggestion),
  );

  const renderFieldAsValid = useCallback(
    (fieldKey: keyof FormState["fields"]): boolean => {
      const field = formState.fields[fieldKey];
      if (!field.isTouched || field.isValid) {
        return true;
      } else if (field.isTouched && field.isDirty) {
        return field.isValid;
      }
      return true;
    },
    [formState.fields],
  );

  const onBlur = useCallback(
    (fieldName: keyof FormState["fields"]) => () => {
      setFormState((previousState) => {
        return {
          ...previousState,
          fields: {
            ...previousState.fields,
            [fieldName]: {
              ...previousState.fields[fieldName],
              isTouched: true,
            },
          },
        };
      });
    },
    [],
  );

  const handleChange = useCallback(function changeHandler({
    fieldName,
    validationSchema,
  }: {
    fieldName: keyof FormState["fields"];
    validationSchema: z.ZodSchema;
  }) {
    return (event: ChangeEvent<HTMLInputElement>) => {
      setFormState((previousState) => {
        const newValue = event.target.value;

        const isDirty =
          previousState.fields[fieldName].isTouched ||
          newValue !== previousState.fields[fieldName].value;

        const isValid = validationSchema.safeParse(newValue).success;

        const newFields = {
          ...previousState.fields,
          [fieldName]: {
            ...previousState.fields[fieldName],
            value: newValue,
            isValid,
            isDirty,
          },
        };

        const addressToParse = [
          `${newFields.addressLine1.value} ${newFields.addressLine2.value}`,
          newFields.city.value,
          `${newFields.state.value} ${newFields.zipcode.value}`,
        ]
          .map((component) => component.trim())
          .filter((component) => component.length > 0)
          .join(", ");
        const parsedAddressResult = parseUSAddress(addressToParse);

        const formValid =
          parsedAddressResult.isOk() &&
          Object.entries(newFields).every(([_, { isValid }]) => isValid);

        return {
          ...previousState,
          isTouched: true,
          isValid: formValid,
          fields: newFields,
          suggestion: parsedAddressResult.isOk()
            ? parsedAddressResult.value
            : undefined,
          rawValue: addressToParse,
        };
      });
    };
  }, []);

  // Dispatch the `onAddressResolved` callback after the state update is applied in `handleChange`. This prevents the bad setState() call warning/error.
  useEffect(() => {
    onAddressResolved({
      address: formState.suggestion,
      rawValue: formState.rawValue,
    });
  }, [
    formState.fields,
    formState.rawValue,
    formState.suggestion,
    onAddressResolved,
  ]);

  return (
    <LabelledInput
      isValid={isValid && (!formState.isTouched || formState.isValid)}
      labelProps={{ text: labelText ?? "Address" }}
      disabled={disabled}
      name="addressLine1"
      inputComponent={
        <div className="flex flex-col divide-y rounded-2xl border border-solid dark:border-transparent">
          <Input
            className="!rounded-none !rounded-t-2xl border-none ring-inset"
            name="addressLine1"
            placeholder="Address Line 1"
            value={formState.fields.addressLine1.value}
            isValid={renderFieldAsValid("addressLine1")}
            disabled={disabled}
            onChange={handleChange({
              fieldName: "addressLine1",
              validationSchema: addressLine1Schema,
            })}
            onBlur={onBlur("addressLine1")}
            autoComplete="address-line1"
            autoFocus
            data-testid="usAddressForm:addressLine1"
          />
          <div className="dark:border-t-neutral-600">
            <Input
              className="!rounded-none border-none ring-inset"
              placeholder="Address Line 2"
              value={formState.fields.addressLine2.value}
              isValid={renderFieldAsValid("addressLine2")}
              disabled={disabled}
              onChange={handleChange({
                fieldName: "addressLine2",
                validationSchema: z.string().optional(),
              })}
              onBlur={onBlur("addressLine2")}
              name="addressLine2"
              autoComplete="address-line2"
            />
          </div>
          <div className="flex flex-row dark:border-t-neutral-600">
            <div className="flex-[2]">
              <Input
                className="!rounded-none !rounded-bl-2xl border-none ring-inset"
                placeholder="City"
                name="city"
                value={formState.fields.city.value}
                isValid={renderFieldAsValid("city")}
                disabled={disabled}
                onChange={handleChange({
                  fieldName: "city",
                  validationSchema: citySchema,
                })}
                onBlur={onBlur("city")}
                autoComplete="address-level2"
              />
            </div>
            <div className="flex-[1.25]">
              <StatePicker
                value={formState.fields.state.value}
                disabled={disabled}
                onChange={handleChange({
                  fieldName: "state",
                  validationSchema: stateNameSchema,
                })}
              />
            </div>
            <div className="flex-[1.5]">
              <Input
                className="!rounded-none !rounded-br-2xl border-none ring-inset"
                placeholder="Zipcode"
                value={formState.fields.zipcode.value}
                isValid={renderFieldAsValid("zipcode")}
                disabled={disabled}
                onChange={handleChange({
                  fieldName: "zipcode",
                  validationSchema: zipSchema,
                })}
                onBlur={onBlur("zipcode")}
                autoComplete="postal-code"
              />
            </div>
          </div>
        </div>
      }
    />
  );
};
