export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /**
   * A timestamp represented as an [RFC-3339 Nano](https://www.ietf.org/rfc/rfc3339.txt) string
   *
   * Example: `2006-01-02T15:04:05.999999999Z07:00`
   */
  Time: { input: string; output: string; }
};

export type AcceptMesoTermsInput = {
  /** A client-generated version identifier for the Meso privacy policy presented during the `Transfer`. */
  acceptedPrivacyVersion: Scalars['String']['input'];
  /** A client-generated version identifier for the Meso terms presented during the `Transfer`. */
  acceptedTermsVersion: Scalars['String']['input'];
};

export type ActivateInput = {
  /** The Meso-generated token representing the user's risk session. */
  riskSessionKey: Scalars['String']['input'];
};

export type ActivationResult = Errors | UserActivation;

export type AddBasicInfoInput = {
  /** The date of birth in `YYYY-MM-DD` format. */
  dateOfBirth: Scalars['String']['input'];
  /** The given name of the user. */
  firstName: Scalars['String']['input'];
  /** The family name of the user. */
  lastName: Scalars['String']['input'];
};

export type AddCitizenshipInput = {
  /** An [ISO-3166](https://en.wikipedia.org/wiki/ISO_3166) 2 character country code. */
  countryCode: Scalars['String']['input'];
};

export type AddPaymentCardInput = {
  /** Should we skip replacing the input, null or false will replace */
  skipReplace?: InputMaybe<Scalars['Boolean']['input']>;
  /**
   * The checkout frame token for the card. The token should contain:
   * * PAN
   * * CVV
   * * expiration
   * * cardholder name
   * * billing address
   */
  token: Scalars['String']['input'];
};

export type AddPeselInput = {
  /** The PESEL (Polish: Powszechny Elektroniczny System Ewidencji Ludności) number */
  pesel: Scalars['String']['input'];
};

export type AddPhoneNumberInput = {
  /** An [E.164](https://en.wikipedia.org/wiki/E.164) compliant phone number. */
  phoneNumber: Scalars['String']['input'];
};

export type AddResidenceCountryInput = {
  /** An [ISO-3166](https://en.wikipedia.org/wiki/ISO_3166) 2 character country code. */
  countryCode: Scalars['String']['input'];
};

export type AddResidentialAddressInput = {
  /** The street address of the user. */
  address1: Scalars['String']['input'];
  /** Secondary address information (unit, suite, etc.). */
  address2?: InputMaybe<Scalars['String']['input']>;
  /** Additional address information. */
  address3?: InputMaybe<Scalars['String']['input']>;
  /** Additional address information. */
  address4?: InputMaybe<Scalars['String']['input']>;
  city: Scalars['String']['input'];
  /** An [ISO-3166](https://en.wikipedia.org/wiki/ISO_3166) 2 character country code. */
  countryCode: Scalars['String']['input'];
  /** A postal code based on the [ISO-3166](https://en.wikipedia.org/wiki/ISO_3166) 2 character country code. */
  postalCode: Scalars['String']['input'];
  /** A two-digit US state or territory code. */
  region: Scalars['String']['input'];
};

export type AddTaxpayerIdFullInput = {
  /** The user's taxpayer id, without dashes, eg (1111111111) */
  taxpayerId: Scalars['String']['input'];
};

export type AddTaxpayerIdLast4Input = {
  /** The last-4 digits of the user's tax ID (SSN). */
  taxpayerIdLast4: Scalars['String']['input'];
};

export type AddUserWalletInput = {
  /** The public key for the wallet to add */
  walletAddress: Scalars['String']['input'];
};

export type Address = {
  __typename?: 'Address';
  address1: Scalars['String']['output'];
  address2?: Maybe<Scalars['String']['output']>;
  address3?: Maybe<Scalars['String']['output']>;
  address4?: Maybe<Scalars['String']['output']>;
  city: Scalars['String']['output'];
  countryCode: Scalars['String']['output'];
  postalCode: Scalars['String']['output'];
  region: Scalars['String']['output'];
};

/**
 * An intent to cash out, must be created before funds are sent
 * to the deposit address
 */
export type AnonCashOutQuote = {
  __typename?: 'AnonCashOutQuote';
  /** The asset that will be sent to the fiat account */
  destAsset: Asset;
  /**
   * The amount that will show up in the user's fiat account
   *
   * destination = sourceSubtotal - mesoFee
   */
  destination: Money;
  /** The exchange rate being offered */
  exchangeRate: ExchangeRate;
  /** the expiration time of the cash out */
  expiration: Scalars['Time']['output'];
  /** The fee that meso will charge for the cash out */
  mesoFee: Money;
  /** The asset that should be sent to the deposit address */
  sourceAsset: Asset;
  /** The source amount converted to the destination currency */
  sourceSubtotal: Money;
  /** The amount that should be sent to the deposit asset */
  sourceTotal: Money;
};

export type AnonCashOutQuoteResult = AnonCashOutQuote | Errors;

/** A non-executable anonymous price quote */
export type AnonQuote = {
  __typename?: 'AnonQuote';
  /** Details about the asset being received */
  destAsset: Asset;
  /** The amount of crypto the user will be sent. */
  destination: Money;
  /** The exchange rate being offered */
  exchangeRate: ExchangeRate;
  /** the expiration time of the quote */
  expiration: Scalars['Time']['output'];
  /** The amount Meso will charge in USD for this Transfer. */
  mesoFee: Money;
  /** The amount Meso will charge for this Transfer. */
  mesoFeePresented: Money;
  /** Has the meso fee been waived, this is usually because a partner is subsidizing it */
  mesoFeeWaived: Scalars['Boolean']['output'];
  /** The amount the user is charged in USD for moving funds on chain */
  networkFee: Money;
  /** The estimated cost of moving funds on chain in the network token of the chain */
  networkFeeEstimate: Money;
  /** The amount the user is charged for moving funds on chain */
  networkFeePresented: Money;
  /** Has the network fee been waived */
  networkFeeWaived: Scalars['Boolean']['output'];
  /** Details about the asset being charged */
  sourceAsset: Asset;
  /** The source total without fees in USD. This is the amount of crypto that will be purchased for the Transfer. */
  sourceSubtotal: Money;
  /** The source total without fees. This is the amount of crypto that will be purchased for the Transfer. */
  sourceSubtotalPresented: Money;
  /** The total amount a user will be charged for a cash-in in USD. This is inclusive of fees. */
  sourceTotal: Money;
  /** The total amount a user will be charged for a cash-in. This is inclusive of fees. */
  sourceTotalPresented: Money;
};

export type AnonQuoteInput = {
  /**
   * A stringified amount, including decimals (based on the given `currency`).
   *
   * Examples: `"1"`, `"10"`, `"1.00"`, `"10.22"`, `"100.02"`
   *
   * One of sourceAmount or destAmount is required
   */
  destAmount?: InputMaybe<Scalars['String']['input']>;
  /** The CAIP-19 ID for the destination asset */
  destAsset: Scalars['String']['input'];
  /**
   * A stringified amount, including decimals (based on the given `currency`).
   *
   * One of sourceAmount or destAmount is required
   */
  sourceAmount?: InputMaybe<Scalars['String']['input']>;
  /** The CAIP-19 ID for the source asset */
  sourceAsset: Scalars['String']['input'];
};

export type AnonQuoteResult = AnonQuote | Errors;

export type ApplePayBillingContactInput = {
  addressLine1: Scalars['String']['input'];
  addressLine2?: InputMaybe<Scalars['String']['input']>;
  administrativeArea: Scalars['String']['input'];
  countryCode: Scalars['String']['input'];
  familyName: Scalars['String']['input'];
  givenName: Scalars['String']['input'];
  locality: Scalars['String']['input'];
  postalCode: Scalars['String']['input'];
  subAdministrativeArea?: InputMaybe<Scalars['String']['input']>;
  subLocality?: InputMaybe<Scalars['String']['input']>;
};

export type ApplePayInput = {
  billingContact: ApplePayBillingContactInput;
  shippingContact: ApplePayShippingContactInput;
  tokenData: ApplePayTokenDataInput;
};

/** An Apple Pay Payment Session, containing an opaque merchant session passed to the completeMerchantValidation method */
export type ApplePayPaymentSession = {
  __typename?: 'ApplePayPaymentSession';
  /** The opaque Apple Pay session object, serialized as JSON string */
  merchantSession: Scalars['String']['output'];
};

export type ApplePayPaymentSessionResult = ApplePayPaymentSession | Errors;

export type ApplePayShippingContactInput = {
  email: Scalars['String']['input'];
  familyName?: InputMaybe<Scalars['String']['input']>;
  givenName?: InputMaybe<Scalars['String']['input']>;
  phone: Scalars['String']['input'];
  phoneticFamilyName?: InputMaybe<Scalars['String']['input']>;
  phoneticGivenName?: InputMaybe<Scalars['String']['input']>;
};

export type ApplePayTokenDataInput = {
  data: Scalars['ID']['input'];
  ephemeralPublicKey: Scalars['ID']['input'];
  publicKeyHash: Scalars['ID']['input'];
  signature: Scalars['ID']['input'];
  transactionId: Scalars['ID']['input'];
  version: Scalars['String']['input'];
};

/** A token or currency that can bought or sold */
export type Asset = {
  __typename?: 'Asset';
  /** The contract address, if the asset is a token */
  address: Scalars['String']['output'];
  /** The number of decimals */
  decimals: Scalars['Int']['output'];
  /** The network identifier the asset lives on */
  network: Scalars['String']['output'];
  /** The symbol, eg USD */
  symbol: Scalars['String']['output'];
};

/** A banner message */
export type BannerMessage = {
  __typename?: 'BannerMessage';
  /** The body of the banner */
  body: Scalars['String']['output'];
  /** The id of the banner */
  id: Scalars['ID']['output'];
  /** The title of the banner */
  title: Scalars['String']['output'];
};

/** Wrapper for booleans that can be part of a union */
export type BooleanObject = {
  __typename?: 'BooleanObject';
  bool: Scalars['Boolean']['output'];
};

export type BooleanResult = BooleanObject | Errors;

export enum CardType {
  CHARGE = 'CHARGE',
  CREDIT = 'CREDIT',
  DEBIT = 'DEBIT',
  DEFERRED_DEBIT = 'DEFERRED_DEBIT',
  PREPAID = 'PREPAID',
  UNKNOWN = 'UNKNOWN'
}

/**
 * An intent to cash out, must be created before funds are sent
 * to the deposit address
 */
export type CashOutQuote = {
  __typename?: 'CashOutQuote';
  /** The address to where the USDC should be sent. */
  depositAddress: Scalars['String']['output'];
  /** The asset that will be sent to the fiat account */
  destAsset: Asset;
  /** The user's fiat instrument that will receive the funds */
  destInstrument: FiatInstrument;
  /**
   * The amount that will show up in the user's fiat account
   *
   * destination = sourceSubtotal - mesoFee
   */
  destination: Money;
  /** The exchange rate being offered */
  exchangeRate: ExchangeRate;
  /** the expiration time of the cash out */
  expiration: Scalars['Time']['output'];
  /** The identifier */
  id: Scalars['ID']['output'];
  /** The fee that meso will charge for the cash out */
  mesoFee: Money;
  /** The maximum amount of volume a user can have in a 30-day period */
  monthlyLimit: Money;
  /** The user's current rolling 30-day volume */
  monthlyUsage: Money;
  /** The user's wallet that will send the funds */
  senderInstrument: WalletInstrument;
  /** The asset that should be sent to the deposit address */
  sourceAsset: Asset;
  /** The source amount converted to the destination currency */
  sourceSubtotal: Money;
  /** The amount that should be sent to the deposit asset */
  sourceTotal: Money;
};

export type CashOutQuoteResult = CashOutQuote | Errors;

export type ChangePasswordInput = {
  /** The user's current password to verify */
  currentPassword: Scalars['String']['input'];
  /** The new password, must have a [zxcvbn](https://github.com/dropbox/zxcvbn) score of at least 3 */
  newPassword: Scalars['String']['input'];
};

export type CompletePasskeyLoginInput = {
  /** the ID of the passkey ceremony to consume for this login attempt */
  ceremonyId: Scalars['ID']['input'];
  /** a blob of JSON data that is returned from `navigator.credentials.get` */
  response: Scalars['String']['input'];
  /** The Meso-generated token representing the user's risk session. */
  riskSessionKey: Scalars['String']['input'];
};

export type CompletePasskeyRegistrationInput = {
  /** the ID of the passkey ceremony to consume for this registration */
  ceremonyId: Scalars['ID']['input'];
  /** a blob of JSON data that is returned from `navigator.credentials.create` */
  response: Scalars['String']['input'];
};

export type CreateAccountInput = {
  /** The user's email, must be unique */
  email: Scalars['String']['input'];
  /** The password, must have a [zxcvbn](https://github.com/dropbox/zxcvbn) score of at least 3 */
  password: Scalars['String']['input'];
};

export type CreateAnonCashOutQuoteInput = {
  /**
   * The CAIP-19 ID for the destination asset. Currently only USD is supported.
   *
   * See: https://github.com/ChainAgnostic/CAIPs/blob/main/CAIPs/caip-19.md
   */
  destinationAsset: Scalars['String']['input'];
  /**
   * A stringified amount, including decimals (based on the given `currency`).
   *
   * Examples: `"1"`, `"10"`, `"1.00"`, `"10.22"`, `"100.02"`
   */
  sourceAmount: Scalars['String']['input'];
  /**
   * The CAIP-19 ID for the source asset. Currently only USDC is supported on
   * any chain that meso supports.
   *
   * See: https://github.com/ChainAgnostic/CAIPs/blob/main/CAIPs/caip-19.md
   */
  sourceAsset: Scalars['String']['input'];
};

export type CreateCashOutQuoteInput = {
  /**
   * The CAIP-19 ID for the destination asset. Currently only USD is supported.
   *
   * See: https://github.com/ChainAgnostic/CAIPs/blob/main/CAIPs/caip-19.md
   */
  destinationAsset: Scalars['String']['input'];
  /** The financial instrument ID that will recieve the fiat */
  destinationInstrumentId: Scalars['ID']['input'];
  /** The risk session key */
  riskSessionKey: Scalars['String']['input'];
  /** The financial instrument ID of the wallet that will send the funds */
  senderInstrumentId: Scalars['ID']['input'];
  /**
   * A stringified amount, including decimals (based on the given `currency`).
   *
   * Examples: `"1"`, `"10"`, `"1.00"`, `"10.22"`, `"100.02"`
   */
  sourceAmount: Scalars['String']['input'];
  /**
   * The CAIP-19 ID for the source asset. Currently only USDC is supported on
   * any chain that meso supports.
   *
   * See: https://github.com/ChainAgnostic/CAIPs/blob/main/CAIPs/caip-19.md
   */
  sourceAsset: Scalars['String']['input'];
};

export type CreateThreeDomainSecureSessionInput = {
  /** the amount to secure */
  amount: Scalars['String']['input'];
  /**
   * The CAIP-19 ID for the source asset, eg fiat:x/iso4217:USD
   *
   * one of asset or currencyCode is required
   */
  asset?: InputMaybe<Scalars['String']['input']>;
  /** the value of `screen.colorDepth` */
  colorDepth: Scalars['String']['input'];
  /**
   * The meso currency code of the amount to secure, eg fiat/USD
   *
   * one of asset or currencyCode is required
   */
  currencyCode?: InputMaybe<Scalars['String']['input']>;
  /** the instrument to perform 3DS upon */
  financialInstrumentId: Scalars['ID']['input'];
  /** the financial partner from the quote */
  financialPartner?: InputMaybe<FinancialPartner>;
  /** the value of `navigator.javaEnabled()` */
  javaEnabled: Scalars['Boolean']['input'];
  /** the value of `naviator.language` */
  language: Scalars['String']['input'];
  /** the value of `screen.height` */
  screenHeight: Scalars['String']['input'];
  /** the value of `screen.width` */
  screenWidth: Scalars['String']['input'];
  /** the value of `new Date().getTimezoneOffset()` */
  timezoneOffset: Scalars['String']['input'];
};

export type CreateTicketIdentityDocUploadUrlInput = {
  /** the zendesk ticket it should be attached to */
  ticketId: Scalars['Int']['input'];
  /** the user to request and ID doc for */
  userId: Scalars['ID']['input'];
};

export type CreateUserFeedbackInput = {
  /** the contents of the feedback */
  content: Scalars['String']['input'];
  /** the kind of feedback */
  kind: Scalars['String']['input'];
  /** the optional rating */
  rating?: InputMaybe<Scalars['Int']['input']>;
  /** the optional transfer ID for this feedback */
  transferId?: InputMaybe<Scalars['ID']['input']>;
};

export enum DeclineReason {
  BANK_DECLINE = 'BANK_DECLINE',
  CARD_EXPIRED = 'CARD_EXPIRED',
  MONTHLY_LIMIT = 'MONTHLY_LIMIT',
  PAYMENT_DECLINE = 'PAYMENT_DECLINE',
  RISK_DECLINE = 'RISK_DECLINE',
  UNKNOWN = 'UNKNOWN'
}

/** A Financial Instrument representing a deposit address. */
export type DepositAddressInstrument = {
  __typename?: 'DepositAddressInstrument';
  /** The address the user can send "asset" to cash out */
  address: Scalars['String']['output'];
  /**
   * The asset that will be accepted by the deposit address
   *
   * asset.network is the specific network this deposit address lives on.
   */
  asset: Asset;
  /** when the instrument was created */
  createdAt: Scalars['Time']['output'];
  /** The unique public ID for this instrument. */
  id: Scalars['ID']['output'];
  /** The instrument kind, will always be DEPOSIT_ADDRESS */
  kind: InstrumentKind;
  /**
   * The namespace portion of the [CAIP-2](https://github.com/ChainAgnostic/CAIPs/blob/master/CAIPs/caip-2.md) formatted Chain ID.
   *
   * eip155 -> evm wallet
   * solana -> solana wallet
   */
  networkNamespace: Scalars['String']['output'];
};

/** The collection of deposit address instruments for a `User`. */
export type DepositAddressInstruments = {
  __typename?: 'DepositAddressInstruments';
  collection: Array<DepositAddressInstrument>;
};

export type DepositAddressInstrumentsResult = DepositAddressInstruments | Errors;

/** A domain-level error suitable for presenting to an end-user */
export type Error = {
  __typename?: 'Error';
  /** a unique error code describing the failure, eg. login_failed */
  code: Scalars['String']['output'];
  /** the English error message */
  enMessage: Scalars['String']['output'];
  /**
   * an optional help url for more information about how the user can
   * correct the error
   */
  helpUrl?: Maybe<Scalars['String']['output']>;
};

/** A collection of domain-level errors */
export type Errors = {
  __typename?: 'Errors';
  errors: Array<Error>;
};

/** Represents an exchange rate as Quote/Base */
export type ExchangeRate = {
  __typename?: 'ExchangeRate';
  /** The [CAIP-19](https://github.com/ChainAgnostic/CAIPs/blob/master/CAIPs/caip-19.md) asset code for the base asset (denominator) */
  base: Scalars['String']['output'];
  /** The [CAIP-19](https://github.com/ChainAgnostic/CAIPs/blob/master/CAIPs/caip-19.md) asset code for the quote asset (numerator) */
  quote: Scalars['String']['output'];
  /** the amount of quote asset you get for 1 base asset */
  rate: Scalars['String']['output'];
};

export type ExecuteTransferInput = {
  /** Optional Apple Pay paramters for quotes with deferred source instrument of APPLE_PAY */
  applePay?: InputMaybe<ApplePayInput>;
  /** The device session id from Checkout's risk.js */
  deviceSessionId?: InputMaybe<Scalars['String']['input']>;
  /** The JWT received from a `Quote`. */
  jwt: Scalars['String']['input'];
  /** Optional name of an available rewards program */
  rewardsProgram?: InputMaybe<Scalars['String']['input']>;
  /** The Meso-generated token representing the user's risk session. */
  riskSessionKey: Scalars['String']['input'];
  /** 3DS information, if available */
  threeDomainSecure?: InputMaybe<ThreeDomainSecureInput>;
};

/** A Financial Instrument representing a fiat currency. */
export type FiatInstrument = {
  __typename?: 'FiatInstrument';
  /** The card scheme if fiat instrument is a card */
  cardScheme: Scalars['String']['output'];
  /** The card type if fiat instrument represents a card. */
  cardType: CardType;
  /** when the instrument was created */
  createdAt: Scalars['Time']['output'];
  /** A client-friendly display name for the instrument. */
  display: Scalars['String']['output'];
  /** The unique public ID for this instrument. */
  id: Scalars['ID']['output'];
  kind: InstrumentKind;
  /** The masked number */
  maskedNumber: Scalars['String']['output'];
  /** Whether the fiat instrument can support cash-outs */
  payoutEligible: Scalars['Boolean']['output'];
  /**
   * Do transfers with this instrument require a 3DS session?
   * true => yes, false or null => no
   */
  requires3ds?: Maybe<Scalars['Boolean']['output']>;
};

/** The collection of fiat instruments for a `User`. */
export type FiatInstruments = {
  __typename?: 'FiatInstruments';
  collection: Array<FiatInstrument>;
};

export type FiatInstrumentsResult = Errors | FiatInstruments;

/** An instrument that can be the source or destination of funds */
export type FinancialInstrument = DepositAddressInstrument | FiatInstrument | WalletInstrument;

export enum FinancialPartner {
  MESO_SPZOO = 'MESO_SPZOO',
  UNKNOWN = 'UNKNOWN',
  ZERO_HASH = 'ZERO_HASH'
}

/** Search to find a user. If `id` provider, it will be used, otherwise email will be used */
export type FindUser = {
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
};

export enum FrontendDeclineReason {
  BANK_DECLINE = 'BANK_DECLINE',
  CARD_EXPIRED = 'CARD_EXPIRED',
  OTHER = 'OTHER'
}

/** A document that a user provided to verify their identity */
export type IdentityDocument = {
  __typename?: 'IdentityDocument';
  id: Scalars['ID']['output'];
  kind: Scalars['String']['output'];
  riskLevel: Scalars['String']['output'];
  verificationId: Scalars['ID']['output'];
};

/** A link to a site to upload identity documents */
export type IdentityDocumentUrl = {
  __typename?: 'IdentityDocumentUrl';
  /** Has this URL been consumed */
  consumed: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  /** The URL the user should be redirected to */
  url: Scalars['String']['output'];
};

export type IdentityDocumentUrlResult = Errors | IdentityDocumentUrl;

export type IdentityDocuments = {
  __typename?: 'IdentityDocuments';
  collection: Array<IdentityDocument>;
};

export type IdentityDocumentsResult = Errors | IdentityDocuments;

export enum InstrumentKind {
  APPLE_PAY = 'APPLE_PAY',
  DEPOSIT_ADDRESS = 'DEPOSIT_ADDRESS',
  PAYMENT_CARD = 'PAYMENT_CARD',
  UNKNOWN = 'UNKNOWN',
  WALLET = 'WALLET'
}

/** A step requested by the risk system to reduce Meso's risk exposure */
export type Intervention = {
  __typename?: 'Intervention';
  createdAt: Scalars['Time']['output'];
  id: Scalars['ID']['output'];
  kind: Scalars['String']['output'];
  status: Scalars['String']['output'];
  ticketId?: Maybe<Scalars['Int']['output']>;
  triggeredByRuleIds: Array<Scalars['String']['output']>;
  updatedAt: Scalars['Time']['output'];
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export enum InterventionStatus {
  MITIGATED = 'MITIGATED',
  PENDING = 'PENDING',
  UNMITIGATED = 'UNMITIGATED'
}

export type Interventions = {
  __typename?: 'Interventions';
  collection: Array<Intervention>;
};

export type InterventionsResult = Errors | Interventions;

/** The result of a successful login. */
export type Login = {
  __typename?: 'Login';
  /** Whether the user needs to perform 2FA. */
  needsTwoFactor: Scalars['Boolean']['output'];
  profileStatus: ProfileStatus;
  /** The status for the user, refer to User.status */
  userStatus: Scalars['String']['output'];
};

/** Provides the message to be signed by the user's wallet to proceed with the `Transfer` flow. */
export type LoginMessage = {
  __typename?: 'LoginMessage';
  /** The address of the wallet that will be used to sign a login message. */
  address: Scalars['String']['output'];
  /** The kind of message to generate, determines the call that needs to be made to the wallet */
  kind?: Maybe<LoginMessageKind>;
  /** A unique, Meso-generated identifier for the auth message to be signed. */
  messageId: Scalars['ID']['output'];
  /** A message to be signed by the user's wallet. */
  messageToSign: Scalars['String']['output'];
  /** The [CAIP-2](https://github.com/ChainAgnostic/CAIPs/blob/master/CAIPs/caip-2.md) formatted ID. */
  network: Scalars['String']['output'];
};

export type LoginMessageInput = {
  /** The address of the wallet that will be used to sign a login message. */
  address: Scalars['String']['input'];
  /** The kind of message to generate, defaults to TEXT */
  kind?: InputMaybe<LoginMessageKind>;
  /** The [CAIP-2](https://github.com/ChainAgnostic/CAIPs/blob/master/CAIPs/caip-2.md) formatted ID. */
  network: Scalars['String']['input'];
};

export enum LoginMessageKind {
  ERC712 = 'ERC712',
  TEXT = 'TEXT',
  UNKNOWN = 'UNKNOWN'
}

export type LoginMessageResult = Errors | LoginMessage;

export type LoginResult = Errors | Login;

/** Represents a fiat or crypto value. */
export type Money = {
  __typename?: 'Money';
  /**
   * A stringified amount, including decimals (based on the given `currency`).
   *
   * Examples: `"1"`, `"10"`, `"1.00"`, `"10.22"`, `"100.02"`
   */
  amount: Scalars['String']['output'];
  /** The symbol of the currency. This is the token's symbol or the ISO-4217 code for Fiat amounts. */
  currency: Scalars['String']['output'];
};

/** The top-level Mutation operations. */
export type Mutation = {
  __typename?: 'Mutation';
  /** Provide acceptance of the Meso terms. */
  acceptMesoTerms?: Maybe<ProfileStatusResult>;
  /** Provide acceptance of the Zero Hash terms. */
  acceptZeroHashTerms?: Maybe<ProfileStatusResult>;
  /** Provide acknowledgement of the scam warning. */
  acknowledgeScamWarning?: Maybe<ProfileStatusResult>;
  /** Called by the frontend when the onboarding is complete. The returned user activation should be polled until it reaches a terminal state */
  activate?: Maybe<ActivationResult>;
  addBasicInfo?: Maybe<ProfileStatusResult>;
  /** Provide the citizenship of the user. */
  addCitizenship?: Maybe<ProfileStatusResult>;
  /** Adds a payment card (debit-only) to the user's signup. Will verify the card using the payment network */
  addPaymentCard?: Maybe<ProfileStatusResult>;
  /** Provide the PESEL of the user */
  addPesel?: Maybe<ProfileStatusResult>;
  addPhoneNumber?: Maybe<ProfileStatusResult>;
  /** Provide the residence country of the user */
  addResidenceCountry?: Maybe<ProfileStatusResult>;
  addResidentialAddress?: Maybe<ProfileStatusResult>;
  /** Provide a Basis Theory generated token representing the user's full tax ID (SSN) including dashes. */
  addTaxpayerIdFull?: Maybe<ProfileStatusResult>;
  /** Provide the last 4 digits of a user's tax ID (SSN). */
  addTaxpayerIdLast4?: Maybe<ProfileStatusResult>;
  /** Adds another wallet to a user */
  addUserWallet?: Maybe<ProfileStatusResult>;
  /**
   * Creates a passkey ceremony for performing the discoverable login
   * flow
   */
  beginPasskeyLogin?: Maybe<PasskeyCeremonyResult>;
  /** Begins a passkey registration */
  beginPasskeyRegistration?: Maybe<PasskeyCeremonyResult>;
  /**
   * Verifies user's current password before updating to new password
   *
   * Returns errors:
   * - password_reused
   */
  changePassword?: Maybe<BooleanResult>;
  /**
   * Performs a login with a discoverable login input from a passkey. If login
   * is successful, the user will have 2 auth factors.
   *
   * Returns errors:
   * - login_failed
   */
  completePasskeyLogin?: Maybe<LoginResult>;
  /** Registers the passkey device with the backend as a login method */
  completePasskeyRegistration?: Maybe<BooleanResult>;
  /** Creates the user's account login */
  createAccount?: Maybe<ProfileStatusResult>;
  /** Creates an anonymous quote for cash out. Cannot be executed. Fee tier is estimated */
  createAnonCashOutQuote?: Maybe<AnonCashOutQuoteResult>;
  /** Creates a quote to cash out. Required before sending funds to the deposit address */
  createCashOutQuote?: Maybe<CashOutQuoteResult>;
  /** Create a new identity document URL and return it */
  createIdentityDocumentUrl?: Maybe<IdentityDocumentUrlResult>;
  /** Creates a 3DS session for the unhosted flow */
  createThreeDomainSecureSession?: Maybe<ThreeDomainSecureSessionResult>;
  /** Creates a identity document upload URL and attaches it to the provided ticket */
  createTicketIdentityDocUploadURL?: Maybe<BooleanResult>;
  /** Create a user feedback. */
  createUserFeedback?: Maybe<BooleanResult>;
  /**
   * Executes a quote, can only be called by active users
   *
   * Returns errors:
   * - quote_already_executed
   */
  executeTransfer?: Maybe<TransferResult>;
  /** Export transfers */
  exportTransfers?: Maybe<TransfersExportResult>;
  /** Logout the current session */
  logout?: Maybe<BooleanResult>;
  /** Create a new session */
  newSession?: Maybe<NewSessionResult>;
  /**
   * Performs login with an email and password. Will automatically send an SMS
   * verification to user.
   *
   * Returns errors:
   * - login_failed
   */
  performEmailPasswordLogin?: Maybe<LoginResult>;
  /**
   * Performs login with an email magic link. Will automatically send an SMS
   * verification to user.
   *
   * Returns errors:
   * - login_failed
   */
  performMagicLinkLogin?: Maybe<LoginResult>;
  /**
   * Performs login by verifying a `loginMessage` that has been signed by a wallet
   * that the user has created in Meso. Will automatically send an SMS verification to the user.
   *
   * Returns errors:
   * - login_failed
   * - invalid_signature
   */
  performWalletLogin?: Maybe<LoginResult>;
  /**
   * Removes the given fiat instrument
   *
   * Returns errors:
   * - unknown_fiat_instrument
   */
  removeFiatInstrument?: Maybe<BooleanResult>;
  /**
   * Removes a wallet from a user
   *
   * Returns errors:
   * - unknown_wallet
   */
  removeUserWallet?: Maybe<BooleanResult>;
  /** Request a valid merchant session from the Apple Pay server */
  requestApplePayPaymentSession?: Maybe<ApplePayPaymentSessionResult>;
  /**
   * Requests a user to reset their password given their email. Will give
   * no indication if the email is unknown or not.
   */
  requestPasswordReset?: Maybe<BooleanResult>;
  /** Resend an email verification email */
  resendEmailVerification?: Maybe<BooleanResult>;
  /** Trigger a new 2FA code to be sent to the user. */
  resendTwoFactor?: Maybe<BooleanResult>;
  /**
   * Resets a user's password, must not be equal to the current password.
   * User must have logged in via the reset password flow
   *
   * Returns errors:
   * - password_reused
   * - unauthorized_password_reset
   */
  resetPassword?: Maybe<BooleanResult>;
  /** Allows agents to resolve risk interventions from the ZenDesk interface */
  resolveInterventions?: Maybe<InterventionsResult>;
  /** Returns information for the currently logged in user */
  self?: Maybe<UserResult>;
  /** Sets the user's theme, can only be called by active users */
  setTheme?: Maybe<BooleanResult>;
  /** Creates a 3DS session for the unhosted flow */
  setThreeDomainSecureSessionMethodCompletion?: Maybe<ThreeDomainSecureSessionResult>;
  /** Allows completing onboarding without providing a fiat instrument */
  skipFiatInstrument?: Maybe<ProfileStatusResult>;
  /**
   * Will create a new user and wallet.
   *
   * Returns errors:
   * - invalid_wallet_address
   * - invalid_wallet_network
   * - user_already_signed_up
   */
  startOnboarding?: Maybe<ProfileStatusResult>;
  /** Simulates a document received webhook for sandbox/testing */
  stubDocumentReceivedWebhook?: Maybe<BooleanResult>;
  /**
   * Updates a user's password, must not be equal to the current password
   *
   * Returns errors:
   * - password_reused
   */
  updatePassword?: Maybe<BooleanResult>;
  /**
   * Allows agents to freeze / unfreeze / close / close_risk users from the
   * Zendesk interface
   */
  updateUserStatus?: Maybe<UserResult>;
  /** Verify a user's email using a code from the email body */
  verifyEmail?: Maybe<BooleanResult>;
  verifyPhoneNumber?: Maybe<ProfileStatusResult>;
  /** Provide a 2FA code for authentication. */
  verifyTwoFactor?: Maybe<BooleanResult>;
  /** Withdrawals the user's signup application and marks the user declined */
  withdrawalSignup?: Maybe<BooleanResult>;
};


/** The top-level Mutation operations. */
export type MutationAcceptMesoTermsArgs = {
  input: AcceptMesoTermsInput;
};


/** The top-level Mutation operations. */
export type MutationActivateArgs = {
  input: ActivateInput;
};


/** The top-level Mutation operations. */
export type MutationAddBasicInfoArgs = {
  input: AddBasicInfoInput;
};


/** The top-level Mutation operations. */
export type MutationAddCitizenshipArgs = {
  input: AddCitizenshipInput;
};


/** The top-level Mutation operations. */
export type MutationAddPaymentCardArgs = {
  input: AddPaymentCardInput;
};


/** The top-level Mutation operations. */
export type MutationAddPeselArgs = {
  input: AddPeselInput;
};


/** The top-level Mutation operations. */
export type MutationAddPhoneNumberArgs = {
  input: AddPhoneNumberInput;
};


/** The top-level Mutation operations. */
export type MutationAddResidenceCountryArgs = {
  input: AddResidenceCountryInput;
};


/** The top-level Mutation operations. */
export type MutationAddResidentialAddressArgs = {
  input: AddResidentialAddressInput;
};


/** The top-level Mutation operations. */
export type MutationAddTaxpayerIdFullArgs = {
  input: AddTaxpayerIdFullInput;
};


/** The top-level Mutation operations. */
export type MutationAddTaxpayerIdLast4Args = {
  input: AddTaxpayerIdLast4Input;
};


/** The top-level Mutation operations. */
export type MutationAddUserWalletArgs = {
  input: AddUserWalletInput;
};


/** The top-level Mutation operations. */
export type MutationChangePasswordArgs = {
  input: ChangePasswordInput;
};


/** The top-level Mutation operations. */
export type MutationCompletePasskeyLoginArgs = {
  input: CompletePasskeyLoginInput;
};


/** The top-level Mutation operations. */
export type MutationCompletePasskeyRegistrationArgs = {
  input: CompletePasskeyRegistrationInput;
};


/** The top-level Mutation operations. */
export type MutationCreateAccountArgs = {
  input: CreateAccountInput;
};


/** The top-level Mutation operations. */
export type MutationCreateAnonCashOutQuoteArgs = {
  input: CreateAnonCashOutQuoteInput;
};


/** The top-level Mutation operations. */
export type MutationCreateCashOutQuoteArgs = {
  input: CreateCashOutQuoteInput;
};


/** The top-level Mutation operations. */
export type MutationCreateThreeDomainSecureSessionArgs = {
  input: CreateThreeDomainSecureSessionInput;
};


/** The top-level Mutation operations. */
export type MutationCreateTicketIdentityDocUploadUrlArgs = {
  input: CreateTicketIdentityDocUploadUrlInput;
};


/** The top-level Mutation operations. */
export type MutationCreateUserFeedbackArgs = {
  input: CreateUserFeedbackInput;
};


/** The top-level Mutation operations. */
export type MutationExecuteTransferArgs = {
  input: ExecuteTransferInput;
};


/** The top-level Mutation operations. */
export type MutationNewSessionArgs = {
  input: NewSessionInput;
};


/** The top-level Mutation operations. */
export type MutationPerformEmailPasswordLoginArgs = {
  input: PerformEmailPasswordLoginInput;
};


/** The top-level Mutation operations. */
export type MutationPerformMagicLinkLoginArgs = {
  input: PerformMagicLinkLoginInput;
};


/** The top-level Mutation operations. */
export type MutationPerformWalletLoginArgs = {
  input: PerformWalletLoginInput;
};


/** The top-level Mutation operations. */
export type MutationRemoveFiatInstrumentArgs = {
  input?: InputMaybe<RemoveFiatInstrumentInput>;
};


/** The top-level Mutation operations. */
export type MutationRemoveUserWalletArgs = {
  input: RemoveUserWalletInput;
};


/** The top-level Mutation operations. */
export type MutationRequestApplePayPaymentSessionArgs = {
  input: RequestApplePayPaymentSessionInput;
};


/** The top-level Mutation operations. */
export type MutationRequestPasswordResetArgs = {
  input: RequestPasswordResetInput;
};


/** The top-level Mutation operations. */
export type MutationResendEmailVerificationArgs = {
  input: ResendEmailVerificationInput;
};


/** The top-level Mutation operations. */
export type MutationResendTwoFactorArgs = {
  input: ResendTwoFactorInput;
};


/** The top-level Mutation operations. */
export type MutationResetPasswordArgs = {
  input: ResetPasswordInput;
};


/** The top-level Mutation operations. */
export type MutationResolveInterventionsArgs = {
  input: ResolveInterventionsInput;
};


/** The top-level Mutation operations. */
export type MutationSetThemeArgs = {
  input: Scalars['String']['input'];
};


/** The top-level Mutation operations. */
export type MutationSetThreeDomainSecureSessionMethodCompletionArgs = {
  input: SetThreeDomainSecureSessionMethodCompletionInput;
};


/** The top-level Mutation operations. */
export type MutationStartOnboardingArgs = {
  input: StartOnboardingInput;
};


/** The top-level Mutation operations. */
export type MutationStubDocumentReceivedWebhookArgs = {
  input: StubDocumentReceivedWebhookInput;
};


/** The top-level Mutation operations. */
export type MutationUpdatePasswordArgs = {
  input: UpdatePasswordInput;
};


/** The top-level Mutation operations. */
export type MutationUpdateUserStatusArgs = {
  input: UpdateUserStatusInput;
};


/** The top-level Mutation operations. */
export type MutationVerifyEmailArgs = {
  input: VerifyEmailInput;
};


/** The top-level Mutation operations. */
export type MutationVerifyPhoneNumberArgs = {
  input: VerifyPhoneNumberInput;
};


/** The top-level Mutation operations. */
export type MutationVerifyTwoFactorArgs = {
  input: VerifyTwoFactorInput;
};


/** The top-level Mutation operations. */
export type MutationWithdrawalSignupArgs = {
  input: WithdrawalSignupInput;
};

/** Create a new session */
export type NewSessionInput = {
  /** The [CAIP-2](https://github.com/ChainAgnostic/CAIPs/blob/master/CAIPs/caip-2.md) formatted ID. */
  network?: InputMaybe<Scalars['String']['input']>;
  /** The partner's ID */
  partnerId: Scalars['String']['input'];
  /** The optional wallet address */
  walletAddress?: InputMaybe<Scalars['String']['input']>;
};

export type NewSessionResult = Errors | Session;

/** Represents a Partner */
export type Partner = {
  __typename?: 'Partner';
  /** The display name */
  displayName: Scalars['String']['output'];
  /** The ID */
  id: Scalars['ID']['output'];
  /** The logo URI */
  logoUri: Scalars['String']['output'];
};

/** A passkey ceremony, can only be used once */
export type PasskeyCeremony = {
  __typename?: 'PasskeyCeremony';
  /** its ID */
  id: Scalars['ID']['output'];
  /** what is the ceremony created for: registration or login */
  kind: PasskeyCeremonyKind;
  /** a blob of JSON data that should be passed to `navigator.credentials.create` or `navigator.credentials.get` */
  request: Scalars['String']['output'];
};

export enum PasskeyCeremonyKind {
  LOGIN = 'LOGIN',
  REGISTRATION = 'REGISTRATION',
  UNKNOWN = 'UNKNOWN'
}

export type PasskeyCeremonyResult = Errors | PasskeyCeremony;

export type PerformEmailPasswordLoginInput = {
  /** The user's email */
  email: Scalars['String']['input'];
  /** The user's password */
  password: Scalars['String']['input'];
  /** The Meso-generated token representing the user's risk session. */
  riskSessionKey: Scalars['String']['input'];
};

export type PerformMagicLinkLoginInput = {
  /** Code to confirm the mail */
  code: Scalars['String']['input'];
  /** Email that will be confirmed */
  email: Scalars['String']['input'];
  /** The Meso-generated token representing the user's risk session. */
  riskSessionKey: Scalars['String']['input'];
};

export type PerformWalletLoginInput = {
  /** The address of the wallet that will be used to sign a login message. */
  address: Scalars['String']['input'];
  /** The [CAIP-2](https://github.com/ChainAgnostic/CAIPs/blob/master/CAIPs/caip-2.md) formatted ID. */
  network: Scalars['String']['input'];
  /** The Meso-generated token representing the user's risk session. */
  riskSessionKey: Scalars['String']['input'];
  /** An opaque signed message returned from the wallet. */
  signature: Scalars['String']['input'];
};

export enum ProfileFieldStatus {
  /** If the field is not required */
  NOT_REQUIRED = 'NOT_REQUIRED',
  /** If the field has been provided */
  PROVIDED = 'PROVIDED',
  /** If the field is required and not provided */
  REQUIRED = 'REQUIRED',
  /** This is unused and only needed as an enum default */
  UNKNOWN = 'UNKNOWN'
}

/** The progress of a user through the onboarding flow. */
export type ProfileStatus = {
  __typename?: 'ProfileStatus';
  /** Whether the user has accepted the Meso agreements. */
  acceptedMesoTerms: Scalars['Boolean']['output'];
  /** Whether the user has accepted the ZeroHash agreements. */
  acceptedZeroHashTerms: Scalars['Boolean']['output'];
  /** Whether the user has created an account */
  account: Scalars['Boolean']['output'];
  /** Whether the user has acknowledged the scam warning. */
  acknowledgedScamWarning: Scalars['Boolean']['output'];
  /** Whether the user has provided basic KYC info (name, dob) */
  basicInfo: Scalars['Boolean']['output'];
  /** Whether the user has specified their citizenship */
  citizenship: Scalars['Boolean']['output'];
  /** Whether the user has onboarded a fiat instrument. */
  financialInstrument: Scalars['Boolean']['output'];
  /** Whether the user has provided identity documents, */
  identityDocuments: Scalars['Boolean']['output'];
  /** The status of the user's PESEL */
  pesel: ProfileFieldStatus;
  /** Whether the user has stored a phone number. */
  phone: Scalars['Boolean']['output'];
  /** Whether the user has completed the phone verification (2fa) step. */
  phoneVerified: Scalars['Boolean']['output'];
  /** An [ISO-3166](https://en.wikipedia.org/wiki/ISO_3166) 2 character country code. */
  residenceCountry?: Maybe<Scalars['String']['output']>;
  /** Whether the user has provided their address for KYC. */
  residentialAddress: Scalars['Boolean']['output'];
  /** Whether the user has provided a valid SSN. Will always be true for non-US users */
  taxpayerId: Scalars['Boolean']['output'];
};

export type ProfileStatusResult = Errors | ProfileStatus;

/** The top-level Query operations. */
export type Query = {
  __typename?: 'Query';
  /** Fetch an anonymous quote */
  anonQuote?: Maybe<AnonQuoteResult>;
  /** Get the current state of a 3DS session */
  getThreeDomainSecureSession?: Maybe<ThreeDomainSecureSessionResult>;
  /** Fetch an identity document URL if one exists */
  identityDocumentUrl?: Maybe<IdentityDocumentUrlResult>;
  /** Retrieve a login message to be signed by the user's wallet for authentication. */
  loginMessage?: Maybe<LoginMessageResult>;
  /** Fetch the partner for the current session */
  partner?: Maybe<Partner>;
  /** Retrieve the user's onboarding status. */
  profileStatus?: Maybe<ProfileStatusResult>;
  /** Fetch a quote for a `Transfer`. */
  quote?: Maybe<QuoteResult>;
  /** Fetch details of the user's risk session. */
  riskSession?: Maybe<RiskSessionResult>;
  /** Fetch details of a `Transfer`. */
  transfer?: Maybe<TransferResult>;
  /** Fetch all transfers. */
  transfers?: Maybe<TransfersResult>;
  user?: Maybe<UserResult>;
  /** Fetch the user activation */
  userActivation?: Maybe<UserActivationResult>;
};


/** The top-level Query operations. */
export type QueryAnonQuoteArgs = {
  input: AnonQuoteInput;
};


/** The top-level Query operations. */
export type QueryGetThreeDomainSecureSessionArgs = {
  id: Scalars['ID']['input'];
};


/** The top-level Query operations. */
export type QueryLoginMessageArgs = {
  input: LoginMessageInput;
};


/** The top-level Query operations. */
export type QueryQuoteArgs = {
  input: QuoteInput;
};


/** The top-level Query operations. */
export type QueryTransferArgs = {
  id: Scalars['ID']['input'];
};


/** The top-level Query operations. */
export type QueryUserArgs = {
  input: FindUser;
};


/** The top-level Query operations. */
export type QueryUserActivationArgs = {
  id: Scalars['ID']['input'];
};

/** A returned price quote */
export type Quote = {
  __typename?: 'Quote';
  /** Details about the asset being received */
  destAsset: Asset;
  /** The finanical instrument that will receive funds */
  destInstrument: FinancialInstrument;
  /** The amount of crypto the user will be sent. */
  destination: Money;
  /** The exchange rate being offered */
  exchangeRate: ExchangeRate;
  /** the expiration time of the quote */
  expiration: Scalars['Time']['output'];
  /** the financial partner facilitating the transfer */
  financialPartner: FinancialPartner;
  /** The executable jwt of the quote */
  jwt: Scalars['String']['output'];
  /** The amount Meso will charge for this Transfer. */
  mesoFee: Money;
  /** The amount Meso will charge for this Transfer. */
  mesoFeePresented: Money;
  /** Has the meso fee been waived, this is usually because a partner is subsidizing it */
  mesoFeeWaived: Scalars['Boolean']['output'];
  /** The maximum amount of volume a user can have in a 30-day period, in USD */
  monthlyLimit: Money;
  /** The maximum amount of volume a user can have in a 30-day period, in the presented currency */
  monthlyLimitPresented: Money;
  /** The user's current rolling 30-day volume */
  monthlyUsage: Money;
  /** The user's current rolling 30-day volume, in the presented currency */
  monthlyUsagePresented: Money;
  /** The amount the user is charged for moving funds on chain in USD */
  networkFee: Money;
  /** The estimated cost of moving funds on chain in the network token of the chain */
  networkFeeEstimate: Money;
  /** The amount the user is charged for moving funds on chain */
  networkFeePresented: Money;
  /** Has the network fee been waived */
  networkFeeWaived: Scalars['Boolean']['output'];
  /** Details about the asset being charged */
  sourceAsset: Asset;
  /** The financial instrument that will be charged */
  sourceInstrument: FinancialInstrument;
  /** The source total without fees. This is the amount of crypto that will be purchased for the Transfer. */
  sourceSubtotal: Money;
  /** The source total without fees in USD. This is the amount of crypto that will be purchased for the Transfer. */
  sourceSubtotalPresented: Money;
  /** The total amount a user will be charged for a cash-in in USD. This is inclusive of fees. */
  sourceTotal: Money;
  /** The total amount a user will be charged for a cash-in. This is inclusive of fees. */
  sourceTotalPresented: Money;
};

export type QuoteInput = {
  /** A single use insturment to be provided later with the execute call */
  deferredSourceInstrument?: InputMaybe<SingleUseInstrument>;
  /**
   * A stringified amount, including decimals (based on the given `currency`).
   *
   * Examples: `"1"`, `"10"`, `"1.00"`, `"10.22"`, `"100.02"`
   *
   * One of sourceAmount or destAmount is required
   */
  destAmount?: InputMaybe<Scalars['String']['input']>;
  /**
   * The CAIP-19 ID for the destination asset
   *
   * See sourceAsset for more details
   */
  destAsset: Scalars['String']['input'];
  /** The unique, Meso-generated identifier for the financial instrument to be used. */
  destInstrumentId: Scalars['ID']['input'];
  /**
   * Optional external identifier for the transfer. Should be unique for
   * each transfer.
   */
  externalId?: InputMaybe<Scalars['ID']['input']>;
  /**
   * A stringified amount, including decimals (based on the given `currency`).
   *
   * Examples: `"1"`, `"10"`, `"1.00"`, `"10.22"`, `"100.02"`
   *
   * One of sourceAmount or destAmount is required
   */
  sourceAmount?: InputMaybe<Scalars['String']['input']>;
  /**
   * The CAIP-19 ID for the source asset
   *
   * See: https://github.com/ChainAgnostic/CAIPs/blob/main/CAIPs/caip-19.md
   *
   * For fiat currencies, CAIP-19 has been extended. Use the pattern
   * fiat:x/iso4217:<alphabetic code>, eg fiat:x/iso4217:USD
   *
   * See: https://en.wikipedia.org/wiki/ISO_4217
   */
  sourceAsset: Scalars['String']['input'];
  /**
   * The unique, Meso-generated identifier for the financial instrument to be used.
   *
   * If not provided, a deferredSourceInstrument must be provided
   */
  sourceInstrumentId?: InputMaybe<Scalars['ID']['input']>;
};

export type QuoteResult = Errors | Quote;

export type RemoveFiatInstrumentInput = {
  /** The id of the instrument to remove */
  id: Scalars['ID']['input'];
};

export type RemoveUserWalletInput = {
  /** The public key for the wallet to remove */
  walletAddress: Scalars['String']['input'];
};

export type RequestApplePayPaymentSessionInput = {
  /** the host for the top-level window where the Apple Pay Session is being created */
  initiativeContext: Scalars['String']['input'];
};

export type RequestPasswordResetInput = {
  /** The email of the user who's password will be reset */
  email: Scalars['String']['input'];
};

/** Input for resending an email verification */
export type ResendEmailVerificationInput = {
  /** The expired code that should be refreshed */
  code: Scalars['String']['input'];
};

export type ResendTwoFactorInput = {
  method: TwoFactorMethod;
};

export type ResetPasswordInput = {
  /** The password, must be between 12 and 64 chars */
  password: Scalars['String']['input'];
};

export type ResolveInterventionsInput = {
  /** email of the agent who resolved it */
  agentEmail: Scalars['String']['input'];
  /** the ids of the intervention to resolve */
  interventionIds: Array<Scalars['ID']['input']>;
  /** the resolution */
  resolution: InterventionStatus;
  /** the ticket where this was resolved. */
  ticketId: Scalars['Int']['input'];
  /** the ID of the user */
  userId: Scalars['ID']['input'];
};

/** Information related to the session interacting with Sardine. */
export type RiskSession = {
  __typename?: 'RiskSession';
  /** The Sardine-provided id for instantiating the JS library. */
  clientId: Scalars['String']['output'];
  /** The Sardine environment. This will be `"production"` or `"sandbox"`. */
  environment: Scalars['String']['output'];
  /** The Meso-generated token representing the user's risk session. */
  sessionKey: Scalars['String']['output'];
  /** The Meso-generated user id. */
  userId: Scalars['ID']['output'];
};

export type RiskSessionResult = Errors | RiskSession;

/** A user session, required to make further calls */
export type Session = {
  __typename?: 'Session';
  /** Is the applepay feature enabled */
  applepayEnabled: Scalars['Boolean']['output'];
  /** a banner message that should be shown on all screens if present */
  banner?: Maybe<BannerMessage>;
  /** [ISO-3166](https://en.wikipedia.org/wiki/ISO_3166) 2 character country code of the device where the session was initiated */
  deviceCountry?: Maybe<Scalars['String']['output']>;
  /** The session ID, can be used for identifying the session in alerts, logs, etc */
  id: Scalars['ID']['output'];
  /** Whether this is a returning user as opposed to a new user. */
  isReturningUser: Scalars['Boolean']['output'];
  /** Is the passkeys feature switch enabled for this partner */
  passkeysEnabled: Scalars['Boolean']['output'];
  /** Details of the risk session. */
  riskSession: RiskSession;
  /**
   * The session token, should be provided as a bearer auth for future calls. Should not
   * be shared with third parties.
   */
  token: Scalars['String']['output'];
  /** the largest amount a user can cash in or out */
  transferMax: Money;
  /** the smallest amount a user can cash in or out */
  transferMin: Money;
};

export enum SessionLevel {
  ONE_FACTOR = 'ONE_FACTOR',
  PUBLIC = 'PUBLIC',
  TWO_FACTOR = 'TWO_FACTOR'
}

export type SetThreeDomainSecureSessionMethodCompletionInput = {
  /** the session's ID */
  id: Scalars['ID']['input'];
  /** 3DS method completion value: Y or N */
  methodCompletion: Scalars['String']['input'];
};

export enum SingleUseInstrument {
  APPLE_PAY = 'APPLE_PAY'
}

export type StartOnboardingInput = {
  /** The [CAIP-2](https://github.com/ChainAgnostic/CAIPs/blob/master/CAIPs/caip-2.md) formatted ID. */
  network: Scalars['String']['input'];
  /** The address of the wallet the user would like to use */
  walletAddress: Scalars['String']['input'];
};

export type StubDocumentReceivedWebhookInput = {
  /** The date of birth in `YYYY-MM-DD` format. */
  dateOfBirth: Scalars['String']['input'];
  /** The given name of the user. */
  firstName: Scalars['String']['input'];
  /** An [ISO-3166](https://en.wikipedia.org/wiki/ISO_3166) 2 character country code. */
  issuingCountry: Scalars['String']['input'];
  /** The kind of document. */
  kind: Scalars['String']['input'];
  /** The family name of the user. */
  lastName: Scalars['String']['input'];
  /** The document number. */
  number: Scalars['String']['input'];
  /** The verification id. */
  verificationId: Scalars['ID']['input'];
};

/** Parameters to create a transfer with a 3DS session */
export type ThreeDomainSecureInput = {
  /** the session ID */
  sessionID: Scalars['ID']['input'];
  /** the transStatus from the Challenge */
  transStatus?: InputMaybe<Scalars['String']['input']>;
};

/** A 3DS session */
export type ThreeDomainSecureSession = {
  __typename?: 'ThreeDomainSecureSession';
  /** the session's Access Control Server transaction ID */
  acsTransactionId?: Maybe<Scalars['ID']['output']>;
  /**
   * Access Control Server URL to perform challenge
   *
   * Will be populated if issuer fingerprint is complete or unnecessary
   */
  acsUrl?: Maybe<Scalars['String']['output']>;
  /** the session's meso ID */
  id: Scalars['ID']['output'];
  /** the next actions required */
  nextActions: Array<Scalars['String']['output']>;
  /** the version of the protocol, eg 2.1.0 or 2.2.0 */
  protocolVersion: Scalars['String']['output'];
  /** Optional Access Control Server URL required to create an issuer fingerprint */
  threeDSMethodUrl?: Maybe<Scalars['String']['output']>;
  /** the session's transaction ID */
  transactionId: Scalars['ID']['output'];
};

export type ThreeDomainSecureSessionResult = Errors | ThreeDomainSecureSession;

/** Details of a Transfer. */
export type Transfer = {
  __typename?: 'Transfer';
  /** The address the send funds to the deposit address on a cash out */
  cashOutSenderAddress?: Maybe<Scalars['String']['output']>;
  /** An [RFC 3339](https://datatracker.ietf.org/doc/html/rfc3339) compliant timestamp representing the initiation of the `Transfer`. */
  createdAt: Scalars['String']['output'];
  /** The full reason the transfer was moved to the DECLINED status */
  declineReason: DeclineReason;
  /** Details of the destination instrument */
  destInstrument: FinancialInstrument;
  /** The destination finanical instrument ID */
  destInstrumentId: Scalars['ID']['output'];
  /** The amount of crypto the user will be sent. */
  destination: Money;
  /** The exchange rate of the transfer */
  exchangeRate: ExchangeRate;
  /** The reason for the frontend that the transfer was moved to the DECLINED status */
  frontendDeclineReason?: Maybe<FrontendDeclineReason>;
  /** The unique identifier for this transfer. */
  id: Scalars['ID']['output'];
  /** The kind of this transfer. */
  kind: TransferKind;
  /** The amount Meso will charge for this Transfer in USD. */
  mesoFee: Money;
  /** The amount Meso will charge for this Transfer in the presented currency. */
  mesoFeePresented: Money;
  /** Has the meso fee been waived, this is usually because a partner is subsidizing it */
  mesoFeeWaived: Scalars['Boolean']['output'];
  /** The cost of moving the funds on chain in USD. */
  networkFee: Money;
  /** The cost of moving the funds on chain in the presented currency. */
  networkFeePresented: Money;
  /** Has the network fee been waived, this usually if it is a de minimis amount */
  networkFeeWaived: Scalars['Boolean']['output'];
  /** The transaction ID of the leg of the transfer that touched the chain */
  networkTransactionId: Scalars['String']['output'];
  /** The partner that facilitated the transfer */
  partner: Partner;
  riskLevel: Scalars['String']['output'];
  /** Details of the source instrument */
  sourceInstrument: FinancialInstrument;
  /** The source financial instrument ID */
  sourceInstrumentId: Scalars['ID']['output'];
  /** The source total without fees. This is the amount of crypto that will be purchased for the Transfer in USD. */
  sourceSubtotal: Money;
  /** The source total without fees. This is the amount of crypto that will be purchased for the Transfer in presented currency. */
  sourceSubtotalPresented: Money;
  /** The total amount a user will be charged for a cash-in in USD. This is inclusive of fees. */
  sourceTotal: Money;
  /** The total amount a user will be charged for a cash-in in the presentment currency. This is inclusive of fees. */
  sourceTotalPresented: Money;
  status: TransferStatus;
  /** An [RFC 3339](https://datatracker.ietf.org/doc/html/rfc3339) compliant timestamp representing the latest update of the `Transfer`. */
  updatedAt: Scalars['String']['output'];
};

export enum TransferKind {
  CASH_IN = 'CASH_IN',
  CASH_OUT = 'CASH_OUT',
  UNKNOWN = 'UNKNOWN'
}

export type TransferResult = Errors | Transfer;

export enum TransferStatus {
  APPROVED = 'APPROVED',
  COMPLETE = 'COMPLETE',
  DECLINED = 'DECLINED',
  EXECUTING = 'EXECUTING',
  UNKNOWN = 'UNKNOWN'
}

/** The collection of transfers for a `User`. */
export type Transfers = {
  __typename?: 'Transfers';
  collection: Array<Transfer>;
};

/** An export of transfers */
export type TransfersExport = {
  __typename?: 'TransfersExport';
  csvContents: Scalars['String']['output'];
};

export type TransfersExportResult = Errors | TransfersExport;

export type TransfersResult = Errors | Transfers;

/** The 2FA method to be used for verification. */
export enum TwoFactorMethod {
  PHONE = 'PHONE'
}

export type UpdatePasswordInput = {
  /** The password, must have a [zxcvbn](https://github.com/dropbox/zxcvbn) score of at least 3 */
  password: Scalars['String']['input'];
};

export enum UpdateUserStatusAction {
  CLOSE = 'CLOSE',
  CLOSE_RISK = 'CLOSE_RISK',
  FREEZE = 'FREEZE',
  UNFREEZE = 'UNFREEZE'
}

export type UpdateUserStatusInput = {
  /** the status to update the user to */
  action: UpdateUserStatusAction;
  /** the email of the agent requesting the action */
  agentEmail: Scalars['String']['input'];
  /** the user to update */
  userId: Scalars['ID']['input'];
};

/**
 * A user only accessible via zendesk, before exposing this to end users, please
 * vet the fields below
 */
export type User = {
  __typename?: 'User';
  /** Was the user auto-declined */
  autoDeclined?: Maybe<Scalars['Boolean']['output']>;
  chargebackVolume?: Maybe<VolumeSummaryResult>;
  /** An [ISO-3166](https://en.wikipedia.org/wiki/ISO_3166) 2 character country code. */
  citizenship?: Maybe<Scalars['String']['output']>;
  /** when was the user born, can be null if they haven't yet provided it */
  dateOfBirth?: Maybe<Scalars['Time']['output']>;
  depositAddressInstruments?: Maybe<DepositAddressInstrumentsResult>;
  email: Scalars['String']['output'];
  emailVerified: Scalars['Boolean']['output'];
  failedTransferVolume?: Maybe<VolumeSummaryResult>;
  feeTier: Scalars['String']['output'];
  fiatInstruments?: Maybe<FiatInstrumentsResult>;
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  identityDocuments?: Maybe<IdentityDocumentsResult>;
  interventions?: Maybe<InterventionsResult>;
  lastName: Scalars['String']['output'];
  /** most recent transfer */
  lastTransfer?: Maybe<TransferResult>;
  limitTier: Scalars['String']['output'];
  limits: UserLimits;
  onboardedAt: Scalars['String']['output'];
  onboardingFiatInstrumentSkipped: Scalars['Boolean']['output'];
  onboardingPartnerId?: Maybe<Scalars['String']['output']>;
  onboardingRiskCategories?: Maybe<Array<Scalars['String']['output']>>;
  onboardingRiskLevel: Scalars['String']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
  phoneNumberVerified: Scalars['Boolean']['output'];
  /** where the user lives, can be null if they haven't yet provided it */
  residence?: Maybe<Address>;
  sardineLink: Scalars['String']['output'];
  /** statuses: unknown, new, provisional, active, in_review, declined, frozen */
  status: Scalars['String']['output'];
  /** The user's theme selection, will return "default" before they've selected */
  theme: Scalars['String']['output'];
  transfer?: Maybe<TransferResult>;
  transferVolume?: Maybe<VolumeSummaryResult>;
  walletInstruments?: Maybe<WalletInstrumentsResult>;
};


/**
 * A user only accessible via zendesk, before exposing this to end users, please
 * vet the fields below
 */
export type UserTransferArgs = {
  id: Scalars['ID']['input'];
};

/** Information about a user's activation status after completing onboarding. */
export type UserActivation = {
  __typename?: 'UserActivation';
  /** The reason for being in a failed state. Can be presented to user */
  failedReason: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  /**
   * The current state of the user activation:
   * started -> succeeded | failed
   */
  state: UserActivationState;
  /** The status for the user, refer to User.status */
  userStatus: Scalars['String']['output'];
};

export type UserActivationResult = Errors | UserActivation;

export enum UserActivationState {
  CREATED = 'CREATED',
  FAILED = 'FAILED',
  SUCCEEDED = 'SUCCEEDED',
  UNKNOWN = 'UNKNOWN'
}

/** Transfer limits for a user */
export type UserLimits = {
  __typename?: 'UserLimits';
  /** the amount of a user's cash in monthly limit they can still use */
  monthlyCashInAvailable: Money;
  /** the amount of a user's cash in monthly limit they've used */
  monthlyCashInUsed: Money;
  /** the amount of a user's cash out monthly limit they can still use */
  monthlyCashOutAvailable: Money;
  /** the amount of a user's cash out monthly limit they've used */
  monthlyCashOutUsed: Money;
  /** the maximum amount of volume a user can cash in or out in a month */
  monthlyMax: Money;
  /** the largest amount a user can cash in or out */
  transferMax: Money;
  /** the smallest amount a user can cash in or out */
  transferMin: Money;
};

export type UserResult = Errors | User;

/** Verify email using code */
export type VerifyEmailInput = {
  /** The verification code from the email body */
  code: Scalars['String']['input'];
};

export type VerifyPhoneNumberInput = {
  /** The code provided by the user during 2FA. */
  verificationCode: Scalars['String']['input'];
};

export type VerifyTwoFactorInput = {
  /** A 2FA code provided by the user. */
  code: Scalars['String']['input'];
};

/** Simple statistics about a user's transfer volume */
export type VolumeSummary = {
  __typename?: 'VolumeSummary';
  count: Scalars['Int']['output'];
  total: Money;
};

export type VolumeSummaryResult = Errors | VolumeSummary;

/** A Financial Instrument representing a crypto wallet. */
export type WalletInstrument = {
  __typename?: 'WalletInstrument';
  /** The address of the wallet that will be used to sign a login message. */
  address: Scalars['String']['output'];
  /** when the instrument was created */
  createdAt: Scalars['Time']['output'];
  /** The unique public ID for this instrument. */
  id: Scalars['ID']['output'];
  kind: InstrumentKind;
  /**
   * The namespace portion of the [CAIP-2](https://github.com/ChainAgnostic/CAIPs/blob/master/CAIPs/caip-2.md) formatted Chain ID.
   *
   * eip155 -> evm wallet
   * solana -> solana wallet
   */
  networkNamespace: Scalars['String']['output'];
  /** has the user verified ownership of the wallet with a signature */
  verified: Scalars['Boolean']['output'];
};

/** The collection of wallet instruments for a `User`. */
export type WalletInstruments = {
  __typename?: 'WalletInstruments';
  collection: Array<WalletInstrument>;
};

export type WalletInstrumentsResult = Errors | WalletInstruments;

export type WithdrawalSignupInput = {
  /** Optional user feedback */
  feedback?: InputMaybe<Scalars['String']['input']>;
};
