import { PropsWithChildren, useContext, useEffect, useRef } from "react";
import {
  SardineProvider,
  SardineContext,
  SardineEnvironment,
} from "@sardine-ai/react-js-wrapper";
import { ApiContextValue } from "../types";
import { useApi } from "../hooks/useApi";
import { Sentry } from "@tigris/common";

type SardineRiskSessionProps = {
  flow?: "onboarding";
  /**
   * A callback to handle errors surfaced in this component
   */
  onError?: () => void;
};

export const SardineRiskSession = ({
  children,
  flow,
  onError = () => {},
}: PropsWithChildren<SardineRiskSessionProps>) => {
  // React strict mode causes useEffect callbacks to be invoked twice. We use
  // the `riskSessionInitialized` ref to avoid setting a new session token from a
  // second RiskSession invocation.
  const riskSessionInitialized = useRef(false);
  const { session, updateSession, api } = useApi();

  useEffect(() => {
    if (!flow) return;

    if (!!session) {
      riskSessionInitialized.current = true;
      return;
    }

    if (riskSessionInitialized.current) return;
    riskSessionInitialized.current = true;

    (async () => {
      const riskSessionResult = await api.resolveRiskSession();

      if (riskSessionResult.isErr()) {
        Sentry.captureException(riskSessionResult.error, {
          extra: { session },
        });

        onError();
      } else {
        updateSession({
          riskSession: {
            ...riskSessionResult.value,
            environment: riskSessionResult.value
              .environment as SardineEnvironment,
          },
        });
      }
    })();
  }, [api, flow, onError, session, updateSession]);

  return (
    <SardineProvider
      clientId={session?.riskSession?.clientId ?? ""}
      environment={session?.riskSession?.environment ?? "sandbox"}
    >
      <SardineConfig flow={flow} session={session}>
        {children}
      </SardineConfig>
    </SardineProvider>
  );
};

function SardineConfig({
  children,
  flow,
  session,
}: PropsWithChildren<{
  flow?: "onboarding";
  session: ApiContextValue["session"];
}>) {
  const { setupSardineWithConfig } = useContext(SardineContext);

  useEffect(() => {
    if (!session || !session.riskSession) return;

    setupSardineWithConfig({
      customerId: session.riskSession.userId,
      sessionKey: session.riskSession.sessionKey,
      flow,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flow, session]);

  return <>{children}</>;
}
