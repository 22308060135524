import Header from "./Header";
import { AnimatePresence, motion } from "framer-motion";
import { AppContext } from "@src/contexts/AppContextProvider";
import { CashOutQuote, TransferKind } from "@src/generated/sdk";
import { LineItems, CardHero, ErrorMessages } from "@tigris/mesokit";
import { MESO_MAX_AMOUNT, Routes } from "@src/utils/constants";
import { CashInQuote, OutletContext } from "@src/types";
import { cloneElement, useCallback, useContext } from "react";
import { useLocation, useOutlet } from "react-router-dom";
import { useQuote, UseQuoteConfiguration } from "@src/hooks/useQuote";
import { QuoteLimitErrorCode } from "@src/api";
import { toast } from "sonner";
import { useApplePayContext } from "@src/hooks/useApplePayContext";
import { useOnboarding } from "@src/hooks/useOnboarding";

const TOAST_ID = "LandingAndTransferContainer";

export const LandingAndTransferContainer = () => {
  const { pathname } = useLocation();
  const {
    configuration: { network, destinationAsset, walletAddress, transferKind },
    api: { resolveRemoveFiatInstrument },
    user,
    fiatInstrument,
    setFiatInstrument,
    userLimits,
    setQuoteLimitReached,
    setIsAddingCard,
    updateUser,
    transfer,
    executeTransferRequestIsInFlight,
  } = useContext(AppContext);
  const { theme, fiatInstruments } = user;
  const { applePayEnabled, applePayCanceled } = useApplePayContext();
  const { supportedPaymentMethods } = useOnboarding();

  const handleQuoteLimitError = useCallback<
    Required<UseQuoteConfiguration>["onLimitError"]
  >(
    (code?: QuoteLimitErrorCode) => {
      switch (code) {
        case "below_min_xfer":
          toast.error(
            ErrorMessages.quote.minLimitError(userLimits?.mesoMinimum ?? 25),
            { id: "quoteLimitError" },
          );
          break;
        case "above_max_xfer":
        case "above_monthly_xfer":
          toast.error(
            ErrorMessages.quote.maxLimitError(
              userLimits?.monthlyAmountAvailable ?? MESO_MAX_AMOUNT,
            ),
            { id: "quoteLimitError" },
          );
      }
      setQuoteLimitReached();
    },
    [
      setQuoteLimitReached,
      userLimits?.mesoMinimum,
      userLimits?.monthlyAmountAvailable,
    ],
  );

  const quoteHook = useQuote({ onLimitError: handleQuoteLimitError });
  const { quote } = quoteHook;
  const outlet = useOutlet({
    useQuote: quoteHook,
  } satisfies OutletContext);

  const onRemoveFiatInstrument = useCallback(
    async (id: string) => {
      const result = await resolveRemoveFiatInstrument({ input: { id } });
      if (
        result.isOk() &&
        result.value.user.fiatInstruments?.__typename === "FiatInstruments" &&
        result.value.user.fiatInstruments
      ) {
        const fiatInstruments = result.value.user.fiatInstruments;
        updateUser({ fiatInstruments }, supportedPaymentMethods);
      } else if (result.isErr()) {
        toast?.error(result.error, { id: TOAST_ID });
      }
    },
    [resolveRemoveFiatInstrument, supportedPaymentMethods, updateUser],
  );

  const onlyShowOutlet = pathname === Routes.TransferSheetDepositAddress;
  const isCashIn = transferKind === TransferKind.CASH_IN;

  return (
    <div className={"flex h-full flex-col gap-2"}>
      <AnimatePresence mode="wait">
        {onlyShowOutlet ? (
          outlet &&
          cloneElement(outlet, {
            key: pathname,
          })
        ) : (
          <motion.div
            key="CardAnimationWrapper"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="flex h-full flex-col gap-2"
          >
            <CardHero
              theme={theme}
              asset={destinationAsset}
              amount={quote?.destination?.amount}
              network={network}
              walletAddress={walletAddress}
              fiatInstrument={fiatInstrument}
              fiatInstruments={fiatInstruments}
              setFiatInstrument={setFiatInstrument}
              setIsAddingCard={setIsAddingCard}
              depositAddress={(quote as CashOutQuote)?.depositAddress}
              transferKind={transferKind}
              applePayEnabled={applePayEnabled}
              applePayCanceled={applePayCanceled}
              canShowPopover={
                !!!transfer?.status && !executeTransferRequestIsInFlight
              }
              limits={
                userLimits && userLimits.approachingLimit
                  ? userLimits
                  : undefined
              }
              authenticated={!!user.id}
              onRemoveFiatInstrument={onRemoveFiatInstrument}
            />
            <div className="flex h-full w-full flex-col justify-between overflow-x-hidden px-4 pb-4">
              <LineItems
                key="LandingAndTransferContainerLineItems"
                exchangeRate={quote?.exchangeRate}
                mesoFeeAmount={quote?.mesoFee.amount}
                mesoFeeWaived={
                  isCashIn && (quote as CashInQuote)?.mesoFeeWaived
                }
                networkFeeAmount={
                  isCashIn
                    ? (quote as CashInQuote)?.networkFee?.amount
                    : undefined
                }
                networkFeeWaived={
                  isCashIn && (quote as CashInQuote)?.networkFeeWaived
                }
                subtotalAmount={quote?.sourceSubtotal.amount}
                totalAmount={
                  isCashIn
                    ? quote?.sourceTotal.amount
                    : quote?.destination.amount
                }
                transferKind={transferKind}
              />
              <AnimatePresence mode="wait">
                {outlet &&
                  cloneElement(outlet, {
                    key: pathname,
                  })}
              </AnimatePresence>
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      {/* This is intentionally placed at the bottom of the component to allow it to stack above the other content */}
      {pathname !== Routes.TransferSheetStatus && <Header />}
    </div>
  );
};
