import { z } from "zod";
import {
  AssetAmount,
  CAIPAsset,
  CAIPNetwork,
  AuthenticationStrategy,
  Network,
  Asset,
} from "@src/types";
import { ErrorMessages } from "@tigris/mesokit";
import { SingleUseInstrument } from "@src/generated/sdk";

/**
 * Validate that the provided singleUseInstrument is valid.
 */
export const singleUseInstrumentSchema = z.nativeEnum(SingleUseInstrument);

/**
 * Validate that the provided authentication strategy is allowed.
 */
export const authenticationStrategySchema = z.nativeEnum(
  AuthenticationStrategy,
);

/**
 * Validate that the provided network is allowed.
 */
export const networkSchema = z.nativeEnum(Network);

/**
 * Validate that the provided CAIPNetwork is allowed.
 */
export const caipNetworkSchema = z.nativeEnum(CAIPNetwork);

/**
 * Validate that the provided asset is allowed.
 */
export const assetSchema = z.nativeEnum(Asset);

/**
 * Validate that the provided CAIPAsset is allowed.
 */
export const caipAssetSchema = z.nativeEnum(CAIPAsset);

/**
 * Validate that the provided `sourceAmount` or `destinationAmount` is allowed.
 */
export const amountSchema = z
  .string()
  .transform((value) => {
    if (value.endsWith(".")) {
      return `${value}00`;
    }

    return value;
  })
  .pipe(z.string().regex(/^\d*\.?\d+$/));

/**
 *
 * Validate a numeric string is a valid decimal number and is within the specified range.
 *
 * @param min Minimum number to be matched against
 * @param max Maximum number to be matched against
 * @returns Zod schema
 */
export const amountBoundariesSchema = (min: AssetAmount, max: AssetAmount) => {
  return z
    .string()
    .transform((value) => {
      if (value.endsWith(".")) {
        return `${value}00`;
      }

      return value;
    })
    .pipe(z.string().regex(/^\d*\.?\d+$/))
    .pipe(
      z.coerce
        .number()
        .min(Number(min), ErrorMessages.quote.minLimitError(min))
        .max(Number(max), ErrorMessages.quote.maxLimitError(max)),
    );
};
