import { err, ok, Result } from "neverthrow";
import { Routes, UserStatus } from "../../types";
import { ErrorMessages } from "@tigris/mesokit";
import { InstrumentKind, ProfileFieldStatus } from "../../generated/sdk";
import { getAge } from "@tigris/common";
import { NextOnboardingArgs } from "./types";

export const getNextInternationalOnboardingStep = ({
  profileStatus,
  user,
  supportedPaymentMethods,
}: NextOnboardingArgs): Result<Routes, string> => {
  // In all cases, this is the very first step, so if it's not set but other values are, something is very wrong
  if (!profileStatus.residenceCountry) {
    return ok(Routes.Root);
  }

  if (!profileStatus.account && !profileStatus.acceptedMesoTerms) {
    return ok(Routes.CreateAccount);
  } else if (!profileStatus.account || !profileStatus.acceptedMesoTerms) {
    // If a user has `account:true` but has not accepted terms, they are in an unrecoverable state
    return err(ErrorMessages.onboardingSteps.UNABLE_TO_DETERMINE_NEXT_STEP);
  }

  if (!profileStatus.phone || !profileStatus.phoneVerified) {
    return ok(Routes.PhoneEntry);
  }

  if (!profileStatus.identityDocuments) {
    return ok(Routes.BasicInfoOverview);
  }

  // At this point, if no user object is provided, we can't determine next steps
  if (!user) {
    return err(ErrorMessages.onboardingSteps.USER_OBJECT_REQUIRED);
  }

  // Personal information
  if (
    !user.firstName ||
    !user.lastName ||
    !user.dateOfBirth ||
    !user.citizenship ||
    !profileStatus.citizenship
  ) {
    return ok(Routes.PersonalInfo);
  }

  // Conditional PESEL
  if (
    profileStatus.citizenship &&
    profileStatus.pesel === ProfileFieldStatus.REQUIRED
  ) {
    return ok(Routes.Pesel);
  }

  if (
    profileStatus.citizenship &&
    profileStatus.pesel === ProfileFieldStatus.NOT_REQUIRED &&
    !profileStatus.residentialAddress
  ) {
    return ok(Routes.ResidentialAddress);
  }

  // Scam warning
  const userAge = getAge(user.dateOfBirth);
  const ageRequiresScamWarning = userAge.isOk() && userAge.value >= 50;
  if (ageRequiresScamWarning && !profileStatus.acknowledgedScamWarning) {
    return ok(Routes.ScamWarning);
  }

  // Now should go to select payment method
  if (!profileStatus.financialInstrument) {
    if (
      supportedPaymentMethods.length === 1 &&
      supportedPaymentMethods[0] === InstrumentKind.PAYMENT_CARD
    ) {
      return ok(Routes.AddPaymentCard);
    }

    return ok(Routes.SelectPaymentMethod);
  }

  if (user.status === UserStatus.NEW) {
    return ok(Routes.Summary);
  }

  return err(ErrorMessages.onboardingSteps.UNABLE_TO_DETERMINE_NEXT_STEP);
};
