/* eslint-disable react-hooks/exhaustive-deps */
import {
  Combobox,
  ComboboxInput,
  ComboboxOptions,
  ComboboxOption,
} from "@headlessui/react";

import {
  ForwardedRef,
  forwardRef,
  useCallback,
  useMemo,
  useState,
} from "react";
import { Input } from "./Input";
import { twMerge } from "tailwind-merge";
import { CountryCodeAlpha2 } from "../types";
import { Country, NullableCountry } from "../utils/countries";

export type CountryComboboxProps = {
  /** An optional callback that will be dispatched when a country is selected. If no country is selected, `null` will be provided and you can assume the input is invalid. */
  onSelectCountry?: (countryCode: CountryCodeAlpha2 | null) => void;
  /** Whether to disable the combobox input. Defaults to `false`. */
  disabled?: boolean;
  /** Placeholder text for the combobox input. Defaults to "Search countries". */
  placeholder?: string;
  /** The initial value for the combobox. */
  initialCountry?: CountryCodeAlpha2;
  countries: Country[];

  /** Whether to render the combobox input without border radius bottom. */
  clipBorderBottom?: boolean;
};

//  Because of how @headlessui/react works, we cannot explicitly pass props to the `ComboboxInput as={}` value.
// To achieve our desired effect,we create two separate component refs each with explicit styling.
// https://headlessui.com/react/combobox#rendering-as-different-elements

// This allows us to forward refs from the <ComboboxInput /> component to our Meso <Input /> component
const InputForCombobox = forwardRef(
  (props, ref: ForwardedRef<HTMLInputElement>) => {
    return <Input ref={ref} {...props} className="!text-sm font-medium" />;
  },
);

const BorderBottomClippedInputForCombobox = forwardRef(
  (props, ref: ForwardedRef<HTMLInputElement>) => {
    return (
      <Input
        ref={ref}
        {...props}
        className="!rounded-b-none !text-sm font-medium"
      />
    );
  },
);

const noop = () => {};

export const CountryCombobox = ({
  onSelectCountry = noop,
  disabled = false,
  placeholder = "Search countries",
  initialCountry,
  countries,
  clipBorderBottom = false,
}: CountryComboboxProps) => {
  const [selectedCountry, setSelectedCountry] = useState<NullableCountry>(
    () => {
      if (initialCountry) {
        return (
          countries.find(
            (country) => country.countryCodeAlpha2 === initialCountry,
          ) ?? null
        );
      }
      return null;
    },
  );
  const [query, setQuery] = useState("");

  const filteredCountries = useMemo(() => {
    if (query === "") {
      return countries;
    }

    return countries.filter((country) => {
      return country.display.toLowerCase().includes(query.toLowerCase());
    });
  }, [query]);

  const handleSelectedCountryChange = useCallback(
    (newSelectedCountry: NullableCountry) => {
      setSelectedCountry(newSelectedCountry);
      onSelectCountry(newSelectedCountry?.countryCodeAlpha2 ?? null);
    },
    [],
  );

  return (
    <Combobox<NullableCountry>
      value={selectedCountry}
      onChange={handleSelectedCountryChange}
      onClose={() => setQuery("")}
      immediate
    >
      {({ activeIndex }) => {
        return (
          <>
            <ComboboxInput<Country, typeof Input>
              aria-label="Assignee"
              displayValue={(country) =>
                country ? `${country.icon} ${country.display}` : ""
              }
              onChange={(event) => setQuery(event.target.value)}
              placeholder={placeholder}
              as={
                clipBorderBottom
                  ? BorderBottomClippedInputForCombobox
                  : InputForCombobox
              }
              // Props for our Meso <Input> component
              data-testid="CountryCombobox:input"
              isValid={true}
              disabled={disabled}
              // We add _search to the end to prevent 1password from rendering it's UI
              // see: https://1password.community/discussion/comment/606453/#Comment_606453
              id="CountryCombobox_search"
            />
            <ComboboxOptions
              anchor="bottom"
              data-testid="CountryCombobox:options"
              className={twMerge(
                "rounded-2xl border bg-white p-2 text-sm shadow-2xl empty:invisible dark:border-neutral-800 dark:bg-neutral-700",
                // Taken from the headlessui docs
                "w-[var(--input-width)] origin-top border transition duration-200 ease-out empty:invisible data-[closed]:scale-95 data-[closed]:opacity-0",
                "[--anchor-gap:4px]",
              )}
              transition
            >
              {filteredCountries.map((country, index) => (
                <ComboboxOption
                  data-testid="CountryCombobox:option"
                  key={country.countryCodeAlpha2}
                  value={country}
                  className={twMerge(
                    "flex w-full cursor-pointer items-center justify-between rounded-xl px-4 py-2 transition dark:bg-neutral-700 dark:text-white",
                    activeIndex === index &&
                      "bg-neutral-200 dark:bg-neutral-600",
                  )}
                >
                  <div className="flex gap-2 text-sm font-medium">
                    <div>{country.icon}</div>
                    <div>{country.display}</div>
                  </div>
                </ComboboxOption>
              ))}
              {filteredCountries.length === 0 && (
                <ComboboxOption
                  data-testid="CountryCombobox:option"
                  key="fallback"
                  value={null}
                  className={twMerge(
                    "flex w-full cursor-pointer items-center justify-between rounded-xl px-4 py-2 transition dark:bg-neutral-700 dark:text-white",
                    activeIndex === 0 && "bg-neutral-200 dark:bg-neutral-600",
                    "text-xs",
                  )}
                >
                  <div className="flex flex-col">
                    <div className="font-bold">No results found.</div>
                    <div>We may not support this country yet.</div>
                  </div>
                </ComboboxOption>
              )}
            </ComboboxOptions>
          </>
        );
      }}
    </Combobox>
  );
};
