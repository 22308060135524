import { Sentry } from "@tigris/common";
import {
  GenericError,
  Offset,
  Position,
  PixelValue,
  Message,
  MessageKind,
  SerializedTransferIframeParams,
} from "@src/types";
import { Result, err, ok } from "neverthrow";

const ERROR_MESSAGE = "Unable to parse `layout.offset`.";

const isPositiveNumber = (n: PixelValue): boolean => {
  const num = Number(n);
  return !Number.isNaN(num) && num >= 0;
};

const parseOffset = (
  layoutOffset: SerializedTransferIframeParams["layoutOffset"],
): Offset | GenericError => {
  if (!isPositiveNumber(layoutOffset as PixelValue)) {
    // Assume we are dealing with a serialized object or invalid string
    try {
      const parsedOffset = JSON.parse(layoutOffset as string);
      const offset: Offset = { horizontal: 0, vertical: 0 };

      if ("horizontal" in parsedOffset) {
        if (!isPositiveNumber(parsedOffset.horizontal)) {
          return { message: ERROR_MESSAGE };
        }

        offset.horizontal = Number(parsedOffset.horizontal);
      }

      if ("vertical" in parsedOffset) {
        if (!isPositiveNumber(parsedOffset.vertical)) {
          return { message: ERROR_MESSAGE };
        }
        offset.vertical = Number(parsedOffset.vertical);
      }

      return offset;
    } catch (err: unknown) {
      Sentry.captureException(ERROR_MESSAGE, {
        extra: { error: err, layoutOffset },
      });

      return { message: ERROR_MESSAGE };
    }
  } else {
    if (isPositiveNumber(layoutOffset as PixelValue)) {
      const offsetValue = Number(layoutOffset);
      return { horizontal: offsetValue, vertical: offsetValue };
    } else {
      return { message: ERROR_MESSAGE };
    }
  }
};

const parsePosition = (
  position: unknown,
): { position: Position } | GenericError => {
  if (!Object.values(Position).some((value) => value === position)) {
    return {
      message: `Invalid position. Required one of ${Object.values(
        Position,
      ).join(", ")}.`,
    };
  }

  return { position: position as Position };
};

export const parseLayoutParams = ({
  layoutOffset,
  layoutPosition,
}: Pick<
  SerializedTransferIframeParams,
  "layoutOffset" | "layoutPosition"
>): Result<{ offset: Offset; position: Position }, Message> => {
  const parseOffsetResult = parseOffset(layoutOffset);

  if ("message" in parseOffsetResult) {
    return err({
      kind: MessageKind.CONFIGURATION_ERROR,
      payload: { message: parseOffsetResult.message },
    });
  }

  const parsePositionResult = parsePosition(layoutPosition);

  if ("message" in parsePositionResult) {
    return err({
      kind: MessageKind.CONFIGURATION_ERROR,
      payload: parsePositionResult,
    });
  }

  return ok({
    offset: parseOffsetResult,
    position: parsePositionResult.position,
  });
};
