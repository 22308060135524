import { useSafeLocalStorage } from "./useSafeLocalStorage";

type Impression = {
  count: number;
  lastTs: number;
};

type Impressions = {
  [key: string]: Impression;
};

export const useImpressionTracker = () => {
  const [impressions, setImpressions] = useSafeLocalStorage<Impressions>(
    "meso:impressions",
    {},
  );

  return {
    trackImpression: (key: string) => {
      setImpressions({
        ...impressions,
        [key]: {
          count: key in impressions ? impressions[key].count + 1 : 1,
          lastTs: new Date().getTime(),
        },
      });
    },
    getImpressionCount: (key: string): number => impressions[key]?.count || 0,
    getImpressionLastTs: (key: string): Date | undefined =>
      key in impressions ? new Date(impressions[key].lastTs) : undefined,
  };
};
