import {
  AddPaymentCardForm,
  AddPaymentCardFormProps,
  countries,
  LoadingInterstitial,
} from "@tigris/mesokit";
import { useCallback, useState } from "react";
import { toast } from "sonner";
import {
  CountryCodeAlpha2,
  OnboardingAppRenderContext,
  Routes,
  UserStatus,
} from "../types";
import AnimationContainer from "./AnimationContainer";
import { getNextOnboardingStep } from "../utils/nextOnboardingStep";
import { useRouter } from "../hooks/useRouter";
import { useApi } from "../hooks/useApi";
import { useOnboarding } from "../hooks/useOnboarding";
import { useActivateUser } from "../hooks/useActivateUser";
import { TelemetryEvents, Posthog } from "@tigris/common";

const TOAST_ID = "AddPaymentCard";

export const AddPaymentCard = () => {
  const {
    api: { resolveAddPaymentCard },
    session,
  } = useApi();
  const { navigate, disableNavigation, enableNavigation } = useRouter();
  const { renderContext, returnToTransfer, user, configuration } =
    useOnboarding();
  const [formEnabled, setFormEnabled] = useState(false);
  const [showLoadingInterstitial, setShowLoadingInterstitial] = useState(false);

  const [initialValues] = useState<
    AddPaymentCardFormProps["initialValues"] | undefined
  >(() => {
    if (user.firstName && user.lastName && user.residentialAddress) {
      // This component accepts our API model's notion of a `residentialAddress`. Whether the address is US or Int'l shouldn't matter here.
      return {
        cardholderName: `${user.firstName} ${user.lastName}`,
        billingAddress: {
          addressLine1: user.residentialAddress.address1,
          addressLine2: user.residentialAddress.address2 ?? "",
          city: user.residentialAddress.city,
          state: user.residentialAddress.region,
          zip: user.residentialAddress.postalCode,
          country: user.residentialAddress.countryCode as CountryCodeAlpha2,
        },
      };
    }

    return undefined;
  });

  const handleActivateUserError = useCallback(
    (userActivationStartTime: number) => {
      // Collect metrics
      const duration = performance.now() - userActivationStartTime;
      const sessionId = session?.id;
      Posthog?.capture(TelemetryEvents.userActivationComplete, {
        status: "error",
        sessionId,
        duration,
      });

      enableNavigation();

      if (renderContext === OnboardingAppRenderContext.EMBEDDED_IN_TRANSFER) {
        returnToTransfer("onboardingTerminated");
      } else {
        navigate(Routes.ManualReview);
      }
    },
    [enableNavigation, navigate, renderContext, returnToTransfer, session?.id],
  );

  const handleActivateUserSuccess = useCallback(
    (userStatus: UserStatus, userActivationStartTime: number) => {
      // Collect metrics
      const duration = performance.now() - userActivationStartTime;
      const sessionId = session?.id;

      Posthog?.capture(TelemetryEvents.userActivationComplete, {
        status: userStatus,
        sessionId,
        duration,
      });

      enableNavigation();

      if (userStatus === UserStatus.ACTIVE) {
        navigate(Routes.Summary);
      } else if (userStatus === UserStatus.IN_REVIEW) {
        // Routing to /manual-review will handle the `returnToTransfer` call
        navigate(Routes.ManualReview, { userStatus });
      } else {
        returnToTransfer("onboardingTerminated");
      }
    },
    [enableNavigation, navigate, returnToTransfer, session?.id],
  );

  const { activateUser, isActivatingUser } = useActivateUser();

  const handleTokenizationSuccess = useCallback<
    AddPaymentCardFormProps["onTokenizationSuccess"]
  >(
    async (token: string) => {
      setFormEnabled(false);

      const addPaymentCardResult = await resolveAddPaymentCard({
        input: { token },
      });

      if (addPaymentCardResult.isErr()) {
        toast.error(addPaymentCardResult.error, { id: TOAST_ID });

        setFormEnabled(true);
        return;
      }

      const { user, addPaymentCard } = addPaymentCardResult.value;

      const nextStep = getNextOnboardingStep({
        profileStatus: addPaymentCard,
        supportedPaymentMethods: configuration.supportedPaymentMethods,
        user,
      });

      if (nextStep.isErr()) {
        toast.error(nextStep.error, {
          id: "UNABLE_TO_DETERMINE_NEXT_STEP",
        });
        return;
      }

      // This means the user has completed all basic info entry and we can begin activation
      if (nextStep.value === Routes.Summary) {
        // Collect metrics
        const userActivationStartTime = performance.now();

        Posthog.capture(TelemetryEvents.userActivationStart, {
          sessionId: session?.id,
        });

        setShowLoadingInterstitial(true);
        disableNavigation();

        const activateUserResult = await activateUser();
        if (activateUserResult.isErr()) {
          handleActivateUserError(userActivationStartTime);
        } else {
          handleActivateUserSuccess(
            activateUserResult.value,
            userActivationStartTime,
          );
        }
      } else {
        navigate(nextStep.value);
      }

      // MESO-1907 – Re-enable for cash-outs
      // -----------------------
      // Special case of adding payout eligible card for cash-out
      // Fallthrough if user is not active
      // if (user.status === UserStatus.ACTIVE) {
      //   //Recall transfer sheet and take user to transfer view
      //   // Implement a `returnToTransfer()` (or similar) function on context
      //   return;
      // }

      // if (
      //   user &&
      //   user.__typename === "User" &&
      //   user.fiatInstruments &&
      //   user.fiatInstruments.__typename === "FiatInstruments" &&
      //   user.walletInstruments &&
      //   user.walletInstruments.__typename === "WalletInstruments" &&
      //   user.depositAddressInstruments?.__typename ===
      //     "DepositAddressInstruments"
      // ) {
      //   if (
      //     transferKind === TransferKind.CASH_OUT &&
      //     !user.fiatInstruments.collection.find((fi) => fi.payoutEligible)
      //   ) {
      //     toast.error(
      //       ErrorMessages.addPaymentCard.PAYOUT_INELIGIBLE_PAYMENT_CARD_ERROR,
      //       { id: TOAST_ID },
      //     );
      //     setFormEnabled(true);
      //     return;
      //   }

      //   updateUser({
      //     ...user,
      //     theme: user.theme as ThemeName,
      //     fiatInstruments: user.fiatInstruments,
      //     walletInstruments: user.walletInstruments,
      //     depositAddressInstruments: user.depositAddressInstruments,
      //   });

      //   const nextStep = nextOnboardingStep(
      //     Routes.AddPaymentCard,
      //     addPaymentCard,
      //     user.status as UserStatus,
      //     search,
      //   );

      //   if (nextStep.isErr()) {
      //     toast.error(nextStep.error, {
      //       id: "UNABLE_TO_DETERMINE_NEXT_STEP",
      //     });
      //     return;
      //   }

      //   navigate(nextStep.value.to, nextStep.value.options);
      // }
    },
    [
      activateUser,
      configuration.supportedPaymentMethods,
      disableNavigation,
      handleActivateUserError,
      handleActivateUserSuccess,
      navigate,
      resolveAddPaymentCard,
      session?.id,
    ],
  );

  // Defer the loading of CKO's JS until our intro animation completes.
  const onAnimationComplete = useCallback(() => {
    setFormEnabled(true);
  }, []);

  return (
    <AnimationContainer onAnimationComplete={onAnimationComplete}>
      {showLoadingInterstitial ? (
        <LoadingInterstitial mode="loading" />
      ) : (
        <AddPaymentCardForm
          international={user?.residenceCountry !== CountryCodeAlpha2.US}
          initialCountryCodeAlpha3={
            countries.find(
              ({ countryCodeAlpha2 }) =>
                countryCodeAlpha2 === user!.residenceCountry,
            )!.countryCodeAlpha3
          }
          formEnabled={formEnabled}
          disabled={isActivatingUser}
          onTokenizationSuccess={handleTokenizationSuccess}
          formId="OnboardingAddPaymentCard"
          initialValues={initialValues}
          sessionId={session?.id}
        />
      )}
    </AnimationContainer>
  );
};
