import { createContext } from "react";
import { RouterContextValue, Routes } from "../types";

export const RouterContext = createContext<RouterContextValue>({
  currentRoute: { pathname: Routes.Root },
  navigate: () => {},
  outlet: <></>,

  enableNavigation: () => {},
  disableNavigation: () => {},
  navigationDisabled: false,
});
