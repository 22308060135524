import { CountryCodeAlpha2, CountryCodeAlpha3 } from "../types";

export type NullableCountry = Country | null;
export type Country = {
  display: string;
  icon: string;
  countryCodeAlpha2: CountryCodeAlpha2;
  countryCodeAlpha3: CountryCodeAlpha3;
};

export const getUS = () => ({
  display: "United States",
  icon: "🇺🇸",
  countryCodeAlpha2: CountryCodeAlpha2.US,
  countryCodeAlpha3: CountryCodeAlpha3.USA,
});

// Smarty requires the ISO3 Alpha-3 country code
// // https://www.smarty.com/docs/cloud/international-address-autocomplete-api#supported-country-codes
export const countries: Country[] = [
  // Ensure this is always first
  getUS(),

  // Supported EU countries
  {
    display: "Austria",
    icon: "🇦🇹",
    countryCodeAlpha2: CountryCodeAlpha2.AT,
    countryCodeAlpha3: CountryCodeAlpha3.AUT,
  },
  {
    display: "Belgium",
    icon: "🇧🇪",
    countryCodeAlpha2: CountryCodeAlpha2.BE,
    countryCodeAlpha3: CountryCodeAlpha3.BEL,
  },
  {
    display: "Bulgaria",
    icon: "🇧🇬",
    countryCodeAlpha2: CountryCodeAlpha2.BG,
    countryCodeAlpha3: CountryCodeAlpha3.BGR,
  },
  {
    display: "Croatia",
    icon: "🇭🇷",
    countryCodeAlpha2: CountryCodeAlpha2.HR,
    countryCodeAlpha3: CountryCodeAlpha3.HRV,
  },
  {
    display: "Cyprus",
    icon: "🇨🇾",
    countryCodeAlpha2: CountryCodeAlpha2.CY,
    countryCodeAlpha3: CountryCodeAlpha3.CYP,
  },
  {
    display: "Czech Republic",
    icon: "🇨🇿",
    countryCodeAlpha2: CountryCodeAlpha2.CZ,
    countryCodeAlpha3: CountryCodeAlpha3.CZE,
  },
  {
    display: "Denmark",
    icon: "🇩🇰",
    countryCodeAlpha2: CountryCodeAlpha2.DK,
    countryCodeAlpha3: CountryCodeAlpha3.DNK,
  },
  {
    display: "Estonia",
    icon: "🇪🇪",
    countryCodeAlpha2: CountryCodeAlpha2.EE,
    countryCodeAlpha3: CountryCodeAlpha3.EST,
  },
  {
    display: "Finland",
    icon: "🇫🇮",
    countryCodeAlpha2: CountryCodeAlpha2.FI,
    countryCodeAlpha3: CountryCodeAlpha3.FIN,
  },
  {
    display: "France",
    icon: "🇫🇷",
    countryCodeAlpha2: CountryCodeAlpha2.FR,
    countryCodeAlpha3: CountryCodeAlpha3.FRA,
  },
  {
    display: "Germany",
    icon: "🇩🇪",
    countryCodeAlpha2: CountryCodeAlpha2.DE,
    countryCodeAlpha3: CountryCodeAlpha3.DEU,
  },
  {
    display: "Greece",
    icon: "🇬🇷",
    countryCodeAlpha2: CountryCodeAlpha2.GR,
    countryCodeAlpha3: CountryCodeAlpha3.GRC,
  },
  {
    display: "Hungary",
    icon: "🇭🇺",
    countryCodeAlpha2: CountryCodeAlpha2.HU,
    countryCodeAlpha3: CountryCodeAlpha3.HUN,
  },
  {
    display: "Iceland",
    icon: "🇮🇸",
    countryCodeAlpha2: CountryCodeAlpha2.IS,
    countryCodeAlpha3: CountryCodeAlpha3.ISL,
  },
  {
    display: "Ireland",
    icon: "🇮🇪",
    countryCodeAlpha2: CountryCodeAlpha2.IE,
    countryCodeAlpha3: CountryCodeAlpha3.IRL,
  },
  {
    display: "Italy",
    icon: "🇮🇹",
    countryCodeAlpha2: CountryCodeAlpha2.IT,
    countryCodeAlpha3: CountryCodeAlpha3.ITA,
  },
  {
    display: "Latvia",
    icon: "🇱🇻",
    countryCodeAlpha2: CountryCodeAlpha2.LV,
    countryCodeAlpha3: CountryCodeAlpha3.LVA,
  },
  {
    display: "Lithuania",
    icon: "🇱🇹",
    countryCodeAlpha2: CountryCodeAlpha2.LT,
    countryCodeAlpha3: CountryCodeAlpha3.LTU,
  },
  {
    display: "Luxembourg",
    icon: "🇱🇺",
    countryCodeAlpha2: CountryCodeAlpha2.LU,
    countryCodeAlpha3: CountryCodeAlpha3.LUX,
  },
  {
    display: "Malta",
    icon: "🇲🇹",
    countryCodeAlpha2: CountryCodeAlpha2.MT,
    countryCodeAlpha3: CountryCodeAlpha3.MLT,
  },
  {
    display: "Netherlands",
    icon: "🇳🇱",
    countryCodeAlpha2: CountryCodeAlpha2.NL,
    countryCodeAlpha3: CountryCodeAlpha3.NLD,
  },
  {
    display: "Norway",
    icon: "🇳🇴",
    countryCodeAlpha2: CountryCodeAlpha2.NO,
    countryCodeAlpha3: CountryCodeAlpha3.NOR,
  },
  {
    display: "Poland",
    icon: "🇵🇱",
    countryCodeAlpha2: CountryCodeAlpha2.PL,
    countryCodeAlpha3: CountryCodeAlpha3.POL,
  },
  {
    display: "Portugal",
    icon: "🇵🇹",
    countryCodeAlpha2: CountryCodeAlpha2.PT,
    countryCodeAlpha3: CountryCodeAlpha3.PRT,
  },
  {
    display: "Romania",
    icon: "🇷🇴",
    countryCodeAlpha2: CountryCodeAlpha2.RO,
    countryCodeAlpha3: CountryCodeAlpha3.ROU,
  },
  {
    display: "Slovakia",
    icon: "🇸🇰",
    countryCodeAlpha2: CountryCodeAlpha2.SK,
    countryCodeAlpha3: CountryCodeAlpha3.SVK,
  },
  {
    display: "Slovenia",
    icon: "🇸🇮",
    countryCodeAlpha2: CountryCodeAlpha2.SI,
    countryCodeAlpha3: CountryCodeAlpha3.SVN,
  },
  {
    display: "Spain",
    icon: "🇪🇸",
    countryCodeAlpha2: CountryCodeAlpha2.ES,
    countryCodeAlpha3: CountryCodeAlpha3.ESP,
  },
  {
    display: "Sweden",
    icon: "🇸🇪",
    countryCodeAlpha2: CountryCodeAlpha2.SE,
    countryCodeAlpha3: CountryCodeAlpha3.SWE,
  },
];

export const nonUSCountries = (() =>
  countries.filter(
    (country) => country.countryCodeAlpha2 !== CountryCodeAlpha2.US,
  ))();

export const sortedCountries = (_countries: Country[] = countries) => {
  return _countries.sort((a, b) => {
    if (a.display < b.display) {
      return -1;
    } else if (a.display > b.display) {
      return 1;
    }
    // a must be equal to b
    return 0;
  });
};

export const defaultNonUSCountry = nonUSCountries[0];
