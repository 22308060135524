import { PropsWithChildren } from "react";
import { twMerge } from "tailwind-merge";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { StaticButtonProps } from ".";

/** A Meso UI button. */
export const StaticButton = (props: PropsWithChildren<StaticButtonProps>) => {
  const { children, primary = true, isLoading = false, themeOverride } = props;

  const buttonClasses = twMerge([
    "h-12 rounded-[16px] px-4 font-bold text-white transition duration-100 flex items-center justify-center hover:opacity-90",
    primary
      ? "bg-primary dark:bg-primary-light hover:opacity-90"
      : "text-neutral-800 bg-neutral-100 border border-neutral-200 hover:opacity-90 dark:text-neutral-200 dark:border-neutral-700 dark:bg-neutral-800",
    props.disabled
      ? "cursor-not-allowed bg-neutral-400 hover:bg-neutral-400 dark:bg-neutral-600 dark:text-neutral-400 dark:hover:bg-neutral-600"
      : "scale-100 active:scale-95",
    props.className ?? "",
  ]);

  if (props.as === "a") {
    return (
      <a
        className={twMerge(
          themeOverride ? themeOverride : buttonClasses,
          "cursor-pointer",
        )}
        href={props.href}
        rel={props.rel}
        target={props.target}
        data-testid={
          "data-testid" in props ? props["data-testid"] : "continue-button"
        }
      >
        {isLoading ? (
          <div className="flex items-center justify-center gap-1">
            <FontAwesomeIcon
              data-testid="LandingSheet:loaderIcon"
              icon={icon({ name: "loader" })}
              size="lg"
              className="fa-spin"
            />
            {children}
          </div>
        ) : (
          children
        )}
      </a>
    );
  }

  return (
    <button
      disabled={props.disabled}
      className={themeOverride ? themeOverride : buttonClasses}
      onClick={props.onClick}
      data-testid={
        "data-testid" in props ? props["data-testid"] : "continue-button"
      }
      type={props.type ?? "button"}
    >
      {isLoading ? (
        <div className="flex items-center justify-center gap-1">
          <FontAwesomeIcon
            data-testid="LandingSheet:loaderIcon"
            icon={icon({ name: "loader" })}
            size="lg"
            className="fa-spin"
          />
          {children}
        </div>
      ) : (
        children
      )}
    </button>
  );
};
