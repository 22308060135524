import { useState, useCallback, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { Button, Text } from "@tigris/mesokit";
import { motion } from "framer-motion";
import { motionVariants } from "@src/utils/animation";
import { AppContext } from "@src/contexts/AppContext";

interface FeedbackFormProps {
  onClose: () => void;
  onSubmit: () => void;
  transferId: string;
}

const feedbackEmojis = [
  { emoji: "🤩", label: "Great", value: 4 },
  { emoji: "👍", label: "Good", value: 3 },
  { emoji: "👎", label: "OK", value: 2 },
  { emoji: "😞", label: "Bad", value: 1 },
];

const FEEDBACK_TEXT_MAX_LENGTH = 500;

export const FeedbackForm = ({
  onClose,
  onSubmit,
  transferId,
}: FeedbackFormProps) => {
  const {
    api: { resolveSubmitUserFeedback },
  } = useContext(AppContext);
  const [rating, setRating] = useState<number>(0);
  const [feedbackText, setFeedbackText] = useState(" ");

  const handleSubmit = useCallback(async () => {
    if (rating > 0) {
      const content = feedbackText.slice(0, FEEDBACK_TEXT_MAX_LENGTH);

      // Fire-and-forget
      await resolveSubmitUserFeedback({
        input: {
          kind: "standalone_post_transfer_user_feedback",
          content: content.length > 0 ? content : " ",
          rating,
          transferId,
        },
      });

      onSubmit();
    }
  }, [rating, resolveSubmitUserFeedback, feedbackText, transferId, onSubmit]);

  return (
    <motion.div
      key="FeedbackFormPopover"
      variants={motionVariants.scaleInSmall}
      initial="initial"
      animate="animate"
      exit="exit"
      className="rounded-ts-card absolute bottom-0 z-50 flex w-full flex-col gap-4 bg-neutral-800 p-4 shadow-2xl"
    >
      <header className="flex flex-row items-center justify-between">
        <FontAwesomeIcon
          icon={icon({ name: "close" })}
          className="cursor-pointer text-neutral-300"
          onClick={onClose}
        />
        <Text className="font-semibold">Feedback</Text>
        <FontAwesomeIcon
          icon={icon({ name: "life-ring" })}
          className="text-neutral-300"
        />
      </header>
      <div className="flex justify-between gap-2">
        {feedbackEmojis.map(({ emoji, value }) => (
          <button
            key={value}
            className={`w-full rounded-lg border border-neutral-700 py-1 text-xl ${
              rating === value ? "bg-neutral-700" : ""
            }`}
            onClick={() => setRating(value)}
          >
            {emoji}
          </button>
        ))}
      </div>
      <div className="relative flex">
        <textarea
          className="ring-primary dark:ring-primary-light h-full w-full resize-none rounded-lg bg-neutral-700 p-2 pb-4 outline-none ring-offset-1 ring-offset-neutral-800 focus:ring-2 dark:text-white"
          rows={2}
          placeholder="Any additional comments? (optional)"
          value={feedbackText}
          onChange={(e) => setFeedbackText(e.target.value)}
          maxLength={FEEDBACK_TEXT_MAX_LENGTH}
        />
        <div className="absolute bottom-1 right-1">
          <Text className="text-xs text-neutral-400 opacity-50">
            {feedbackText.length}/{FEEDBACK_TEXT_MAX_LENGTH}
          </Text>
        </div>
      </div>
      <Button
        primary={false}
        className="h-10 text-sm"
        onClick={handleSubmit}
        disabled={rating === 0}
      >
        <FontAwesomeIcon
          icon={icon({ name: "paper-plane" })}
          className="mr-2"
        />
        Share Feedback
      </Button>
    </motion.div>
  );
};
