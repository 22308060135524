import { GraphQLError } from "graphql";

/** Error messages returned as `GraphQLError.message` values. */
export const AUTH_FACTOR_ERROR_MESSAGES = [
  "requires two factors",
  "requires at least one factor",
];

/** Check whether our API is returning a GraphQL error due to missing auth factors. */
export const isAuthFactorError = (errors?: GraphQLError[]) =>
  errors
    ? errors.some((error) => AUTH_FACTOR_ERROR_MESSAGES.includes(error.message))
    : false;
