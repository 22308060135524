import { useState } from "react";
import { USAddressForm } from "./USAddressForm";
import { USAddressSuggestion } from "../../../utils/smarty";
import { AddressCombobox } from "../../AddressCombobox";
import { CountryCodeAlpha3 } from "../../../types";

export type USAddressCaptureProps = {
  onAddressResolved: (params: {
    address?: USAddressSuggestion;
    rawValue: string;
  }) => void;
  labelText?: string;
  placeholder?: string;
  disabled: boolean;
  isValid: boolean;
  /** The `name` for the `input` field. This will be matched by the `label`'s `htmlFor` props. */
  inputName: string;

  initialValue?: USAddressSuggestion;
  expandedOnly?: boolean;
};

const noop = () => {};

export const USAddressCapture = ({
  expandedOnly = false,
  initialValue,
  labelText,
  placeholder,
  disabled,
  isValid,
  inputName,
  onAddressResolved,
}: USAddressCaptureProps) => {
  const [formExpanded, setFormExpanded] = useState(
    expandedOnly || initialValue,
  );
  const [suggestion, setSuggestion] = useState<USAddressSuggestion | undefined>(
    () => initialValue,
  );

  if (formExpanded) {
    return (
      <USAddressForm
        placeholder={placeholder}
        disabled={disabled}
        isValid={isValid}
        onAddressResolved={onAddressResolved}
        suggestion={suggestion}
        labelText={labelText}
      />
    );
  }

  return (
    <AddressCombobox<USAddressSuggestion>
      labelText={labelText}
      placeholder={placeholder}
      disabled={disabled}
      isValid={isValid}
      inputName={inputName}
      initialCountryCodeAlpha3={CountryCodeAlpha3.USA}
      onAddressSelected={(address, rawValue = "") => {
        setFormExpanded(true);
        setSuggestion(address);
        onAddressResolved({ address, rawValue });
      }}
      onError={noop}
    />
  );
};
