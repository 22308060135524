import { err, ok, Result } from "neverthrow";
import { PropsWithChildren, useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Button, Logo, Text } from "@tigris/mesokit";
import { dynamicDelay } from "@src/utils/dynamicDelay";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { twMerge } from "tailwind-merge";
import { AnimatePresence, motion } from "framer-motion";
import JSConfetti from "js-confetti";
import {
  Posthog,
  Sentry,
  TelemetryEvents,
  truncateAddress,
} from "@tigris/common";
import { Network } from "@src/types";
import giftIcon from "../assets/gift-icon.png";
import { StandaloneError } from "./Standalone/StandaloneError";

type RewardsLandingError =
  | {
      kind: "recoverable";
      message: string;
    }
  | {
      kind: "terminal";
      message: string;
    };

const { VITE_GRAPHQL_ORIGIN: API_ORIGIN = "http://localhost:4001" } =
  import.meta.env;

const AMOUNTS = [25, 50, 100, 500].map((value) => ({
  value,
  display: `$${value}`,
}));

const getProvider = () => {
  if ("phantom" in window) {
    const provider = window.phantom?.solana;

    if (provider?.isPhantom) {
      return ok(provider);
    }
  }

  const errorMessage =
    "Unable to detect solana provider. The app may not be running inside Phantom browser or the Phantom extension may not be installed.";

  Sentry.captureException(errorMessage);

  return err(errorMessage);
};

const generateTransferUrl = async ({
  sourceAmount,
  walletAddress,
  rewardsProgram,
}: Record<
  "walletAddress" | "rewardsProgram" | "sourceAmount",
  string
>): Promise<Result<string, string>> => {
  const startTime = performance.now();
  const mesoConfiguration = new URLSearchParams({
    // The partner ID will change depending on the campaign. But for now, we do not get dynamic data about the campaign so we have to hardcode it.
    partnerId: "meso-phantom-incentives",
    walletAddress,
    network: "solana:5eykt4UsFv8P8NJdTREpY1vzqKqZKvdp",
    destinationAsset: "SOL",
    sourceAmount,
    rewardsProgram,
  });

  const paramsToSign = `?${mesoConfiguration.toString()}`;
  const errorMessage = "Unable to sign params.";

  try {
    const signedUrlResponse = await fetch(`${API_ORIGIN}/sign`, {
      method: "POST",
      body: JSON.stringify({ query: paramsToSign }),
    });

    if (!signedUrlResponse.ok) {
      Sentry.captureException(errorMessage, { extra: { paramsToSign } });
      return err(errorMessage);
    }

    const { query } = await signedUrlResponse.json();

    await dynamicDelay(startTime);

    return ok(`${location.origin}/${query}`);
  } catch (error: unknown) {
    Sentry.captureException(error, { extra: { paramsToSign } });
    return err(errorMessage);
  }
};

const Layout = ({ children }: PropsWithChildren) => {
  return (
    <div className="flex h-full w-full flex-col items-center justify-center gap-4 overflow-hidden px-4">
      <div className="sm:rounded-ts-card flex h-full max-w-md flex-col justify-between gap-8 rounded-none bg-white p-8 shadow-2xl sm:h-auto sm:justify-normal dark:bg-neutral-800">
        <div className="flex items-center justify-center">
          <Logo size="lg" showText={false} />
        </div>
        {children}
      </div>
    </div>
  );
};

// This can only be called once as it adds a `<canvas>` element to the DOM
const jsConfetti = new JSConfetti();

export const RewardsLanding = () => {
  const [searchParams] = useSearchParams();
  const [walletAddress, setWalletAddress] = useState<string | null>(null);
  const [error, setError] = useState<RewardsLandingError | null>(null);
  const [amount, setAmount] = useState<number>(25);
  const [isLoading, setIsLoading] = useState(false);
  const [rewardsProgramName, setRewardsProgramName] = useState<string | null>(
    null,
  );

  const connectWallet = useCallback(async () => {
    const providerResult = getProvider();

    if (providerResult.isErr()) {
      setError({ kind: "terminal", message: providerResult.error });
      return;
    }

    try {
      const resp = await providerResult.value.connect();
      const walletAddress = resp.publicKey.toString();

      if (walletAddress) {
        setError(null);
        setWalletAddress(walletAddress);

        setTimeout(() => {
          jsConfetti.addConfetti({
            emojis: ["🍕", "👻", "🦄", "💸"],
            emojiSize: 72,
            confettiNumber: 48,
          });
        }, 1000);
        return;
      }

      setError({ kind: "terminal", message: "Unable to connect to wallet." });
    } catch (err: unknown) {
      if (
        err &&
        typeof err === "object" &&
        "code" in err &&
        err.code === 4001
      ) {
        const error: RewardsLandingError = {
          kind: "recoverable",
          message: "User rejected the request",
        };

        Posthog.capture(TelemetryEvents.rewardsLandingConnectFail, error);
        setError(error);
        return;
      }

      const error: RewardsLandingError = {
        kind: "terminal",
        message: "Unable to connect to wallet.",
      };

      Posthog.capture(TelemetryEvents.rewardsLandingConnectFail, error);
      setError(error);
    }
  }, []);

  // On load
  useEffect(() => {
    // Validate we have a campaign name in the URL params
    const programName = searchParams.get("name");
    Posthog.capture(TelemetryEvents.rewardsLandingPageView, { programName });

    if (!programName) {
      setError({
        kind: "terminal",
        message: "Rewards program name is required.",
      });
      return;
    }

    setRewardsProgramName(programName);

    connectWallet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLaunchTransfer = useCallback(async () => {
    if (!walletAddress || !amount) {
      setError({
        kind: "terminal",
        message: "Wallet address and amount are required.",
      });
      return;
    }

    setIsLoading(true);

    const signedUrlResult = await generateTransferUrl({
      sourceAmount: amount.toString(),
      walletAddress: walletAddress!,
      rewardsProgram: rewardsProgramName!,
    });

    if (signedUrlResult.isErr()) {
      setError({ kind: "terminal", message: signedUrlResult.error });
      return;
    }

    location.href = signedUrlResult.value;
  }, [amount, rewardsProgramName, walletAddress]);

  if (error && error.kind === "terminal") {
    return <StandaloneError dataTestId="RewardsLanding:error" />;
  }

  if (error && error.kind === "recoverable") {
    return (
      <Layout>
        <Button
          data-testid="RewardsLanding:connectWalletButton"
          onClick={connectWallet}
        >
          Connect Wallet
        </Button>
      </Layout>
    );
  }

  return (
    <Layout>
      <div className="flex flex-col gap-3 rounded-2xl border py-8 dark:border-neutral-700 dark:bg-neutral-700/40">
        <div className="flex flex-col items-center justify-center gap-1 border-b pb-4 dark:border-neutral-700">
          <img src={giftIcon} className="mb-2" width={48} height={48} />
          <Text className="text-center text-xl font-semibold tracking-tight">
            Get $2 of SOL!
          </Text>
          <Text
            data-testid="RewardsLanding:walletAddress"
            className="rounded-full px-4 text-center font-mono text-xs font-bold opacity-60"
          >
            <FontAwesomeIcon
              size="lg"
              icon={icon({ name: "wallet", style: "regular" })}
            />{" "}
            {walletAddress &&
              truncateAddress(walletAddress, Network.SOLANA_MAINNET, 12)}
          </Text>
        </div>
        <div className="flex flex-col gap-3 px-8 pt-4">
          <Text className="text-md">
            <FontAwesomeIcon
              className="text-highlight"
              size="lg"
              icon={icon({ name: "circle-check", style: "solid" })}
            />{" "}
            Create a Meso Account
          </Text>
          <Text className="text-md opacity-50">
            <FontAwesomeIcon
              size="lg"
              icon={icon({ name: "circle", style: "regular" })}
            />{" "}
            Verify your identity
          </Text>
          <div>
            <Text className="text-md opacity-50">
              <FontAwesomeIcon
                size="lg"
                icon={icon({ name: "circle", style: "regular" })}
              />{" "}
              Make your first purchase with Meso
            </Text>
            <div className="flex flex-col gap-1.5">
              <div className="mt-4 flex gap-3">
                {AMOUNTS.map(({ value, display }) => (
                  <div
                    key={value}
                    onClick={() => {
                      Posthog.capture(
                        TelemetryEvents.rewardsLandingAmountSelect,
                        {
                          amount: value,
                        },
                      );
                      setAmount(value);
                    }}
                    className={twMerge(
                      "flex w-full cursor-pointer items-center justify-center rounded-full p-2 text-center font-semibold transition-colors dark:text-white",
                      amount === value
                        ? "bg-blue-500 text-white"
                        : "bg-neutral-100 dark:bg-neutral-700",
                    )}
                  >
                    {display}
                    <AnimatePresence>
                      {amount === value && (
                        <motion.div
                          initial={{ opacity: 0, width: 0 }}
                          animate={{ opacity: 1, width: 16 }}
                          exit={{ opacity: 0, width: 0 }}
                        >
                          <FontAwesomeIcon
                            size="sm"
                            className="ml-1"
                            icon={icon({ name: "check", style: "solid" })}
                          />
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="pt-6">
        <Text className="mb-2 text-center text-xs opacity-60">
          Limit one per person. Account activation and first <br />
          successful purchase required.
        </Text>

        <Button
          onClick={handleLaunchTransfer}
          disabled={isLoading || !walletAddress}
          isLoading={isLoading}
          data-testid="RewardsLanding:launchButton"
        >
          Continue
        </Button>
      </div>
    </Layout>
  );
};
