import { IParsedAddress, parseAddress as parse } from "addresser";
import { ErrorMessages } from "./errorMessages";
import { Result, err, ok } from "neverthrow";
import { USAddressSuggestion } from "./smarty";

/* Parse a string into a structured Smarty address using the `addresser` package. */
export const parseUSAddress = (
  address: string,
): Result<USAddressSuggestion, string> => {
  // Attempt to manually parse address
  try {
    const parsedAddress = parse(address.trim()) as IParsedAddress & {
      addressLine2?: string;
    };

    return ok({
      streetLine: parsedAddress.addressLine1,
      secondary: parsedAddress.addressLine2 ?? "",
      entries: 1,
      city: parsedAddress.placeName,
      state: parsedAddress.stateAbbreviation,
      zipcode: parsedAddress.zipCode,
    });
  } catch (error: unknown) {
    return err(ErrorMessages.address.UNABLE_TO_PARSE_ADDRESS_ERROR);
  }
};
