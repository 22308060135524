import { AddPaymentCard } from "./components/AddPaymentCard";
import { BasicInfoEntry } from "./components/BasicInfoEntry";
import { BasicInfoOverview } from "./components/BasicInfoOverview";
import { CreateAccount } from "./components/CreateAccount";
import { HandoffFailed } from "./components/HandoffFailed";
import { ManualReview } from "./components/ManualReview";
import { SelectPaymentMethod } from "./components/SelectPaymentMethod";
import { Phone2Fa } from "./components/Phone2Fa";
import { PhoneEntry } from "./components/PhoneEntry";
import { Summary } from "./components/Summary";
import { TaxpayerIdEntry } from "./components/TaxpayerIdEntry";
import { Routes } from "./types";
import { Landing } from "./components/Landing";
import { ScamWarning } from "./components/ScamWarning";
import { DeclareResidency } from "./components/DeclareResidency";
import { PersonalInfo } from "./components/PersonalInfo";
import { PeselEntry } from "./components/PeselEntry";
import { ResidentialAddress } from "./components/ResidentialAddress";

export const routes = [
  {
    path: Routes.Root,
    element: <Landing />,
  },
  {
    path: Routes.DeclareResidency,
    element: <DeclareResidency />,
  },
  {
    path: Routes.CreateAccount,
    element: <CreateAccount />,
  },
  {
    path: Routes.PhoneEntry,
    element: <PhoneEntry />,
  },
  {
    path: Routes.Phone2Fa,
    element: <Phone2Fa />,
  },
  {
    path: Routes.SelectPaymentMethod,
    element: <SelectPaymentMethod />,
  },
  {
    path: Routes.AddPaymentCard,
    element: <AddPaymentCard />,
  },
  {
    path: Routes.BasicInfoOverview,
    element: <BasicInfoOverview />,
  },
  {
    path: Routes.PersonalInfo,
    element: <PersonalInfo />,
  },
  {
    path: Routes.ResidentialAddress,
    element: <ResidentialAddress />,
  },
  {
    path: Routes.Pesel,
    element: <PeselEntry />,
  },
  {
    path: Routes.BasicInfoEntry,
    element: <BasicInfoEntry />,
  },
  {
    path: Routes.ScamWarning,
    element: <ScamWarning />,
  },
  {
    path: Routes.Summary,
    element: <Summary />,
  },
  {
    path: Routes.ManualReview,
    element: <ManualReview />,
  },
  {
    path: Routes.TaxpayerIdEntry,
    element: <TaxpayerIdEntry />,
  },
  {
    path: Routes.HandoffFailed,
    element: <HandoffFailed />,
  },
];
