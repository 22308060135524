import {
  CardHero,
  LineItems,
  LoadingInterstitial,
  EnvironmentBanner,
  ErrorMessages,
} from "@tigris/mesokit";
import { CashOutQuote, TransferKind } from "../../generated/sdk";
import { useQuote, UseQuoteConfiguration } from "@src/hooks/useQuote";
import { useContext, cloneElement, useCallback } from "react";
import { AppContext } from "@src/contexts/AppContext";
import { CashInQuote, OutletContext } from "@src/types";
import { MESO_MAX_AMOUNT, MESO_MIN_AMOUNT, Routes } from "@src/utils/constants";
import { useLocation, useOutlet } from "react-router-dom";
import { Toast } from "../Toast";
import { useOnboarding } from "@src/hooks/useOnboarding";
import { HoldingScreen } from "../HoldingScreen";
import { AnnouncementBanner } from "../AnnouncementBanner";
import { QuoteLimitErrorCode } from "@src/api";
import { toast } from "sonner";
import { useApplePayContext } from "@src/hooks/useApplePayContext";
import { AddDebitCard } from "../AddDebitCard";

const TOAST_ID = "InlineLayout";

export const InlineLayout = () => {
  const {
    user,
    configuration: { walletAddress, network, destinationAsset, transferKind },
    fiatInstrument,
    setFiatInstrument,
    appReady,
    setQuoteLimitReached,
    userLimits,
    isAddingCard,
    setIsAddingCard,
    api: { resolveRemoveFiatInstrument },
    updateUser,
    transfer,
    executeTransferRequestIsInFlight,
  } = useContext(AppContext);
  const { theme, fiatInstruments } = user;
  const { applePayEnabled, applePayCanceled } = useApplePayContext();
  const { pathname } = useLocation();
  const isCashIn = transferKind === TransferKind.CASH_IN;
  const { onboardingInProgress, supportedPaymentMethods } = useOnboarding();
  const handleQuoteLimitError = useCallback<
    Required<UseQuoteConfiguration>["onLimitError"]
  >(
    (code?: QuoteLimitErrorCode) => {
      switch (code) {
        case "below_min_xfer":
          toast.error(
            ErrorMessages.quote.minLimitError(
              userLimits?.mesoMinimum ?? MESO_MIN_AMOUNT,
            ),
            { id: "quoteLimitError" },
          );
          break;
        case "above_max_xfer":
        case "above_monthly_xfer":
          toast.error(
            ErrorMessages.quote.maxLimitError(
              userLimits?.monthlyAmountAvailable ?? MESO_MAX_AMOUNT,
            ),
            { id: "quoteLimitError" },
          );
      }
      setQuoteLimitReached();
    },
    [
      setQuoteLimitReached,
      userLimits?.mesoMinimum,
      userLimits?.monthlyAmountAvailable,
    ],
  );
  const quoteHook = useQuote({ onLimitError: handleQuoteLimitError });
  const { quote } = quoteHook;
  const outlet = useOutlet({ useQuote: quoteHook } satisfies OutletContext);

  const onRemoveFiatInstrument = useCallback(
    async (id: string) => {
      const result = await resolveRemoveFiatInstrument({ input: { id } });
      if (
        result.isOk() &&
        result.value.user.fiatInstruments?.__typename === "FiatInstruments" &&
        result.value.user.fiatInstruments
      ) {
        const fiatInstruments = result.value.user.fiatInstruments;
        updateUser({ fiatInstruments }, supportedPaymentMethods);
      } else if (result.isErr()) {
        toast?.error(result.error, { id: TOAST_ID });
      }
    },
    [resolveRemoveFiatInstrument, supportedPaymentMethods, updateUser],
  );

  if (!appReady) {
    return <LoadingInterstitial mode="waiting" key="LoadingInterstitial" />;
  }

  return (
    <div className="flex h-full w-full flex-col justify-between transition-opacity">
      {pathname === Routes.TransferUnavailable ||
      pathname === Routes.ActivateUser ? (
        <>{outlet}</>
      ) : (
        <>
          <AnnouncementBanner />
          <Toast key={pathname} />
          {isAddingCard ? (
            <AddDebitCard />
          ) : (
            <>
              <div className="flex flex-col gap-2">
                <CardHero
                  theme={theme}
                  asset={destinationAsset}
                  amount={quote?.destination?.amount}
                  network={network}
                  walletAddress={walletAddress}
                  fiatInstrument={fiatInstrument}
                  fiatInstruments={fiatInstruments}
                  setFiatInstrument={setFiatInstrument}
                  depositAddress={(quote as CashOutQuote)?.depositAddress}
                  transferKind={transferKind}
                  applePayEnabled={applePayEnabled}
                  applePayCanceled={applePayCanceled}
                  setIsAddingCard={setIsAddingCard}
                  canShowPopover={
                    !!!transfer?.status && !executeTransferRequestIsInFlight
                  }
                  limits={
                    userLimits && userLimits.approachingLimit
                      ? userLimits
                      : undefined
                  }
                  authenticated={!!user.id}
                  onRemoveFiatInstrument={onRemoveFiatInstrument}
                />
                <LineItems
                  key="LandingSheetLineItems"
                  exchangeRate={quote?.exchangeRate}
                  mesoFeeAmount={quote?.mesoFee.amount}
                  mesoFeeWaived={
                    isCashIn && (quote as CashInQuote)?.mesoFeeWaived
                  }
                  networkFeeAmount={
                    isCashIn
                      ? (quote as CashInQuote)?.networkFee?.amount
                      : undefined
                  }
                  networkFeeWaived={
                    isCashIn && (quote as CashInQuote)?.networkFeeWaived
                  }
                  subtotalAmount={quote?.sourceSubtotal.amount}
                  totalAmount={
                    isCashIn
                      ? quote?.sourceTotal.amount
                      : quote?.destination.amount
                  }
                  transferKind={transferKind}
                />
              </div>
              <div>{outlet && cloneElement(outlet, { key: pathname })}</div>

              {onboardingInProgress && <HoldingScreen />}

              <div className="absolute flex w-full justify-center">
                <EnvironmentBanner />
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};
