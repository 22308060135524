// Taken from: https://gist.github.com/amadeuszblanik/d76b029b2b16e44e507c555dbc8edaf5
const checkSum = (digits: number[]) => {
  const digit11 = digits[10];
  digits.pop();

  const times = [1, 3, 7, 9, 1, 3, 7, 9, 1, 3];

  let sum = digits.reduce((accum, currentValue, index) => {
    return accum + currentValue * times[index];
  });

  sum %= 10;
  sum = 10 - sum;
  sum %= 10;

  if (sum == digit11) {
    return true;
  } else {
    return false;
  }
};

const peselToDigits = (value: string): number[] =>
  value.split("").map((item) => parseInt(item));

export const validatePesel = (value: string): boolean => {
  const digits = peselToDigits(value);

  if (digits.length !== 11 && digits.every((item) => !isNaN(item))) {
    return false;
  }

  return checkSum(digits);
};
