import { motion } from "framer-motion";
import { useCallback, useEffect, useMemo, useState } from "react";
import { twMerge } from "tailwind-merge";
import { CountryCodeAlpha2 } from "../types";

const phoneNumbers = new Map<
  CountryCodeAlpha2,
  { value: string; icon: string }
>([
  [CountryCodeAlpha2.AT, { value: "1 5139279", icon: "🇦🇹" }],
  [CountryCodeAlpha2.BE, { value: "2 513 42 13", icon: "🇧🇪" }],
  [CountryCodeAlpha2.BG, { value: "88 555 2586", icon: "🇧🇬" }],
  [CountryCodeAlpha2.HR, { value: "47 492 280", icon: "🇭🇷 " }],
  [CountryCodeAlpha2.CY, { value: "25388877", icon: "🇨🇾" }],
  [CountryCodeAlpha2.CZ, { value: "727895310", icon: "🇨🇿" }],
  [CountryCodeAlpha2.DK, { value: "33118188", icon: "🇩🇰" }],
  [CountryCodeAlpha2.EE, { value: "6806646", icon: "🇪🇪" }],
  [CountryCodeAlpha2.FI, { value: "201234703", icon: "🇫🇮" }],
  [CountryCodeAlpha2.FR, { value: "1 53 77 21 00", icon: "🇫🇷" }],
  [CountryCodeAlpha2.DE, { value: "89 221048", icon: "🇩🇪" }],
  [CountryCodeAlpha2.GR, { value: "21 0322 5984", icon: "🇬🇷" }],
  [CountryCodeAlpha2.HR, { value: "14879487", icon: "🇭🇺" }],
  [CountryCodeAlpha2.IS, { value: "4445000", icon: "🇮🇸" }],
  [CountryCodeAlpha2.IE, { value: "57 776 0500", icon: "🇮🇪" }],
  [CountryCodeAlpha2.IT, { value: "06 854 0393", icon: "🇮🇹" }],
  [CountryCodeAlpha2.LV, { value: "28 005 595", icon: "🇱🇻" }],
  [CountryCodeAlpha2.LT, { value: "37 366660", icon: "🇱🇹" }],
  [CountryCodeAlpha2.LU, { value: "24 16 16 1", icon: "🇱🇺" }],
  [CountryCodeAlpha2.MT, { value: "2124 5425", icon: "🇲🇹" }],
  [CountryCodeAlpha2.NL, { value: "20 800 8130", icon: "🇳🇱" }],
  [CountryCodeAlpha2.NO, { value: "23 31 55 0", icon: "🇳🇴" }],
  [CountryCodeAlpha2.PL, { value: "22 328 88 88", icon: "🇵🇱" }],
  [CountryCodeAlpha2.PT, { value: "22 028 0240", icon: "🇵🇹" }],
  [CountryCodeAlpha2.RO, { value: "799 154 156", icon: "🇷🇴" }],
  [CountryCodeAlpha2.SK, { value: "2/599 851 11", icon: "🇸🇰" }],
  [CountryCodeAlpha2.SI, { value: "40 616 719", icon: "🇸🇮" }],
  [CountryCodeAlpha2.ES, { value: "931 78 43 43", icon: "🇪🇸" }],
  [CountryCodeAlpha2.SE, { value: "8 679 35 00", icon: "🇸🇪" }],
  [CountryCodeAlpha2.US, { value: "8158382294", icon: "🇺🇸" }],
]);

const PhoneNumber = ({ value, icon }: { value: string; icon: string }) => {
  const [copied, setCopied] = useState(false);

  const handleClick = useCallback(() => {
    if (copied) return;

    navigator.clipboard.writeText(value).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 1_500);
    });
  }, [copied, value]);

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 1000);
    }
  }, [copied, value]);

  return (
    <div
      onClick={handleClick}
      className="flex cursor-pointer gap-1 opacity-60 transition-opacity duration-150 hover:opacity-100"
    >
      <div>{icon}</div>
      <div>{copied ? "Copied!" : value}</div>
    </div>
  );
};

export const PhoneNumberHelper = ({
  countryCodeAlpha2,
}: {
  countryCodeAlpha2?: CountryCodeAlpha2;
}) => {
  const [isVisible, setIsVisible] = useState(true);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const handleClose = useCallback(() => {
    setIsVisible(false);
  }, []);

  const phoneNumber = useMemo(() => {
    return countryCodeAlpha2 ? phoneNumbers.get(countryCodeAlpha2) : undefined;
  }, [countryCodeAlpha2]);

  if (!phoneNumber) return null;

  return (
    <motion.div
      className={twMerge("absolute bottom-0 w-full", !isVisible && "hidden")}
      initial={{ y: 30, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ y: 30, opacity: 0 }}
    >
      <div
        className={twMerge(
          "m-2 rounded-xl p-4",
          "text-xs",
          "bg-purple-900 text-white dark:bg-neutral-900/90",
        )}
      >
        <div className="mb-4 flex justify-between">
          <div className="font-mono">Test phone numbers</div>
          <div
            className="cursor-pointer opacity-75 hover:opacity-100"
            onClick={handleClose}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              className="size-4"
            >
              <path d="M6.28 5.22a.75.75 0 0 0-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 1 0 1.06 1.06L10 11.06l3.72 3.72a.75.75 0 1 0 1.06-1.06L11.06 10l3.72-3.72a.75.75 0 0 0-1.06-1.06L10 8.94 6.28 5.22Z" />
            </svg>
          </div>
        </div>

        <div className="flex flex-col gap-2">
          <PhoneNumber value={phoneNumber?.value} icon={phoneNumber.icon} />
        </div>
      </div>
    </motion.div>
  );
};
