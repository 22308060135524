import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./styles.css";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { InputProps } from "./Input";
import { twMerge } from "tailwind-merge";
import {
  ButtonHTMLAttributes,
  FocusEventHandler,
  Ref,
  forwardRef,
  useCallback,
  useMemo,
  useState,
} from "react";

type InlineFormInputProps = {
  input?: Omit<InputProps, "className">;
  button?: ButtonHTMLAttributes<HTMLButtonElement> & {
    /**
     * Whether the button renders with the `primary` styling.
     *
     * Defaults to `true`.
     */
    primary?: boolean;

    "data-testid"?: string;
  };
};

/**
 * An input/submit button combo rendered inline.
 *
 * This component expects to be rendered into a `<form>` element.
 *
 * **Example:**
 *
 * ```ts
 * <form onSubmit={handleSubmit}>
 *   <InlineFormInput
 *     input={{ type: "password", disabled: true }}
 *     button={{ disabled: true }}
 *   />
 * </form>
 * ```
 */
export const InlineFormInput = forwardRef(function InlineFormInputWithRef(
  { button, input }: InlineFormInputProps,
  ref: Ref<HTMLInputElement>,
) {
  const [isFocused, setIsFocused] = useState(false);

  const transferFocus = useCallback<FocusEventHandler<HTMLInputElement>>(
    (event) => {
      setIsFocused(true);
      if (input && input?.onFocus && typeof input.onFocus === "function") {
        input.onFocus(event);
      }
    },
    [input],
  );

  const transferBlur = useCallback<FocusEventHandler<HTMLInputElement>>(
    (event) => {
      setIsFocused(false);

      if (input && input?.onBlur && typeof input.onBlur === "function") {
        input.onBlur(event);
      }
    },
    [input],
  );

  const refinedProps = useMemo(() => {
    const { isValid, ...elementProps } = input || {};

    return { isValid, elementProps };
  }, [input]);

  return (
    <div
      className={twMerge(
        "input",
        "flex py-1",
        input?.disabled && "input-disabled",
        isFocused && "inline-form-input-focus",
        // Add the invalid class on the parent wrapper. Not the input
        !refinedProps.isValid && "input-invalid",
      )}
    >
      <input
        {...refinedProps.elementProps}
        id={input ? input.id || input.name : ""}
        className="raw-input flex-1 rounded-[16px]"
        onFocus={transferFocus}
        onBlur={transferBlur}
        ref={ref}
      />
      {button && (
        <div className="-mr-2">
          <button
            type="submit"
            tabIndex={0}
            className={twMerge([
              "h-full w-10 rounded-[12px] font-bold transition duration-100 hover:opacity-90",
              !button || button?.primary
                ? "bg-primary dark:bg-primary-light text-white"
                : "border border-neutral-200 text-neutral-800 hover:bg-neutral-200/20 dark:border-neutral-600 dark:text-neutral-200 dark:hover:bg-neutral-200/10",
              button?.disabled
                ? "cursor-not-allowed bg-neutral-400 hover:bg-neutral-400 dark:bg-neutral-600 dark:text-neutral-400 dark:hover:bg-neutral-600"
                : "scale-100 active:scale-95",
            ])}
            data-testid={"data-testid" in button ? button["data-testid"] : ""}
            disabled={button.disabled ?? false}
          >
            <FontAwesomeIcon
              icon={icon({ name: "arrow-right", style: "solid" })}
              className="hover:opacity-70 dark:text-white"
            />
          </button>
        </div>
      )}
    </div>
  );
});
